import { type WishItem } from '../../../domain/entities/WishItem/WishItem';
import { useEffect, useState } from 'react';
import WishItemsRepository from '../../../infrastructure/repositories/WishItem';
import { type WishListId } from '../../../domain/entities/WishList/types';
import WishItemsStore from '../../../infrastructure/store/wishitems';

interface WishItemsControls {
	wishItems: WishItem[];
	fetchWishItems: (limit?: number) => Promise<void>;
	getWishItemsTotalCount: () => Promise<number>;
}

const useWishItems = ({ wishlistId }: { wishlistId: WishListId }): WishItemsControls => {
	const [wishItems, updateWishItems] = useState<WishItem[]>(
		WishItemsRepository.getWishItemsByWishlistId(wishlistId),
	);

	useEffect(() => {
		const disposer = WishItemsStore.subscribeOnWishItemsChange((wishItems) => {
			updateWishItems(wishItems.filter((wishItem) => wishItem.wishlist === wishlistId));
		});

		return () => {
			disposer();
		};
	}, []);

	const getWishItemsTotalCount = async () => WishItemsRepository.getWishItemsTotalCount(wishlistId);

	const fetchWishItems = async (limit?: number): Promise<void> => {
		WishItemsRepository.fetchWishItems(wishlistId, limit).catch((e) => {
			console.log('error -->', e);
		});
	};

	return {
		wishItems,
		fetchWishItems,
		getWishItemsTotalCount,
	};
};

export default useWishItems;
