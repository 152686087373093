import React from 'react';
import { colors, type IconColor } from './definitions';

interface ChevronRightProps {
	color?: IconColor;
}

const ChevronRight: React.FC<ChevronRightProps> = ({ color = 'white' }) => (
	<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M13.5 27L22.5 18L13.5 9"
			stroke={colors[color]}
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default ChevronRight;
