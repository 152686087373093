import { createContext } from 'react';

export interface Notification {
	message: string;
	onClose: () => void;
	time?: number;
}

export interface NotificationControls {
	notification: Notification | null;
	sendNotification: (message: string, time?: number) => void;
}

const NotificationsContext = createContext<NotificationControls | null>(null);

export default NotificationsContext;
