import React from 'react';

const ChevronDown = () => (
	<svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.36394 7.43498C6.75446 7.82551 7.38763 7.82551 7.77815 7.43498L13.435 1.77813C13.8255 1.3876 13.8255 0.754439 13.435 0.363915C13.0445 -0.0266094 12.4113 -0.0266097 12.0208 0.363915L7.07104 5.31366L2.1213 0.363916C1.73077 -0.0266088 1.09761 -0.0266084 0.707083 0.363916C0.316559 0.75444 0.316559 1.3876 0.707083 1.77813L6.36394 7.43498Z"
			fill="#7678ED"
		/>
	</svg>
);

export default ChevronDown;
