import React, { useEffect } from 'react';
import Container from '../../ui/Components/Container';
import TextLogo from '../../ui/Icons/TextLogo';
import styles from './styles.module.scss';
import { Typography } from '@mui/material';
import LoginForm from '../../ui/LoginForm';
import DescriptionCard from '../../ui/DescriptionCard';
import useAnalytics from '../../hooks/useAnalytics';

const LoginPage = () => {
	const analytics = useAnalytics();

	useEffect(() => {
		analytics.sendPageViewEvent('login_page');
	}, []);

	return (
		<Container fullHeight>
			<div className={styles.header}>
				<div className={styles.logo}>
					<TextLogo />
				</div>
			</div>
			<div className={styles.content}>
				<Typography color="primary" variant={'h2'}>
					Log in
				</Typography>
				<DescriptionCard className={styles.description}>
					Welcome back! Please login with your personal info
				</DescriptionCard>
				<LoginForm />
			</div>
		</Container>
	);
};

export default LoginPage;
