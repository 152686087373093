import React, { useEffect } from 'react';
import Container from '../../ui/Components/Container';
import TextLogo from '../../ui/Icons/TextLogo';
import styles from './styles.module.scss';
import { Typography } from '@mui/material';
import SignUpForm from '../../ui/SignUpForm';
import DescriptionCard from '../../ui/DescriptionCard';
import useAnalytics from '../../hooks/useAnalytics';

const SignUpPage = () => {
	const analytics = useAnalytics();

	useEffect(() => {
		analytics.sendPageViewEvent('signup_page');
	}, []);

	return (
		<Container fullHeight>
			<div className={styles.header}>
				<div className={styles.logo}>
					<TextLogo />
				</div>
			</div>
			<div className={styles.content}>
				<Typography color="primary" variant={'h2'} className={styles.title}>
					Sign Up
				</Typography>
				<DescriptionCard className={styles.description}>
					Hello there! Please enter your personal details to create account
				</DescriptionCard>
				<SignUpForm />
			</div>
		</Container>
	);
};

export default SignUpPage;
