import React from 'react';
import { Typography } from '@mui/material';
import styles from './styles.module.scss';

interface DescriptionCardProps {
	className?: string;
}

const DescriptionCard: React.FC<DescriptionCardProps> = ({ children, className }) => {
	return (
		<div className={[styles.wrapper, className].join(' ')}>
			<Typography color="secondary" variant="body1" className={styles.text}>
				{children}
			</Typography>
		</div>
	);
};

export default DescriptionCard;
