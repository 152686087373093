export const CUSTOM_ERROR_NAME = 'Wishly Error';

interface ErrorWithMessage {
	message: string;
}

export const getErrorMessage = (error: unknown): string => {
	if (typeof error === 'string') {
		return error;
	}

	if (error instanceof Error) {
		return `${error.name}. ${error.message}`;
	}

	if ((error as ErrorWithMessage).message) {
		return (error as ErrorWithMessage).message;
	}

	return '';
};
