import React from 'react';
import Container from '../Container';
import styles from './styles.module.scss';

interface PopUpCardProps {
	className?: string;
}

const PopUpCard: React.FC<PopUpCardProps> = ({ children, className }) => (
	<div className={[styles.wrapper, className].join(' ')}>
		<Container className={styles.container}>{children}</Container>
	</div>
);

export default PopUpCard;
