import React, { type FormEvent } from 'react';
import Input from '../Components/Input';
import { Stack } from '@mui/material';
import styles from './styles.module.scss';
import Link from '../Components/Link';
import Button from '../Components/Button';
import { useSignUpForm } from './useSignUpForm';
import PasswordInput from '../Components/PasswordInput';
import { PASSWORD_FORMAT } from '../../../../utils/validation';

const SignUpForm = () => {
	const {
		username,
		setUsername,
		email,
		setEmail,
		password,
		setPassword,
		confirmedPassword,
		setConfirmedPassword,
		isSignUpDisabled,
		isLoading,
		signup,
		errors,
	} = useSignUpForm();

	const handleFormSubmit = async (event: FormEvent) => {
		event.preventDefault();
		await signup();
	};

	return (
		<>
			<Stack
				component="form"
				onSubmit={handleFormSubmit}
				id="signup-form"
				spacing={2.5}
				className={styles.inputGroup}
			>
				<Input
					value={username}
					onChange={setUsername}
					label={'Username'}
					placeholder={'Enter username'}
					autoComplete={'username-to-signup'}
					required
					error={!!errors.username}
					helperText={errors.username}
					classes={{
						wrapper: styles.input,
					}}
				/>
				<Input
					value={email}
					onChange={setEmail}
					label={'Email'}
					placeholder={'Enter your email'}
					autoComplete={'email-to-signup'}
					required
					error={!!errors.email}
					helperText={errors.email}
					classes={{
						wrapper: styles.input,
					}}
				/>
				<PasswordInput
					value={password}
					onChange={setPassword}
					label={'Password'}
					placeholder={'Enter password'}
					autoComplete={'password-to-signup'}
					required
					error={!!errors.password}
					success={!errors.password && password.length > 0}
					helperText={PASSWORD_FORMAT}
					classes={{
						wrapper: styles.input,
					}}
				/>
				<PasswordInput
					value={confirmedPassword}
					onChange={setConfirmedPassword}
					label={'Password confirmation'}
					placeholder={'Repeat your password'}
					autoComplete={'confirmed-password-to-signup'}
					required
					error={!!errors.confirmedPassword}
					helperText={errors.confirmedPassword}
					classes={{
						wrapper: styles.input,
					}}
				/>
			</Stack>
			<Link to="/login" className={styles.link}>
				Log In
			</Link>
			<Stack spacing={2.5} className={styles.buttonGroup}>
				<Button
					type="submit"
					form="signup-form"
					className={styles.signup_button}
					disabled={isSignUpDisabled || isLoading}
				>
					Sign Up
				</Button>
				{/* <Typography variant={'body1'} sx={{ width: '100%', textAlign: 'center' }}>or</Typography>
				<ContinueWithGoogle onClick={() => {}} />
				<ContinueWithApple onClick={() => {}} /> */}
			</Stack>
		</>
	);
};

export default SignUpForm;
