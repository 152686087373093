import React from 'react';
import Input from '../Components/Input';
import styles from './styles.module.scss';
import { CircularProgress, Stack } from '@mui/material';
import Button from '../Components/Button';
import useWishListCreateForm from '../../hooks/useWishListCreateForm';

const CreateWishlistForm = () => {
	const {
		name,
		description,
		setDescription,
		setName,
		createWishList,
		isLoading,
		isCreationDisabled,
		errors,
	} = useWishListCreateForm();

	return (
		<div className={styles.wrapper}>
			<Stack spacing={2.5} sx={{ width: '100%' }}>
				<Input
					label={'Name*'}
					value={name}
					onChange={setName}
					disabled={isLoading}
					placeholder={'Enter wishlist name'}
					error={!!errors.name}
					helperText={errors.name}
				/>
				<Input
					label={'Description'}
					value={description}
					onChange={setDescription}
					disabled={isLoading}
					placeholder={'Enter description'}
					rows={3}
				/>
			</Stack>
			<div className={styles.buttonGroup}>
				{isLoading ? (
					<CircularProgress sx={{ margin: '0 auto' }} />
				) : (
					<Button onClick={createWishList} disabled={isCreationDisabled}>
						Create new wishlist
					</Button>
				)}
			</div>
		</div>
	);
};

export default CreateWishlistForm;
