import React, { useEffect, useState } from 'react';
import Container from '../../ui/Components/Container';
import { Typography } from '@mui/material';
import styles from './styles.module.scss';
import TextLogo from '../../ui/Icons/TextLogo';
import CreatePasswordForm from '../../ui/CreatePasswordForm';
import useAuth from '../../context/auth/useAuth';
import LoadingPage from '../LoadingPage';
import UserRepository from '../../../../infrastructure/repositories/User';
import DescriptionCard from '../../ui/DescriptionCard';
import useAnalytics from '../../hooks/useAnalytics';

const CreatePasswordPage = () => {
	const [username, setUsername] = useState('');
	const [error, setError] = useState('');
	const { verifyPasswordResetCode } = useAuth();
	const analytics = useAnalytics();

	useEffect(() => {
		analytics.sendPageViewEvent('create_password_page');
	}, []);

	useEffect(() => {
		const setUser = async () => {
			const email = await verifyPasswordResetCode();
			if (!email) {
				setError('Something went wrong. Please try again');
				return;
			}

			const user = await UserRepository.getUserByEmail(email);
			if (!user) {
				setError('There is no User associated with this email');
				return;
			}

			setUsername(user.username);
		};

		setUser();
	}, []);

	if (error) {
		return <Typography variant={'body1'}>{error}</Typography>;
	}

	if (!username) {
		return <LoadingPage />;
	}

	return (
		<Container sx={{ flexDirection: 'column' }} fullHeight>
			<div className={styles.headerWrapper}>
				<TextLogo />
			</div>

			<div className={styles.content}>
				<Typography color="primary" variant={'h2'} className={styles.title}>
					Create new password for {username}
				</Typography>
				<DescriptionCard className={styles.description}>Enter and repeat your new password</DescriptionCard>
				<CreatePasswordForm />
			</div>
		</Container>
	);
};

export default CreatePasswordPage;
