import { type WishList } from '../../../domain/entities/WishList/WishList';
import WishListsStore from '../../store/wishlists';
import { type WishListId } from '../../../domain/entities/WishList/types';
import FirebaseFirestore from '../../http/firebase/FirebaseFirestore';
import { type UserId } from '../../../domain/entities/User/definitions';

const Firestore = new FirebaseFirestore();

class WishListsRepository {
	private readonly _firestore: FirebaseFirestore;

	constructor(firestore: FirebaseFirestore) {
		this._firestore = firestore;
	}

	public async getWishListsTotalCount(): Promise<number> {
		return this._firestore.getWishListsTotalCount();
	}

	public isWishListLoading(wishlistId: WishListId): Promise<WishList | null> | null {
		return WishListsStore.getWishListLoadingPromise(wishlistId);
	}

	public async fetchWishList(wishlistId: WishListId): Promise<WishList | null> {
		const promise = this._firestore.getWishList(wishlistId);

		WishListsStore.setWishlistLoading(wishlistId, promise);
		const wishlist = await promise;
		WishListsStore.setWishlistLoaded(wishlistId);
		if (!wishlist) {
			return null;
		}

		WishListsStore.addWishList(wishlist);
		return wishlist;
	}

	public async fetchWishLists(): Promise<void> {
		const wishLists = await this._firestore.getWishlists();
		if (!wishLists.length) {
			return;
		}

		WishListsStore.setWishLists(wishLists);
	}

	public getWishLists(): WishList[] {
		return WishListsStore.getWishLists();
	}

	public getWishListsByUserId(userId: UserId): WishList[] {
		return WishListsStore.getWishLists().filter((wishlist) => wishlist.userId === userId);
	}

	public async createWishList(name: string, description: string, isMain = false): Promise<void> {
		const wishlist = await this._firestore.createWishList(name, description, isMain);
		if (wishlist === null) {
			return;
		}

		WishListsStore.addWishList(wishlist);
	}

	public getWishListById(wishlistId: WishListId): WishList | null {
		return WishListsStore.getWishListById(wishlistId);
	}

	public async updateWishList(wishlistId: WishListId, name?: string, description?: string): Promise<void> {
		await this._firestore.updateWishList(wishlistId, {
			...(name && { name }),
			...(description && { description }),
		});

		if (name !== undefined) {
			WishListsStore.setWishListName(wishlistId, name);
		}

		if (description !== undefined) {
			WishListsStore.setWishListDescription(wishlistId, description);
		}
	}

	public async getPreviewImage(wishlistId: WishListId): Promise<string> {
		return this._firestore.getWishListPreviewImage(wishlistId);
	}

	public async deleteWishList(wishlistId: WishListId): Promise<void> {
		await this._firestore.deleteWishList(wishlistId);
		WishListsStore.deleteWishListById(wishlistId);
	}
}

export default new WishListsRepository(Firestore);
