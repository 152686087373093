import React from 'react';
import styles from './styles.module.scss';
import { BackIcon } from '../../Icons';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface BackLinkProps {
	label: string;
	to: string;
}

const BackLink: React.FC<BackLinkProps> = ({ label, to }) => {
	const navigate = useNavigate();
	return (
		<div className={styles.backButton}>
			<button
				onClick={() => {
					navigate(to);
				}}
			>
				<BackIcon />
				<Typography variant="body1">{label}</Typography>
			</button>
		</div>
	);
};

export default BackLink;
