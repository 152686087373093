const ASC = 'asc';
const DESC = 'desc';

type Order = typeof ASC | typeof DESC;

export function sortByCreatedAt<T extends { createdAt: Date }>(array: T[], order: Order = ASC): T[] {
	const sortAsc = () => array.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());
	const sortDsc = () => array.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
	const orderToMethod = {
		[ASC]: sortAsc,
		[DESC]: sortDsc,
	};

	return orderToMethod[order]();
}
