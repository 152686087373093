import React from 'react';
import colors from './colors.module.scss';
import './fonts.scss';
import './global.scss';
import {
	createTheme,
	type Theme as MuiTheme,
	ThemeProvider,
	StyledEngineProvider,
} from '@mui/material/styles';

const theme: MuiTheme = createTheme({
	palette: {
		primary: {
			light: colors.primaryLight,
			main: colors.primary,
		},
		secondary: {
			main: '#ffffff',
		},
		error: {
			main: colors.error,
		},
	},
	typography: {
		fontFamily: 'Nunito, sans-serif',
		h1: {
			fontFamily: 'Montserrat Alternates',
			fontWeight: 700,
			fontSize: 64,
			lineHeight: '140%',
			letterSpacing: '-0.05em',
			color: colors.black,
		},
		h2: {
			fontFamily: 'Montserrat Alternates',
			fontWeight: 700,
			fontSize: 24,
			lineHeight: '127%',
			letterSpacing: '-0.05em',
			color: colors.black,
		},
		h3: {
			fontWeight: 600,
			fontSize: 16,
			lineHeight: '148%',
			color: colors.black,
		},
		h4: {
			fontWeight: 600,
			fontSize: 14,
			lineHeight: '148%',
			color: colors.black,
		},
		body1: {
			fontSize: 16,
			lineHeight: 1.48,
			color: colors.black,
		},
		body2: {
			fontSize: 12,
			lineHeight: 1.48,
			color: colors.black,
		},
		button: {
			fontFamily: 'Nunito, sans-serif',
			fontSize: 16,
			lineHeight: '127%',
		},
		fontSize: 16,
	},
});

const Theme: React.FC = ({ children }) => (
	<ThemeProvider theme={theme}>
		<StyledEngineProvider injectFirst>{children}</StyledEngineProvider>
	</ThemeProvider>
);

export default Theme;
