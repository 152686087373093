import { observable, observe } from 'mobx';
import type User from '../../../domain/entities/User';

interface UserStore {
	user: User | null;
	visitorEmail: string;
	isUserLoggedIn: boolean;
}

export const USER_AUTHORIZED = 'wishly.authorized';
export const AUTHORIZED_VALUE = '1.1';

export const userStore = observable<UserStore>({
	user: null,
	visitorEmail: '',
	isUserLoggedIn: localStorage.getItem(USER_AUTHORIZED) === AUTHORIZED_VALUE,
});

export { observe };
