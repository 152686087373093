import React, { useEffect, useState } from 'react';
import Header from '../../ui/Header';
import Container from '../../ui/Components/Container';
import { Typography } from '@mui/material';
import { Navigate, useParams } from 'react-router-dom';
import EditWishItemForm from '../../ui/EditWishItemForm';
import styles from './styles.module.scss';
import WishItemRepository from '../../../../infrastructure/repositories/WishItem';
import BackLink from '../../ui/Components/BackLink';
import useAnalytics from '../../hooks/useAnalytics';

const EditWishItemPage = () => {
	const { wishItemId } = useParams();
	const [wishItemName, setWishItemName] = useState('');
	const analytics = useAnalytics();

	// TODO: Rework it with TODO #1
	// Too much effort to get the name

	useEffect(() => {
		analytics.sendPageViewEvent('edit_wishitem_page');
	}, []);

	useEffect(() => {
		if (!wishItemId) return;

		const wishitem = WishItemRepository.getWishItemById(wishItemId);

		if (wishitem?.title) {
			setWishItemName(wishitem.title);
		} else {
			const fetchWishItemName = async () => {
				if (!wishItemId) return;
				const promise =
					WishItemRepository.isWishItemLoading(wishItemId) ??
					WishItemRepository.fetchWishItemById(wishItemId);
				const wishitem = await promise;
				if (!wishitem?.title) return;

				setWishItemName(wishitem.title);
			};

			fetchWishItemName();
		}
	}, []);

	if (!wishItemId) {
		return <Navigate to="/" />;
	}

	return (
		<Container className={styles.container} fullHeight>
			<Header />
			<BackLink label={wishItemName} to={`/wish/${wishItemId}`} />
			<div className={styles.content}>
				<Typography variant={'h2'}>Edit wish</Typography>
				<EditWishItemForm wishItemId={wishItemId} />
			</div>
		</Container>
	);
};

export default EditWishItemPage;
