import { type UserId, type Username } from './definitions';
import { type Email } from '../Auth/definitions';

export default class User {
	private readonly _id: UserId;

	private _username: Username;
	private _email: Email;

	constructor(username: Username, email: Email, id: UserId) {
		this._username = username;
		this._email = email;
		this._id = id;
	}

	public get username(): Username {
		return this._username;
	}

	public set username(username: Username) {
		this._username = username;
	}

	public get email(): Email {
		return this._email;
	}

	public set email(email: Email) {
		this._email = email;
	}

	public get id(): UserId {
		return this._id;
	}
}
