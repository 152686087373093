import React from 'react';
import { usePasswordForm } from './usePasswordForm';
import Button from '../Components/Button';
import { PASSWORD_FORMAT } from '../../../../utils/validation';
import PasswordInput from '../Components/PasswordInput';
import styles from './styles.module.scss';

const ChangePasswordForm = () => {
	const {
		currentPassword,
		setCurrentPassword,
		newPassword,
		setNewPassword,
		newPasswordCopy,
		setNewPasswordCopy,
		isButtonDisabled,
		errors,
		saveChanges,
	} = usePasswordForm();

	return (
		<div className={styles.wrapper}>
			<PasswordInput
				label={'Current password'}
				value={currentPassword}
				onChange={setCurrentPassword}
				placeholder={'Enter your current password'}
				autoComplete={'password-to-edit'}
				error={!!errors.currentPassword}
				helperText={errors.currentPassword}
				classes={{
					wrapper: styles.input,
				}}
			/>
			<PasswordInput
				label={'New password'}
				value={newPassword}
				onChange={setNewPassword}
				placeholder={'Enter a new password'}
				autoComplete={'new-password'}
				error={!!errors.newPassword}
				success={!!newPassword.length && !errors.newPassword}
				helperText={PASSWORD_FORMAT}
				classes={{
					wrapper: styles.input,
				}}
			/>
			<PasswordInput
				label={'Repeat new password'}
				value={newPasswordCopy}
				onChange={setNewPasswordCopy}
				placeholder={'Repeat your new password'}
				autoComplete={'repeat-new-password'}
				error={!!errors.newPasswordCopy}
				helperText={errors.newPasswordCopy}
				classes={{
					wrapper: styles.input,
				}}
			/>
			<Button classes={{ wrapper: styles.button }} disabled={isButtonDisabled} onClick={saveChanges}>
				Update Password
			</Button>
		</div>
	);
};

export default ChangePasswordForm;
