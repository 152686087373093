import React, { useState } from 'react';
import Modal from '../Components/Modal';
import PopUpCard from '../Components/PopUpCard';
import Button from '../Components/Button';
import { CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Input from '../Components/Input';
import useAuth from '../../context/auth/useAuth';
import styles from './styles.module.scss';
import { CloseIcon } from '../Icons';
import useBreakpoints from '../../hooks/useBreakpoints';

interface BookModalVisitorProps {
	isOpened: boolean;
	email: string;
	setEmail: (email: string) => void;
	continueUrl: string; // Is set when user clicks login or signup
	onBook?: () => Promise<void>;
	onOutsideClick?: () => void;
	error?: string;
}

const BookModalVisitor: React.FC<BookModalVisitorProps> = ({
	isOpened = false,
	onOutsideClick,
	onBook,
	email,
	setEmail,
	error,
	continueUrl,
}) => {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const auth = useAuth();
	const { isDesktop } = useBreakpoints();

	const handleBookByEmail = async () => {
		setIsLoading(true);
		try {
			if (onBook) {
				await onBook();
			}
		} finally {
			setIsLoading(false);
		}
	};

	// TODO: Also set callback that item immediately booked when user is logged in
	const login = () => {
		navigate('/login');
		auth.continueUrl = continueUrl;
	};

	// TODO: Also set callback that item immediately booked when user is signed up
	const signup = () => {
		navigate('/signup');
		auth.continueUrl = continueUrl;
	};

	return (
		<Modal isOpened={isOpened} onClickOutside={onOutsideClick}>
			<PopUpCard>
				{isDesktop && (
					<IconButton classes={{ root: styles.closeButton }} onClick={onOutsideClick}>
						<CloseIcon color="primary" />
					</IconButton>
				)}
				<Stack spacing={2} className={styles.wrapper}>
					<div>
						<Typography variant={'h3'}>Book this wish</Typography>
						<Typography variant="body1">
							You can enter your wishly account or create new one if you don’t have any. It will take you 2
							minutes
						</Typography>
					</div>
					<Button onClick={login}>Log In</Button>
					<Button variant="outlined" onClick={signup}>
						Sign Up
					</Button>
					<Typography variant="body1" sx={{ textAlign: 'center' }}>
						or
					</Typography>
					<Typography variant="body1">You can book the wish just using your e-mail account</Typography>
					<div style={{ marginTop: '12px' }}>
						<Input
							value={email}
							onChange={setEmail}
							label="E-mail"
							placeholder="Enter your e-mail"
							error={!!error}
							helperText={error}
						/>
						<Button className={styles.bookButton} onClick={handleBookByEmail}>
							{isLoading ? (
								<CircularProgress color="secondary" size={25} sx={{ margin: '0 auto' }} />
							) : (
								'Book wish'
							)}
						</Button>
					</div>
				</Stack>
			</PopUpCard>
		</Modal>
	);
};

export default BookModalVisitor;
