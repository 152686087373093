import { useState } from 'react';
import UserRepository from '../../../../infrastructure/repositories/User';
import { passwordValidation, requiredValidation } from '../../../../utils/validation';

interface UsePasswordFormControls {
	currentPassword: string;
	newPassword: string;
	newPasswordCopy: string;
	setCurrentPassword: (currentPassword: string) => void;
	setNewPassword: (newPassword: string) => void;
	setNewPasswordCopy: (setNewPasswordCopy: string) => void;
	saveChanges: () => Promise<void>;
	isButtonDisabled: boolean;
	errors: {
		currentPassword: string;
		newPassword: string;
		newPasswordCopy: string;
	};
	isLoading: boolean;
}

export const usePasswordForm = (): UsePasswordFormControls => {
	const [currentPassword, setCurrentPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [newPasswordCopy, setNewPasswordCopy] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const [errors, setErrors] = useState({
		currentPassword: '',
		newPassword: '',
		newPasswordCopy: '',
	});

	const validatePassword = (value: string) => {
		const validationResult = requiredValidation(value);
		setErrors((prev) => ({
			...prev,
			currentPassword: validationResult,
		}));
	};

	const validateNewPassword = (value: string) => {
		const validationResult = passwordValidation(value);
		setErrors((prev) => ({
			...prev,
			newPassword: validationResult,
		}));
	};

	const validateNewPasswordCopy = (value: string) => {
		const validationResult = passwordValidation(value);
		setErrors((prev) => ({
			...prev,
			newPasswordCopy: validationResult,
		}));
	};

	const handleSetPassword = (value: string) => {
		validatePassword(value);
		setCurrentPassword(value);
	};

	const handleSetNewPassword = (value: string) => {
		validateNewPassword(value);
		setNewPassword(value);
	};

	const handleSetNewPasswordCopy = (value: string) => {
		validateNewPasswordCopy(value);
		setNewPasswordCopy(value);
	};

	const saveChanges = async () => {
		validatePassword(currentPassword);
		validateNewPassword(newPassword);
		validateNewPasswordCopy(newPasswordCopy);
		const disabled = isSavingDisabled();
		if (disabled) {
			return;
		}

		setIsLoading(true);
		try {
			await UserRepository.updatePassword(currentPassword, newPassword);
		} finally {
			setIsLoading(false);
		}
	};

	const isSavingDisabled = () => {
		const isThereAnyError = !!Object.values(errors).find((value) => value.length);
		const notEverythingIsFilled = currentPassword === '' || newPassword === '' || newPasswordCopy === '';
		return isThereAnyError || notEverythingIsFilled;
	};

	return {
		currentPassword,
		newPassword,
		newPasswordCopy,
		setCurrentPassword: handleSetPassword,
		setNewPassword: handleSetNewPassword,
		setNewPasswordCopy: handleSetNewPasswordCopy,
		errors,
		saveChanges,
		isButtonDisabled: isSavingDisabled(),
		isLoading,
	};
};
