import type User from '../../../domain/entities/User';
import UserStore from '../../store/user';
import FirebaseAuthService from '../../../services/auth/firebase/FirebaseAuthService';
import FirebaseFirestore from '../../http/firebase/FirebaseFirestore';
import { type UserId } from '../../../domain/entities/User/definitions';

const FirebaseAuth = new FirebaseAuthService();
const Firestore = new FirebaseFirestore();

class UserRepository {
	private readonly _authService: FirebaseAuthService;
	private readonly _firestoreService: FirebaseFirestore;

	constructor(firebaseAuthService: FirebaseAuthService, firebaseFirestore: FirebaseFirestore) {
		this._authService = firebaseAuthService;
		this._firestoreService = firebaseFirestore;
	}

	public getCurrentUser(): User | null {
		return UserStore.getUser();
	}

	public getVisitorEmail(): string {
		return UserStore.getVisitorEmail();
	}

	public setVisitorEmail(email: string): void {
		UserStore.setVisitorEmail(email);
	}

	public getIsUserLoggedIn(): boolean {
		return UserStore.getIsUserLoggedIn();
	}

	public setIsUserLoggedIn(isUserLoggedIn: boolean): void {
		UserStore.setIsUserLoggedIn(isUserLoggedIn);
	}

	public async getUserById(userId: UserId): Promise<User | null> {
		return this._firestoreService.getUserById(userId);
	}

	public async getUserByUsername(username: string): Promise<User | null> {
		return this._firestoreService.getUserByUsername(username);
	}

	public async checkIsUsernameTaken(username: string): Promise<boolean> {
		return this._firestoreService.checkIfUsernameIsTaken(username);
	}

	public async getUserByEmail(email: string): Promise<User | null> {
		return this._firestoreService.getUserByEmail(email);
	}

	public async setUsername(username: string): Promise<void> {
		const user = this.getCurrentUser();
		if (!user) return;
		await this._firestoreService.updateUsername(user.id, username);
		user.username = username;
	}

	public async setUserEmail(email: string): Promise<void> {
		const user = this.getCurrentUser();
		if (!user) return;
		await this._authService.updateEmail(email);
		await this._firestoreService.updateUserEmail(user.id, email);
		user.email = email;
	}

	public async confirmPassword(password: string): Promise<boolean> {
		return this._authService.isPasswordCorrect(password);
	}

	public async updatePassword(currentPassword: string, updatedPassword: string): Promise<void> {
		const user = this.getCurrentUser();
		const firebaseUser = this._authService.getFirebaseUser();
		if (!user || !firebaseUser) {
			throw new Error('No user available');
		}

		const isPasswordCorrect = await this._authService.isPasswordCorrect(currentPassword);
		if (!isPasswordCorrect) {
			throw new Error('Wrong password');
		}

		await this._authService.updateUserPassword(updatedPassword);
	}

	public async createUser(email: string, username: string): Promise<User> {
		return this._firestoreService.createUser(username, email);
	}

	public async deleteUser(): Promise<void> {
		const user = this.getCurrentUser();
		if (!user) return;

		const wishlists = await this._firestoreService.getWishlists();

		await this._authService.deleteUser();
		await this._firestoreService.deleteUser(user.id);
		await this._firestoreService.deleteBookingsByUserId(user.id);

		await Promise.all(wishlists.map(async (wishlist) => this._firestoreService.deleteWishList(wishlist.id)));
	}
}

export default new UserRepository(FirebaseAuth, Firestore);
