import React, { type ButtonHTMLAttributes } from 'react';
import styles from './style.module.scss';
import { Button as MuiButton } from '@mui/material';

interface ButtonStyles {
	wrapper?: string;
	button?: string;
}

interface ButtonProps {
	type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
	form?: ButtonHTMLAttributes<HTMLButtonElement>['form'];
	variant?: 'contained' | 'outlined';
	onClick?: () => void;
	disabled?: boolean;
	className?: string;
	classes?: ButtonStyles;
	size?: 'large' | 'medium' | 'small';
}

const Button: React.FC<ButtonProps> = ({
	children,
	onClick,
	variant = 'contained',
	disabled,
	className,
	size = 'large',
	type,
	form,
	classes,
}) => {
	const handleClick = () => {
		if (disabled) {
			return;
		}

		if (onClick) {
			onClick();
		}
	};

	return (
		<div className={[styles.wrapper, classes?.wrapper].join(' ')}>
			<MuiButton
				className={className}
				classes={{
					root: [
						styles.button,
						styles[`button__${variant}`],
						styles[`button__${size}`],
						classes?.button,
					].join(' '),
				}}
				color={'primary'}
				variant={variant}
				onClick={handleClick}
				disabled={disabled}
				disableRipple
				type={type}
				form={form}
			>
				{children}
			</MuiButton>
		</div>
	);
};

export default Button;
