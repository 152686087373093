import React from 'react';
import styles from './styles.module.scss';
import Carousel from '../Carousel';
import Button from '../Button';
import { CloseIcon, DeleteIcon } from '../../Icons';
import { Modal } from '@mui/material';
import useBreakpoints from '../../../hooks/useBreakpoints';

interface LightBoxProps {
	imageIndex: number | null;
	setImageIndex: (index: number) => void;
	onClose: () => void;
	removeImage?: (index: number) => void;
	images: Array<string | File>;
}

const LightBox: React.FC<LightBoxProps> = ({ images, imageIndex, setImageIndex, onClose, removeImage }) => {
	const { isDesktop } = useBreakpoints();

	if (imageIndex === null || !images.length) return null;

	return (
		<Modal
			open={true}
			onClose={onClose}
			classes={{ root: styles.wrapper }}
			componentsProps={{ backdrop: { classes: { root: styles.backdrop } } }}
		>
			<div className={styles.content}>
				<Button
					size="small"
					classes={{
						wrapper: styles.closeButtonWrapper,
						button: styles.closeButton,
					}}
					onClick={onClose}
				>
					<CloseIcon />
				</Button>
				<Carousel
					afterChange={(index) => setImageIndex(index)}
					classes={{ dots: styles.dots, root: styles.carousel }}
					adaptiveHeight={false}
					initialSlide={imageIndex}
					currentSlide={imageIndex}
					arrows={isDesktop}
				>
					{images.map((image, index) => (
						<img
							key={index}
							alt=""
							src={typeof image === 'string' ? image : URL.createObjectURL(image)}
							className={styles.image}
						/>
					))}
				</Carousel>
				{!!removeImage && (
					<Button
						size="small"
						classes={{
							wrapper: styles.deleteButtonWrapper,
							button: styles.deleteButton,
						}}
						onClick={() => removeImage(imageIndex)}
					>
						<DeleteIcon />
					</Button>
				)}
			</div>
		</Modal>
	);
};

export default LightBox;
