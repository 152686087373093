import {
	addDoc,
	collection,
	deleteDoc,
	doc,
	type Firestore,
	getCountFromServer,
	getDoc,
	getDocs,
	getFirestore,
	limit as fireStoreLimit,
	query,
	updateDoc,
	where,
	type Timestamp,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import {
	deleteObject,
	type FirebaseStorage,
	getDownloadURL,
	getStorage,
	ref,
	uploadBytes,
} from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import FirebaseApp from '../../../services/firebase/FirebaseApp';
import { WishList } from '../../../domain/entities/WishList/WishList';
import UserStore from '../../store/user';
import { type WishListData } from '../../../domain/entities/WishList/interfaces/WishListData';
import { WishItem } from '../../../domain/entities/WishItem/WishItem';
import { type WishListId } from '../../../domain/entities/WishList/types';
import { type WishItemData } from '../../../domain/entities/WishItem/interfaces/WishItemData';
import { getFileExtension } from '../../../utils/getFileExtension';
import { type WishItemId } from '../../../domain/entities/WishItem/types';
import { CUSTOM_ERROR_NAME } from '../../../utils/errors';
import { type UserId } from '../../../domain/entities/User/definitions';
import User from '../../../domain/entities/User';
import Booking from '../../../domain/entities/Booking';
import { type BookingData, type BookingId } from '../../../domain/entities/Booking/definitions';
import { type WishListFields } from '../../../domain/entities/WishList/interfaces/WishListFIelds';
import { type WishItemFields } from '../../../domain/entities/WishItem/interfaces/WishItemFields';

const TABLE_WISHLISTS = 'wishlists';
const TABLE_WISHITEMS = 'wishitems';
const TABLE_BOOKINGS = 'bookings';
const TABLE_USERS = 'users';

const FOLDER_WISHITEMS = 'wishitems';

type WishListDataFirebase = Omit<WishListData, 'createdAt'> & { createdAt?: Timestamp };
type WishItemDataFirebase = Omit<WishItemData, 'createdAt'> & { createdAt?: Timestamp };

class FirebaseFirestore {
	private readonly _firestore: Firestore;
	private readonly _storage: FirebaseStorage;

	constructor() {
		this._firestore = getFirestore(FirebaseApp);
		this._storage = getStorage(FirebaseApp);
	}

	public async getWishlists(limit?: number): Promise<WishList[]> {
		const userId = UserStore.getUser()?.id;
		if (!userId) {
			return [];
		}

		const c = collection(this._firestore, TABLE_WISHLISTS);
		const w = where('userId', '==', userId);
		const q = limit ? query(c, w, fireStoreLimit(limit)) : query(c, w);
		const querySnapshot = await getDocs(q);

		const data: WishList[] = [];

		querySnapshot.forEach((doc) => {
			const wishListData = doc.data() as WishListDataFirebase;
			data.push(
				new WishList({
					...wishListData,
					id: doc.id,
					createdAt: wishListData.createdAt?.toDate() ?? new Date(),
				}),
			);
		});

		return data;
	}

	public async getWishListsTotalCount(): Promise<number> {
		const userId = UserStore.getUser()?.id;
		if (!userId) {
			return 0;
		}

		const c = collection(this._firestore, TABLE_WISHLISTS);
		const w = where('userId', '==', userId);
		const q = query(c, w);
		const querySnapshot = await getCountFromServer(q);
		return querySnapshot.data().count;
	}

	public async getWishList(wishlistId: WishListId): Promise<WishList | null> {
		if (!wishlistId) return null;

		const d = doc(this._firestore, TABLE_WISHLISTS, wishlistId);
		const documentSnapshot = await getDoc(d);

		if (!documentSnapshot.exists()) {
			return null;
		}

		const data = documentSnapshot.data() as WishListDataFirebase;

		return new WishList({
			...data,
			id: wishlistId,
			createdAt: data.createdAt?.toDate() ?? new Date(),
		});
	}

	public async createWishList(name: string, description: string, isMain = false): Promise<WishList | null> {
		const userId = UserStore.getUser()?.id;
		if (!userId) {
			return null;
		}

		const createdAt = new Date();

		const docRef = await addDoc(collection(this._firestore, TABLE_WISHLISTS), {
			name,
			description,
			userId,
			createdAt,
		});

		return new WishList({ id: docRef.id, userId, name, description, isMain, createdAt });
	}

	public async createWishItem(wishItem: {
		title: string;
		description: string;
		link: string;
		quantity: number;
		images: File[];
		wishlist: WishListId;
	}): Promise<WishItem | null> {
		const userId = UserStore.getUser()?.id;
		if (!userId) {
			return null;
		}

		const uploadPromises = wishItem.images.map(async (image) => {
			const id = uuidv4();
			const storageRef = ref(
				this._storage,
				`${FOLDER_WISHITEMS}/${userId}/${id}.${getFileExtension(image.name)}`,
			);
			return uploadBytes(storageRef, image);
		});

		const imagesResult = await Promise.all(uploadPromises);
		const getUrlPromises = imagesResult.map(async (image) => getDownloadURL(image.ref));

		const imageUrls = await Promise.all(getUrlPromises);
		const createdAt = new Date();

		const docRef = await addDoc(collection(this._firestore, TABLE_WISHITEMS), {
			userId,
			bookedQuantity: 0,
			description: wishItem.description,
			images: imageUrls,
			link: wishItem.link,
			title: wishItem.title,
			wishlist: wishItem.wishlist,
			quantity: wishItem.quantity,
			createdAt,
		});

		return new WishItem({
			id: docRef.id,
			userId,
			...wishItem,
			createdAt,
			bookings: [],
			images: imageUrls,
		});
	}

	public async getWishItems(wishlistId: WishListId, limit?: number): Promise<WishItem[]> {
		const c = collection(this._firestore, TABLE_WISHITEMS);
		const w = where('wishlist', '==', wishlistId);
		const q = typeof limit === 'number' ? query(c, w, fireStoreLimit(limit)) : query(c, w);

		const querySnapshot = await getDocs(q);
		const data: WishItem[] = [];

		const promises: Array<Promise<unknown>> = [];
		querySnapshot.forEach((doc) => {
			const c = collection(this._firestore, TABLE_BOOKINGS);
			const w = where('wishItemId', '==', doc.id);
			const q = query(c, w);
			promises.push(getDocs(q));
		});
		const allBookingsSnapshots = await Promise.all(promises);
		const bookingsMap = new Map<WishItemId, Booking[]>();
		allBookingsSnapshots.forEach((bookingList) => {
			const data = (
				bookingList as { docs: Array<{ id: BookingId; data(): Omit<BookingData, 'id'> }> }
			).docs.map(
				(booking) =>
					new Booking({
						...booking.data(),
						id: booking.id,
					}),
			);
			if (!data?.length) return;
			bookingsMap.set(data[0].wishItemId, data);
		});

		querySnapshot.forEach((doc) => {
			const bookings = bookingsMap.get(doc.id) ?? [];
			const wishItemData = doc.data() as Omit<WishItemDataFirebase, 'bookings'>;
			data.push(
				new WishItem({
					...wishItemData,
					bookings,
					id: doc.id,
					createdAt: wishItemData.createdAt?.toDate() ?? new Date(),
				}),
			);
		});
		return data;
	}

	public async getWishItemsTotalCount(wishlistId: WishListId): Promise<number> {
		const c = collection(this._firestore, TABLE_WISHITEMS);
		const w = where('wishlist', '==', wishlistId);
		const q = query(c, w);

		const querySnapshot = await getCountFromServer(q);
		return querySnapshot.data().count;
	}

	public async getWishItem(wishItemId: WishItemId): Promise<WishItem | null> {
		const d = doc(this._firestore, TABLE_WISHITEMS, wishItemId);
		const documentSnapshot = await getDoc(d);

		if (!documentSnapshot.exists()) {
			return null;
		}

		const bookings = await this.getBookings(wishItemId);
		const data = documentSnapshot.data() as Omit<WishItemDataFirebase, 'bookings'>;

		return new WishItem({
			...data,
			bookings,
			id: wishItemId,
			createdAt: data.createdAt?.toDate() ?? new Date(),
		});
	}

	public async deleteWishItem(wishItemId: WishItemId): Promise<void> {
		const wishItem = await this.getWishItem(wishItemId);
		if (!wishItem) {
			return;
		}

		const d = doc(this._firestore, TABLE_WISHITEMS, wishItemId);
		await deleteDoc(d);

		if (wishItem.images.length) {
			const promises = wishItem.images.map(async (image) => {
				const filename = image.split('/').pop()?.split('?')[0];
				if (!filename) {
					return Promise.resolve();
				}

				const storageRef = ref(this._storage, decodeURIComponent(filename));
				return deleteObject(storageRef);
			});

			await Promise.all(promises);
		}

		// Delete all bookings for this item
		const c = collection(this._firestore, TABLE_BOOKINGS);
		const w = where('wishItemId', '==', wishItemId);
		const q = query(c, w);
		const docs = await getDocs(q);
		const bookingsPromises: Array<Promise<void>> = [];
		docs.forEach((doc) => bookingsPromises.push(deleteDoc(doc.ref)));

		await Promise.all(bookingsPromises);
	}

	public async deleteWishList(wishlistId: WishListId): Promise<void> {
		const d = doc(this._firestore, TABLE_WISHLISTS, wishlistId);
		await deleteDoc(d);

		// Delete items
		const items = await this.getWishItems(wishlistId);
		await Promise.all(items.map(async (item) => this.deleteWishItem(item.id)));
	}

	public async setWishItemBooked({
		id,
		userId,
		quantity,
		email,
	}: {
		id: WishItemId;
		userId?: UserId;
		quantity: number;
		email?: string;
	}): Promise<BookingId> {
		const wishItem = await this.getWishItem(id);

		if (!wishItem) {
			const error = new Error('This WishItem does not exist');
			error.name = CUSTOM_ERROR_NAME;
			throw error;
		}

		if (wishItem.isBooked) {
			const error = new Error('This WishItem already booked');
			error.name = CUSTOM_ERROR_NAME;
			throw error;
		}

		if (wishItem.quantity < quantity || wishItem.quantity - wishItem.bookedQuantity < quantity) {
			const error = new Error('Incorrect quantity. Please try again with smaller number');
			error.name = CUSTOM_ERROR_NAME;
			throw error;
		}

		let existingBooking: Booking | null = null;
		if (userId) {
			existingBooking = wishItem.getBookingByUserId(userId);
		}

		if (email) {
			existingBooking = wishItem.getBookingByEmail(email);
		}

		if (existingBooking) {
			const d = doc(this._firestore, TABLE_BOOKINGS, existingBooking.id);
			await updateDoc(d, 'quantity', existingBooking.quantity + quantity);
			return existingBooking.id;
		}

		const newBooking = {
			email: email ?? '',
			quantity,
			userId: userId ?? '',
			wishItemId: id,
		};

		const addedBooking = await addDoc(collection(this._firestore, TABLE_BOOKINGS), newBooking);

		// Send email for guest users
		if (email) {
			const functions = getFunctions(FirebaseApp);
			const sendBookingEmail = httpsCallable(functions, 'sendBookingMail');
			await sendBookingEmail({ email, bookingId: addedBooking.id, wishId: wishItem.id });
		}

		return addedBooking.id;
	}

	public async getBookings(wishItemId: WishItemId): Promise<Booking[]> {
		const c = collection(this._firestore, TABLE_BOOKINGS);
		const w = where('wishItemId', '==', wishItemId);
		const q = query(c, w);
		const docs = await getDocs(q);
		const result: Booking[] = [];
		docs.forEach((doc) => {
			const bookingId = doc.id;
			const bookingData = doc.data() as Omit<BookingData, 'id'>;
			const booking = new Booking({
				id: bookingId,
				...bookingData,
			});
			result.push(booking);
		});
		return result;
	}

	public async getBookingById(bookingId: BookingId): Promise<Booking | null> {
		const d = doc(this._firestore, TABLE_BOOKINGS, bookingId);
		const documentSnapshot = await getDoc(d);
		if (!documentSnapshot.exists()) return null;
		const data = documentSnapshot.data() as Omit<BookingData, 'id'>;
		return new Booking({
			id: documentSnapshot.id,
			...data,
		});
	}

	public async deleteBooking(bookingId: BookingId): Promise<void> {
		const d = doc(this._firestore, TABLE_BOOKINGS, bookingId);
		await deleteDoc(d);
	}

	public async getUserById(userId: UserId): Promise<User | null> {
		const d = doc(this._firestore, TABLE_USERS, userId);
		const documentSnapshot = await getDoc(d);
		if (!documentSnapshot.exists()) return null;
		const data = documentSnapshot.data() as { email: string; username: string };
		return new User(data.username, data.email, documentSnapshot.id);
	}

	public async getUserByEmail(email: string): Promise<User | null> {
		const c = collection(this._firestore, TABLE_USERS);
		const w = where('email', '==', email);
		const q = query(c, w);
		const docs = await getDocs(q);
		const users = docs.docs;
		if (!users.length) return null;
		const user = users[0];
		const userId = user.id;
		const userData = user.data() as { email: string; username: string };
		return new User(userData.username, userData.email, userId);
	}

	public async checkIfUsernameIsTaken(username: string): Promise<boolean> {
		const c = collection(this._firestore, TABLE_USERS);
		const w = where('username', '==', username);
		const q = query(c, w);
		const querySnapshot = await getCountFromServer(q);
		return querySnapshot.data().count > 0;
	}

	public async getUserByUsername(username: string): Promise<User | null> {
		const c = collection(this._firestore, TABLE_USERS);
		const w = where('username', '==', username);
		const q = query(c, w);
		const docs = await getDocs(q);
		const users = docs.docs;
		if (!users.length) return null;
		const user = users[0];
		const userId = user.id;
		const userData = user.data() as { email: string; username: string };
		return new User(userData.username, userData.email, userId);
	}

	public async updateUserEmail(userId: UserId, email: string): Promise<void> {
		const d = doc(this._firestore, TABLE_USERS, userId);
		await updateDoc(d, 'email', email);
	}

	public async updateUsername(userId: UserId, username: string): Promise<void> {
		const d = doc(this._firestore, TABLE_USERS, userId);
		await updateDoc(d, 'username', username);
	}

	public async createUser(username: string, email: string): Promise<User> {
		const user = await addDoc(collection(this._firestore, TABLE_USERS), { username, email });
		return new User(username, email, user.id);
	}

	public async deleteUser(userId: UserId): Promise<void> {
		const d = doc(this._firestore, TABLE_USERS, userId);
		await deleteDoc(d);
	}

	public async deleteBookingsByUserId(userId: UserId): Promise<void> {
		if (!userId) return;

		const c = collection(this._firestore, TABLE_BOOKINGS);
		const w = where('userId', '==', userId);
		const q = query(c, w);
		const docs = await getDocs(q);
		const bookingsPromises: Array<Promise<void>> = [];
		docs.forEach((doc) => bookingsPromises.push(deleteDoc(doc.ref)));

		await Promise.all(bookingsPromises);
	}

	public async updateWishList(wishlistId: WishListId, updatedFields: Partial<WishListFields>): Promise<void> {
		const docRef = doc(this._firestore, TABLE_WISHLISTS, wishlistId);
		await updateDoc(docRef, { ...updatedFields });
	}

	public async updateWishItem(wishItemId: WishItemId, updatedFields: Partial<WishItemFields>): Promise<void> {
		const docRef = doc(this._firestore, TABLE_WISHITEMS, wishItemId);
		const { images: updatedImages } = updatedFields;

		// Check images
		if (updatedImages) {
			const snapshot = await getDoc(docRef);
			if (!snapshot.exists()) return;
			const { images } = snapshot.data() as WishItemDataFirebase;
			const imagesToRemove = images.filter((image) => !updatedImages.includes(image));

			if (imagesToRemove.length) {
				const promises = imagesToRemove.map(async (image) => {
					const filename = image.split('/').pop()?.split('?')[0];
					if (!filename) {
						return Promise.resolve();
					}

					const storageRef = ref(this._storage, decodeURIComponent(filename));
					return deleteObject(storageRef);
				});

				await Promise.all(promises);
			}
		}

		await updateDoc(docRef, { ...updatedFields });
	}

	public async uploadWishItemImages(imagesToUpload: File[]): Promise<string[]> {
		const userId = UserStore.getUser()?.id;
		if (!userId) {
			return [];
		}

		const uploadPromises = imagesToUpload.map(async (image) => {
			const id = uuidv4();
			const storageRef = ref(
				this._storage,
				`${FOLDER_WISHITEMS}/${userId}/${id}.${getFileExtension(image.name)}`,
			);
			return uploadBytes(storageRef, image);
		});

		const imagesResult = await Promise.all(uploadPromises);
		const getUrlPromises = imagesResult.map(async (image) => getDownloadURL(image.ref));

		return Promise.all(getUrlPromises);
	}

	public async getWishListPreviewImage(wishlistId: WishListId): Promise<string> {
		const c = collection(this._firestore, TABLE_WISHITEMS);
		const wishlistFilter = where('wishlist', '==', wishlistId);
		const imagesFilter = where('images', '!=', []);
		const q = query(c, imagesFilter, wishlistFilter);

		const querySnapshot = await getDocs(q);
		const wishItem = querySnapshot.docs[0]?.data() as WishItemFields | undefined;

		return wishItem?.images[0] ?? '';
	}
}

export default FirebaseFirestore;
