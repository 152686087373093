import React from 'react';

const AddImage = () => (
	<svg width="36" height="33" viewBox="0 0 36 33" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M21 15.6389C21 16.7895 20.0598 17.7222 18.9 17.7222C17.7402 17.7222 16.8 16.7895 16.8 15.6389C16.8 14.4883 17.7402 13.5556 18.9 13.5556C20.0598 13.5556 21 14.4883 21 15.6389Z"
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M0 10.7778C0 9.24365 1.2536 8 2.8 8H25.2C26.7464 8 28 9.24365 28 10.7778V30.2222C28 31.7563 26.7464 33 25.2 33H2.8C1.2536 33 0 31.7563 0 30.2222V10.7778ZM25.2 10.7778H2.8V24.806L9.79249 17.869C10.4759 17.191 11.5839 17.191 12.2674 17.869L17.9596 23.5161L19.692 21.7974C20.3754 21.1194 21.4834 21.1194 22.1669 21.7974L25.2 24.8065V10.7778ZM25.2 28.6594C25.0886 28.598 24.9838 28.5204 24.8892 28.4265L20.9294 24.4982L19.197 26.2168C18.5136 26.8948 17.4056 26.8948 16.7221 26.2168L11.0299 20.5698L3.11033 28.4265C3.01586 28.5203 2.91125 28.5978 2.8 28.6591V30.2222H25.2V28.6594Z"
			fill="white"
		/>
		<line x1="31" y1="1" x2="31" y2="9" stroke="white" strokeWidth="2" strokeLinecap="round" />
		<line
			x1="1"
			y1="-1"
			x2="9"
			y2="-1"
			transform="matrix(-1 0 0 1 36 6)"
			stroke="white"
			strokeWidth="2"
			strokeLinecap="round"
		/>
	</svg>
);

export default AddImage;
