import React from 'react';
import './App.css';
import Routes from './frameworks/web/routes';
import Theme from './frameworks/web/ui/Theme';
import { BrowserRouter } from 'react-router-dom';
import AuthProvider from './frameworks/web/context/auth/AuthProvider';
import NotificationProvider from './frameworks/web/context/notification/NotificationProvider';
import Notification from './frameworks/web/context/notification/Notification';

function App() {
	return (
		<NotificationProvider>
			<AuthProvider>
				<BrowserRouter>
					<Theme>
						<Routes />
						<Notification />
					</Theme>
				</BrowserRouter>
			</AuthProvider>
		</NotificationProvider>
	);
}

export default App;
