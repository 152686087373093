import React, { type FormEvent } from 'react';
import Input from '../Components/Input';
import { Stack } from '@mui/material';
import styles from './styles.module.scss';
import Link from '../Components/Link';
import Button from '../Components/Button';
import useLoginForm from './useLoginForm';
import ErrorBox from '../Components/ErrorBox';
import PasswordInput from '../Components/PasswordInput';

const LoginForm = () => {
	const {
		username,
		setUsername,
		password,
		setPassword,
		login: handleLogIn,
		errors,
		isButtonDisabled,
		cleanCommonError,
	} = useLoginForm();

	const handleFormSubmit = async (event: FormEvent) => {
		event.preventDefault();
		await handleLogIn();
	};

	return (
		<>
			<Stack
				component="form"
				id="login-form"
				onSubmit={handleFormSubmit}
				spacing={2.5}
				className={styles.form}
			>
				<ErrorBox isOpened={!!errors.common} onClose={cleanCommonError} message={errors.common} />
				<div className={styles.inputGroup}>
					<Input
						value={username}
						onChange={setUsername}
						label={'Email or username'}
						placeholder={'Enter your email or username'}
						error={!!errors.username}
						helperText={errors.username}
						classes={{
							wrapper: styles.input,
						}}
					/>
					<PasswordInput
						value={password}
						onChange={setPassword}
						label={'Password'}
						placeholder={'Enter password'}
						error={!!errors.password}
						helperText={errors.password}
						classes={{
							wrapper: styles.input,
						}}
					/>
				</div>
			</Stack>
			<div className={styles.links_wrapper}>
				<Link to="/signup">Sign Up</Link>
				<Link to="/password-recovery">Forgot Password?</Link>
			</div>
			<Stack spacing={2.5} className={styles.buttonGroup}>
				<Button type="submit" form="login-form" className={styles.login_button} disabled={isButtonDisabled}>
					Log in
				</Button>
				{/* <Typography variant={'body1'} sx={{ width: '100%', textAlign: 'center' }}>or</Typography>
				<ContinueWithGoogle onClick={() => {
				}}/>
				<ContinueWithApple onClick={() => {
				}}/> */}
			</Stack>
		</>
	);
};

export default LoginForm;
