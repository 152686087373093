import { type BookingData, type BookingId } from './definitions';
import { type UserId } from '../User/definitions';
import { type WishItemId } from '../WishItem/types';

export default class Booking {
	public quantity: number;

	private readonly _id: BookingId;
	private readonly _userId: UserId;
	private readonly _email: string;
	private readonly _wishItemId: WishItemId;

	constructor(bookingData: BookingData) {
		this._id = bookingData.id;
		this._userId = bookingData.userId;
		this._email = bookingData.email;
		this._wishItemId = bookingData.wishItemId;

		this.quantity = bookingData.quantity;
	}

	public get id(): BookingId {
		return this._id;
	}

	public get userId(): UserId {
		return this._userId;
	}

	public get email(): string {
		return this._email;
	}

	public get wishItemId(): WishItemId {
		return this._wishItemId;
	}
}
