import React, { useEffect } from 'react';
import BigLogo from '../../ui/Icons/BigLogo';
import styles from './style.module.scss';
import Container from '../../ui/Components/Container';
import Button from '../../ui/Components/Button';
import { Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useUser from '../../hooks/useUser';

const WelcomePage = () => {
	const [user] = useUser();
	const navigate = useNavigate();

	useEffect(() => {
		if (user) {
			navigate('/home', { replace: true });
		}
	}, [user]);

	const goToLogin = () => {
		navigate('/login');
	};

	const goToSignUp = () => {
		navigate('/signup');
	};

	return (
		<Container fullHeight className={styles.container}>
			<div className={styles.logo_wrapper}>
				<BigLogo />
			</div>
			<div>
				<Typography variant="h1" color="primary" className={styles.title}>
					Hello
				</Typography>
				<Typography variant="body1" className={styles.subtitle}>
					It’s time to make your wish
				</Typography>
			</div>
			<Stack className={styles.buttonGroup} spacing={2}>
				<Button onClick={goToLogin}>Log In</Button>
				<Button onClick={goToSignUp} variant={'outlined'}>
					Sign up
				</Button>
			</Stack>
		</Container>
	);
};

export default WelcomePage;
