import React, { useEffect } from 'react';
import { type WishListId } from '../../../../../domain/entities/WishList/types';
import useWishList from '../../../hooks/useWishList';
import Tag from '../../Components/Tag';
import { Link } from 'react-router-dom';

interface WishListTagProps {
	wishListId: WishListId;
}

const WishListTag: React.FC<WishListTagProps> = ({ wishListId }) => {
	const { wishlist, fetchWishList } = useWishList(wishListId);

	useEffect(() => {
		if (!wishlist) fetchWishList();
	}, []);

	if (!wishlist) return null;

	return (
		<Link to={`/wishlist/${wishListId}`} style={{ textDecoration: 'none' }}>
			<Tag label={wishlist.name} />
		</Link>
	);
};

export default WishListTag;
