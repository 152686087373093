import React, { useState } from 'react';
import Input from '../Components/Input';
import styles from './styles.module.scss';
import { CircularProgress, Stack } from '@mui/material';
import Button from '../Components/Button';
import useAuth from '../../context/auth/useAuth';
import useNotification from '../../context/notification/useNotification';
import { emailValidation } from '../../../../utils/validation';

const ForgotPasswordForm = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [email, setEmail] = useState('');
	const [error, setError] = useState('');
	const { sendPasswordResetEmail } = useAuth();
	const { sendNotification } = useNotification();

	const sendEmail = async () => {
		const validationResult = emailValidation(email, true);
		if (validationResult) {
			setError(validationResult);
			return;
		}

		setIsLoading(true);
		try {
			await sendPasswordResetEmail(email);
			sendNotification('We sent you an email to create a new password');
		} finally {
			setIsLoading(false);
		}
	};

	const handleSetEmail = (value: string) => {
		setError('');
		setEmail(value);
	};

	return (
		<div className={styles.wrapper}>
			<Stack spacing={2.5} sx={{ width: '100%' }}>
				<Input
					label={'E-mail'}
					value={email}
					onChange={handleSetEmail}
					disabled={isLoading}
					required
					placeholder={'Enter your email'}
					error={!!error}
					helperText={error}
				/>
			</Stack>
			<Button classes={{ wrapper: styles.sendButton }} onClick={sendEmail} disabled={isLoading}>
				{isLoading ? <CircularProgress color="secondary" size={25} sx={{ margin: '0 auto' }} /> : 'Send'}
			</Button>
		</div>
	);
};

export default ForgotPasswordForm;
