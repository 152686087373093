import React from 'react';
import Modal from '../Components/Modal';
import PopUpCard from '../Components/PopUpCard';
import Button from '../Components/Button';
import { IconButton, Typography } from '@mui/material';
import styles from './styles.module.scss';
import { CloseIcon } from '../Icons';
import useBreakpoints from '../../hooks/useBreakpoints';

interface ConfirmModalProps {
	isOpened: boolean;
	text?: string;
	title?: string;
	onConfirm?: () => void;
	confirmText?: string;
	onCancel?: () => void;
	cancelText?: string;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
	isOpened = false,
	onConfirm,
	confirmText,
	cancelText,
	onCancel,
	title,
	text,
}) => {
	const { isDesktop } = useBreakpoints();
	return (
		<Modal isOpened={isOpened} onClickOutside={onCancel}>
			<PopUpCard>
				<div className={styles.wrapper}>
					{isDesktop && (
						<IconButton classes={{ root: styles.closeButton }} onClick={onCancel}>
							<CloseIcon color="primary" />
						</IconButton>
					)}
					{!!title && <Typography variant="h3">{title}</Typography>}
					{!!text && (
						<Typography variant="body2" className={styles.text}>
							{text}
						</Typography>
					)}
					<div className={styles.buttonGroup}>
						<Button classes={{ wrapper: styles.button }} variant="outlined" onClick={onConfirm}>
							{confirmText ?? 'Confirm'}
						</Button>
						<Button classes={{ wrapper: styles.button }} onClick={onCancel}>
							{cancelText ?? 'Cancel'}
						</Button>
					</div>
				</div>
			</PopUpCard>
		</Modal>
	);
};

export default ConfirmModal;
