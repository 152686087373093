import React from 'react';
import Container from '../../ui/Components/Container';
import { Typography } from '@mui/material';
import Header from '../../ui/Header';
import styles from './styles.module.scss';
import CreateWishlistForm from '../../ui/CreateWishlistForm';
import DescriptionCard from '../../ui/DescriptionCard';
import BackLink from '../../ui/Components/BackLink';

const CreateWishListPage = () => (
	<Container className={styles.container} fullHeight>
		<Header />
		<BackLink label="Home" to="/" />
		<div className={styles.content}>
			<Typography color="primary" variant={'h2'}>
				Create new wishlist
			</Typography>
			<DescriptionCard className={styles.description}>
				Write the name of your new wishlist and add some description
			</DescriptionCard>
			<CreateWishlistForm />
		</div>
	</Container>
);

export default CreateWishListPage;
