import React from 'react';
import { HomeIcon } from '../Icons';
import { ProfileIcon } from '../Icons';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';
import { type IconColor } from '../Icons/definitions';
import colors from '../Theme/colors.module.scss';
import { Typography } from '@mui/material';
import useBreakpoints from '../../hooks/useBreakpoints';

interface HeaderProps {
	iconColor?: IconColor;
}

const Header: React.FC<HeaderProps> = ({ iconColor = 'primary' }) => {
	const { isDesktop } = useBreakpoints();

	return (
		<div className={styles.wrapper}>
			<Link to={'/'} className={styles.icon}>
				{isDesktop ? (
					<Typography
						component="span"
						variant="h2"
						sx={{
							fontSize: '24px',
							lineHeight: '127%',
							letterSpacing: '-1.2px',
							color: colors.primary,
							fontWeight: 700,
						}}
					>
						Wishly
					</Typography>
				) : (
					<HomeIcon color={iconColor} />
				)}
			</Link>
			<Link to={'/profile'} className={styles.icon}>
				<ProfileIcon color={iconColor} />
			</Link>
		</div>
	);
};

export default Header;
