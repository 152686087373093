import React, { useEffect } from 'react';
import Container from '../../ui/Components/Container';
import Header from '../../ui/Header';
import Button from '../../ui/Components/Button';
import styles from './styles.module.scss';
import WishListCard from '../../ui/WishListCard';
import CreateModal from '../../ui/CreateModal';
import useModal from '../../hooks/useModal';
import useWishLists from '../../hooks/useWishLists';
import useUser from '../../hooks/useUser';
import useAuth from '../../context/auth/useAuth';
import { useNavigate } from 'react-router-dom';
import useAnalytics from '../../hooks/useAnalytics';
import { sortByCreatedAt } from '../../../../utils/sort';

const HomePage = () => {
	const { isOpened: isCreateModalOpened, setIsOpened: setIsCreateModalOpened } = useModal();
	const { wishlists, fetchWishLists, getWishListsTotalCount } = useWishLists();
	const [user] = useUser();
	const auth = useAuth();
	const navigate = useNavigate();
	const analytics = useAnalytics();

	useEffect(() => {
		if (auth.continueUrl) {
			navigate(auth.continueUrl, { replace: true });
			auth.continueUrl = null;
			return;
		}

		const updateWishLists = async () => {
			const totalCount = await getWishListsTotalCount();
			if (wishlists.length < totalCount) {
				await fetchWishLists();
			}
		};

		updateWishLists();
		analytics.sendPageViewEvent('home_page');

		return () => {
			setIsCreateModalOpened(false);
		};
	}, []);

	const ownWishLists = sortByCreatedAt(
		wishlists.filter((wishlist) => wishlist.userId === user?.id),
		'desc',
	);

	return (
		<Container sx={{ paddingBottom: '120px' }} fullHeight>
			<Header />
			<div className={styles.list}>
				{ownWishLists.map((wishlist) => (
					<WishListCard
						key={wishlist.id}
						id={wishlist.id}
						title={wishlist.name}
						description={wishlist.description}
					/>
				))}
			</div>
			<Button
				classes={{
					wrapper: styles.button_create,
				}}
				onClick={() => {
					setIsCreateModalOpened(true);
				}}
			>
				Create
			</Button>
			<CreateModal isOpened={isCreateModalOpened} onClickOutside={() => setIsCreateModalOpened(false)} />
		</Container>
	);
};

export default HomePage;
