import { useCallback, useState } from 'react';
import useAuth from '../../context/auth/useAuth';
import {
	confirmPasswordValidation,
	emailValidation,
	passwordValidation,
	requiredValidation,
} from '../../../../utils/validation';
import WishListsRepository from '../../../../infrastructure/repositories/WishLists';
import UserRepository from '../../../../infrastructure/repositories/User';
import useUser from '../../hooks/useUser';
import { useNavigate, useParams } from 'react-router-dom';
import AuthError from '../../../../domain/entities/Auth/AuthError';

const USERNAME_EXISTS_ERROR = 'This username already exists';

interface SignUpFormControls {
	username: string;
	setUsername: (username: string) => void;
	email: string;
	setEmail: (email: string) => void;
	password: string;
	setPassword: (password: string) => void;
	confirmedPassword: string;
	setConfirmedPassword: (confirmedPassword: string) => void;
	signup: () => Promise<void>;
	isSignUpDisabled: boolean;
	isLoading: boolean;
	errors: {
		username: string;
		email: string;
		password: string;
		confirmedPassword: string;
	};
}

export const useSignUpForm = (): SignUpFormControls => {
	const [isLoading, setIsLoading] = useState(false);
	const [username, setUsername] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmedPassword, setConfirmedPassword] = useState('');
	const [errors, setErrors] = useState({
		username: '',
		email: '',
		password: '',
		confirmedPassword: '',
	});

	const { signup } = useAuth();
	const [, setUser] = useUser();
	const { continueUrl } = useParams();
	const navigate = useNavigate();

	const validateUsername = useCallback((value: string) => {
		const validationResult = requiredValidation(value);
		setErrors((prev) => ({
			...prev,
			username: validationResult,
		}));
	}, []);

	const validateEmail = useCallback((value: string) => {
		const validationResult = emailValidation(value, true);
		setErrors((prev) => ({
			...prev,
			email: validationResult,
		}));
	}, []);

	const validatePassword = useCallback((value: string) => {
		const validationResult = passwordValidation(value, true);
		setErrors((prev) => ({
			...prev,
			password: validationResult,
		}));
	}, []);

	const validateConfirmedPassword = useCallback(
		(value: string) => {
			const validationResult = confirmPasswordValidation(value, password, true);
			setErrors((prev) => ({
				...prev,
				confirmedPassword: validationResult,
			}));
		},
		[password],
	);

	const handleSetUsername = useCallback((value: string) => {
		validateUsername(value);
		setUsername(value);
	}, []);

	const handleSetEmail = useCallback((value: string) => {
		validateEmail(value);
		setEmail(value);
	}, []);

	const handleSetPassword = useCallback((value: string) => {
		validatePassword(value);
		setPassword(value);
	}, []);

	const handleSetConfirmedPassword = useCallback(
		(value: string) => {
			validateConfirmedPassword(value);
			setConfirmedPassword(value);
		},
		[password],
	);

	const handleSignUp = useCallback(async () => {
		setIsLoading(true);

		const isUsernameExists = await UserRepository.checkIsUsernameTaken(username);
		if (isUsernameExists) {
			setErrors((prev) => ({
				...prev,
				username: USERNAME_EXISTS_ERROR,
			}));
			setIsLoading(false);
			return;
		}

		try {
			await signup(email, password, username);
			const user = await UserRepository.createUser(email, username);
			setUser(user);
			await WishListsRepository.createWishList('Main WishList', 'It is your main wishlist', true);
			navigate(continueUrl ?? '/');
		} catch (error) {
			const isAuthError = error instanceof AuthError;
			if (isAuthError) {
				setErrors((prev) => ({
					...prev,
					[(error as AuthError).key]: (error as AuthError).message,
				}));
			}

			setIsLoading(false);
		}
	}, [username, email, password, confirmedPassword]);

	const isButtonDisabled = () => {
		const isThereError = !!Object.values(errors).find((value) => value.length);
		const notFilled = username === '' || email === '' || password === '' || confirmedPassword === '';
		return isThereError || notFilled;
	};

	return {
		isLoading,
		username,
		setUsername: handleSetUsername,
		email,
		setEmail: handleSetEmail,
		password,
		setPassword: handleSetPassword,
		confirmedPassword,
		setConfirmedPassword: handleSetConfirmedPassword,
		signup: handleSignUp,
		isSignUpDisabled: isButtonDisabled(),
		errors,
	};
};
