import { type BookingData, type BookingId } from '../../../domain/entities/Booking/definitions';
import { useEffect, useState } from 'react';
import BookingRepository from '../../../infrastructure/repositories/Booking';

const EMPTY_BOOKING = {
	wishItemId: '',
	email: '',
	userId: '',
	quantity: 0,
};

const useBooking = (bookingId: BookingId): BookingData => {
	const [bookingInfo, setBookingInfo] = useState<Omit<BookingData, 'id'>>(EMPTY_BOOKING);

	useEffect(() => {
		const getBooking = async () => {
			const booking = await BookingRepository.getBookingById(bookingId);
			if (!booking) return;

			setBookingInfo({
				wishItemId: booking.wishItemId,
				email: booking.email,
				userId: booking.userId,
				quantity: booking.quantity,
			});
		};

		getBooking();
	}, []);

	return {
		id: bookingId,
		...bookingInfo,
	};
};

export default useBooking;
