import { useContext } from 'react';
import NotificationContext, { type NotificationControls } from './NotificationContext';

const useNotification = (): NotificationControls => {
	const context = useContext(NotificationContext);

	if (!context) {
		throw new Error('useNotifications must be used within an NotificationsProvider');
	}

	return context;
};

export default useNotification;
