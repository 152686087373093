import React, { useEffect } from 'react';
import Header from '../../ui/Header';
import Container from '../../ui/Components/Container';
import { Typography } from '@mui/material';
import CreateWishItemForm from '../../ui/CreateWishItemForm';
import { useSearchParams } from 'react-router-dom';
import styles from './styles.module.scss';
import BackLink from '../../ui/Components/BackLink';
import useAnalytics from '../../hooks/useAnalytics';

const CreateWishItemPage = () => {
	const [searchParams] = useSearchParams();
	const preselectedWishList = searchParams.get('wishlist');
	const analytics = useAnalytics();

	useEffect(() => {
		analytics.sendPageViewEvent('create_wishitem_page');
	}, []);

	return (
		<Container className={styles.container} fullHeight>
			<Header />
			<BackLink label="Home" to="/" />
			<div className={styles.content}>
				<Typography variant={'h2'}>Add new wish</Typography>
				<CreateWishItemForm preselectedWishlist={preselectedWishList ?? ''} />
			</div>
		</Container>
	);
};

export default CreateWishItemPage;
