import React, { useEffect } from 'react';
import Modal from '../../Components/Modal';
import PopUpCard from '../../Components/PopUpCard';
import Button from '../../Components/Button';
import { Typography } from '@mui/material';
import NumericInput from '../../Components/NumericInput';
import BookModalVisitor from '../../BookModalVisitor';
import useBookingProcess from '../../../hooks/useBookingProcess';
import { type WishItemId } from '../../../../../domain/entities/WishItem/types';
import { type WishListId } from '../../../../../domain/entities/WishList/types';
import styles from './styles.module.scss';

interface BookModalProps {
	isOpened: boolean;
	wishItemId: WishItemId;
	onBook: () => void;
	wishListId: WishListId;
	onClickOutside?: () => void;
}

const BookModal: React.FC<BookModalProps> = ({
	isOpened = false,
	wishItemId,
	onBook,
	onClickOutside,
	wishListId,
}) => {
	const {
		errors,
		email,
		setEmail,
		wishItem,
		user,
		quantityToBook,
		setQuantityToBook,
		bookItem,
		modal: { isOpened: isBookModalOpened, setIsOpened: setIsBookModalOpened },
	} = useBookingProcess(wishItemId);

	const availableQuantity = wishItem.quantity - wishItem.bookedQuantity;
	const bookedByEmail = email ? !!wishItem.getBookingByEmail(email) : false;
	const bookedByCurrentUser = user?.id ? !!wishItem.getBookingByUserId(user.id) : false;
	const booked = bookedByEmail || bookedByCurrentUser;

	if (availableQuantity <= 0 && !booked) return null;

	useEffect(() => {
		if (!user?.id && availableQuantity === 1) {
			setIsBookModalOpened(true);
		}
	}, [availableQuantity]);

	const renderInput = () => {
		if (wishItem.isBooked || availableQuantity === 1) return null;
		return (
			<>
				<Typography variant={'h3'}>Choose the amount of wish</Typography>
				<div className={styles.amountInputWrapper}>
					<NumericInput min={1} max={availableQuantity} value={quantityToBook} onChange={setQuantityToBook} />
					<Button onClick={handleBookItem} disabled={wishItem.isBooked}>
						Book item
					</Button>
				</div>
			</>
		);
	};

	const handleBookItem = async () => {
		if (user?.id) {
			await bookItem(onBook);
			return;
		}

		setIsBookModalOpened(true);
	};

	const handleVisitorModalOutsideClick = () => {
		setIsBookModalOpened(false);
		if (onClickOutside) onClickOutside();
	};

	return (
		<>
			<Modal isOpened={isOpened} onClickOutside={onClickOutside}>
				<PopUpCard>{renderInput()}</PopUpCard>
			</Modal>
			{!user && (
				<BookModalVisitor
					onOutsideClick={handleVisitorModalOutsideClick}
					isOpened={isBookModalOpened}
					continueUrl={`/wishlist/${wishListId}`}
					onBook={async () => bookItem(onBook)}
					email={email}
					setEmail={setEmail}
					error={errors.email}
				/>
			)}
		</>
	);
};

export default BookModal;
