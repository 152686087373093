import React from 'react';
import { colors, type IconColor } from './definitions';

interface CheckMarkIconProps {
	color?: IconColor;
}

const CheckMarkIcon: React.FC<CheckMarkIconProps> = ({ color = 'primary' }) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M21.5456 5.11107C22.1314 5.69686 22.1314 6.6466 21.5456 7.23239L10.3026 18.4754C9.6778 19.1002 8.66474 19.1002 8.0399 18.4754L2.45376 12.8892C1.86797 12.3035 1.86797 11.3537 2.45376 10.7679C3.03954 10.1821 3.98929 10.1821 4.57508 10.7679L9.17127 15.3641L19.4243 5.11107C20.0101 4.52528 20.9599 4.52528 21.5456 5.11107Z"
			fill={colors[color]}
		/>
	</svg>
);

export default CheckMarkIcon;
