import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { type SxProps, type Theme } from '@mui/material';
import styles from './styles.module.scss';

interface ContainerProps {
	sx?: SxProps<Theme>;
	fullHeight?: boolean;
	className?: string;
}

const Container: React.FC<ContainerProps> = ({ children, sx, fullHeight, className }) => {
	const [screenHeight, setScreenHeight] = useState(window.innerHeight);

	useEffect(() => {
		const handleResize = () => setScreenHeight(window.innerHeight);
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<Box
			className={[styles.container, className].join(' ')}
			sx={{
				...(fullHeight && { minHeight: `${screenHeight}px` }),
				...(sx && sx),
			}}
		>
			{children}
		</Box>
	);
};

export default Container;
