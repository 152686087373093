import React, { useEffect } from 'react';
import Container from '../../ui/Components/Container';
import { Navigate, useParams } from 'react-router-dom';
import WishListView from '../../ui/WishListView';
import styles from './styles.module.scss';
import TextLogo from '../../ui/Icons/TextLogo';
import useAnalytics from '../../hooks/useAnalytics';

const WishListPageVisitor = () => {
	const { wishlistId } = useParams();
	const analytics = useAnalytics();

	useEffect(() => {
		analytics.sendPageViewEvent('visitor_wishlist_page');
	}, []);

	if (!wishlistId) {
		return <Navigate to={'/'} replace />;
	}

	return (
		<Container fullHeight className={styles.container}>
			<div className={styles.header}>
				<div className={styles.logo}>
					<TextLogo />
				</div>
			</div>
			<WishListView wishlistId={wishlistId} />
		</Container>
	);
};

export default WishListPageVisitor;
