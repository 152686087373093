import React from 'react';
import { colors, type IconColor } from './definitions';

interface MinusProps {
	color?: IconColor;
}

const Minus: React.FC<MinusProps> = ({ color = 'primary' }) => (
	<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<line x1="21.5" y1="12" x2="3.5" y2="12" stroke={colors[color]} strokeWidth="2" strokeLinecap="round" />
	</svg>
);

export default Minus;
