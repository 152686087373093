import { type WishListId } from '../../../domain/entities/WishList/types';
import { type WishItem } from '../../../domain/entities/WishItem/WishItem';
import { type WishItemId } from '../../../domain/entities/WishItem/types';
import { observe, wishItemsStore } from '../../../services/mobx/wishitems';
import { autorun, type Lambda, makeAutoObservable, runInAction } from 'mobx';
import { type WishItemFields } from '../../../domain/entities/WishItem/interfaces/WishItemFields';
import { mapFieldsToObject } from '../../helpers/wishitem';

class WishItemsStore {
	public subscribeOnWishItemsChange(callback: (wishItems: WishItem[]) => void): Lambda {
		return autorun(() => {
			callback(wishItemsStore.wishItems);
		});
	}

	public subscribeOnWishItemChangeById(
		wishItemId: WishItemId,
		callback: (wishItem: WishItem) => void,
	): Lambda | null {
		const wishitem = wishItemsStore.wishItems.find((wishItem) => wishItem.id === wishItemId);
		if (!wishitem) {
			return null;
		}

		return observe(wishitem, (updated) => {
			callback(updated.object);
		});
	}

	public getWishItems(): WishItem[] {
		return wishItemsStore.wishItems;
	}

	public getWishItemsByWishlistId(wishListId: WishListId): WishItem[] {
		return wishItemsStore.wishItems.filter((wishItem) => wishItem.wishlist === wishListId);
	}

	public addWishItems(wishItems: WishItem[]): void {
		runInAction(() => {
			wishItemsStore.wishItems = wishItemsStore.wishItems.concat(
				...wishItems.map((item) => makeAutoObservable(item)),
			);
		});
	}

	public getWishItem(wishItemId: WishItemId): WishItem | undefined {
		return wishItemsStore.wishItems.find((wishItem) => wishItem.id === wishItemId);
	}

	public deleteWishItem(wishItemId: WishItemId): void {
		runInAction(() => {
			wishItemsStore.wishItems = wishItemsStore.wishItems.filter((wishItem) => wishItem.id !== wishItemId);
		});
	}

	public setWishItems(wishItems: WishItem[]): void {
		runInAction(() => {
			wishItemsStore.wishItems = wishItems.map((item) => makeAutoObservable(item));
		});
	}

	public updateWishItem(wishItemId: WishItemId, updatedFields: Partial<WishItemFields>): void {
		const wishItem = this.getWishItem(wishItemId);
		if (!wishItem) return;
		runInAction(() => mapFieldsToObject(wishItem, updatedFields));
	}

	public setWishItemLoading(wishItemId: WishItemId, promise: Promise<WishItem | null>): void {
		if (wishItemsStore.loading.has(wishItemId)) return;
		runInAction(() => wishItemsStore.loading.set(wishItemId, promise));
	}

	public setWishItemLoaded(wishItemId: WishItemId): void {
		runInAction(() => wishItemsStore.loading.delete(wishItemId));
	}

	public getWishItemLoadingPromise(wishItemId: WishItemId): Promise<WishItem | null> | null {
		return wishItemsStore.loading.get(wishItemId) ?? null;
	}
}

export default new WishItemsStore();
