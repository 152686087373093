import { type WishList } from '../../../domain/entities/WishList/WishList';
import { useEffect, useState } from 'react';
import WishListsStore from '../../../infrastructure/store/wishlists';
import WishListsRepository from '../../../infrastructure/repositories/WishLists';
import UserRepository from '../../../infrastructure/repositories/User';

interface WishListsControls {
	wishlists: WishList[];
	addWishList(name: string, description: string): void;
	fetchWishLists(): Promise<void>;
	getWishListsTotalCount(): Promise<number>;
}

const useWishLists = (): WishListsControls => {
	const [wishlists, updateWishLists] = useState<WishList[]>(() => {
		const userId = UserRepository.getCurrentUser()?.id;
		return userId ? WishListsRepository.getWishListsByUserId(userId) : [];
	});

	const fetchWishLists = async () =>
		WishListsRepository.fetchWishLists().catch((e) => {
			console.log('error -->', e);
		});

	const getWishListsTotalCount = async () => WishListsRepository.getWishListsTotalCount();

	useEffect(() => {
		const disposer = WishListsStore.subscribeOnWishListsChange((wishlists) => {
			updateWishLists(wishlists);
		});

		return () => {
			disposer();
		};
	}, []);

	return {
		wishlists,
		addWishList: WishListsRepository.createWishList,
		fetchWishLists,
		getWishListsTotalCount,
	};
};

export default useWishLists;
