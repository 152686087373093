import { observe, wishlistsStore } from '../../../services/mobx/wishlists';
import { type WishList } from '../../../domain/entities/WishList/WishList';
import { type WishListId } from '../../../domain/entities/WishList/types';
import { type Lambda, observable, runInAction } from 'mobx';

class WishListsStore {
	public subscribeOnWishListsChange(callback: (wishlists: WishList[]) => void): Lambda {
		return observe(wishlistsStore, (change) => {
			callback(change.object[change.name]);
		});
	}

	public getWishLists(): WishList[] {
		return wishlistsStore.wishlists;
	}

	public setWishLists(wishlists: WishList[]): void {
		runInAction(() => {
			wishlistsStore.wishlists = wishlists.map((wishlist) => observable(wishlist));
		});
	}

	public getWishListById(wishlistId: WishListId): WishList | null {
		return wishlistsStore.wishlists.find((wishlist) => wishlist.id === wishlistId) ?? null;
	}

	public addWishList(wishlist: WishList): void {
		runInAction(() => {
			wishlistsStore.wishlists = wishlistsStore.wishlists.concat(observable(wishlist));
		});
	}

	public deleteWishList(wishlist: WishList): void {
		runInAction(() => {
			wishlistsStore.wishlists = wishlistsStore.wishlists.filter((wl) => wl.id !== wishlist.id);
		});
	}

	public deleteWishListById(wishlistId: WishListId): void {
		const wishlist = this.getWishListById(wishlistId);
		if (!wishlist) {
			return;
		}

		this.deleteWishList(wishlist);
	}

	public setWishListDescription(wishlistId: WishListId, description: string): void {
		const wishlist = this.getWishListById(wishlistId);
		if (!wishlist) {
			return;
		}

		runInAction(() => {
			wishlist.description = description;
		});
	}

	public setWishListName(wishlistId: WishListId, name: string): void {
		const wishlist = this.getWishListById(wishlistId);
		if (!wishlist) {
			return;
		}

		runInAction(() => {
			wishlist.name = name;
		});
	}

	public setWishlistLoading(wishListId: WishListId, promise: Promise<WishList | null>): void {
		if (wishlistsStore.loading.has(wishListId)) return;
		runInAction(() => wishlistsStore.loading.set(wishListId, promise));
	}

	public setWishlistLoaded(wishListId: WishListId): void {
		runInAction(() => wishlistsStore.loading.delete(wishListId));
	}

	public getWishListLoadingPromise(wishListId: WishListId): Promise<WishList | null> | null {
		return wishlistsStore.loading.get(wishListId) ?? null;
	}
}

export default new WishListsStore();
