import React from 'react';
import {
	FormHelperText,
	Input,
	MenuItem,
	Select as MuiSelect,
	type SelectChangeEvent,
	Typography,
} from '@mui/material';
import Label from '../Label';
import styles from './styles.module.scss';
import ChevronDown from '../../Icons/ChevronDown';
import colors from '../../Theme/colors.module.scss';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			border: `${colors.primaryLight} 2px solid`,
			boxShadow: 'none',
			borderRadius: '20px',
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
			marginTop: '8px',
		},
	},
};

type Option = {
	id: string;
	value: string;
};

interface SelectProps {
	name?: string;
	options: Option[];
	selected: string;
	onSelect?: (val: string) => void;
	label?: string;
	required?: boolean;
	error?: boolean;
	helperText?: string;
	disabled?: boolean;
	classes?: {
		wrapper?: string;
	};
}

const Select: React.FC<SelectProps> = ({
	options,
	selected,
	onSelect,
	label,
	name,
	required,
	error,
	helperText,
	disabled,
	classes,
}) => {
	const handleChange = (event: SelectChangeEvent) => {
		if (!onSelect) {
			return;
		}

		const {
			target: { value },
		} = event;
		onSelect(value);
	};

	return (
		<div className={[styles.wrapper, classes?.wrapper].join(' ')}>
			{label && (
				<Label htmlFor={name} className={styles.label}>
					{required ? `${label}*` : label}
				</Label>
			)}
			<MuiSelect
				classes={{
					select: styles.root,
				}}
				name={name}
				value={selected}
				onChange={handleChange}
				IconComponent={ChevronDown}
				displayEmpty={true}
				disabled={disabled}
				error={error}
				input={<Input classes={{ root: [styles.select, ...(error ? [styles.error] : [])].join(' ') }} />}
				MenuProps={MenuProps}
				renderValue={(value) => {
					const item = options.find((option) => option.id === value);
					const className = item ? '' : styles.placeholderText;
					return (
						<Typography variant="body1" className={className}>
							{item?.value ?? 'Choose from the suggested'}
						</Typography>
					);
				}}
			>
				<MenuItem key="placeholder" value="" className={styles.placeholderOption}>
					Choose from the suggested
				</MenuItem>
				{options.map((option, index) => (
					<MenuItem key={option.id ?? index} value={option.id} disabled={option.id === ''}>
						{option.value}
					</MenuItem>
				))}
			</MuiSelect>
			{helperText && (
				<FormHelperText disabled={disabled} error={error}>
					{helperText}
				</FormHelperText>
			)}
		</div>
	);
};

export default Select;
