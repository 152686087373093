import React, { useState } from 'react';
import Input from '../Components/Input';
import styles from './styles.module.scss';
import { CircularProgress, Stack, Typography } from '@mui/material';
import Button from '../Components/Button';
import useEditProfileForm from './useEditProfileForm';
import type User from '../../../../domain/entities/User';
import { useNavigate } from 'react-router-dom';
import DeleteAccountModal from '../DeleteAccountModal';
import useModal from '../../hooks/useModal';
import FeedbackModal from '../FeedbackModal';

interface EditProfileFormProps {
	user: User;
}

const EditProfileForm: React.FC<EditProfileFormProps> = ({ user }) => {
	const { username, setUsername, email, setEmail, errors, isSavingDisabled, saveChanges, deleteAccount } =
		useEditProfileForm(user);
	const [isLoading] = useState(false);
	const navigate = useNavigate();
	const [isModalOpened, setModalOpened] = useState(false);
	const { isOpened: isFeedbackModalOpened, setIsOpened: setFeedbackModalOpened } = useModal(false); // Feedback modal

	const handlePasswordChangeClick = () => {
		navigate('/password-change');
	};

	return (
		<div className={styles.wrapper}>
			<Stack spacing={2.5} sx={{ width: '100%' }}>
				<div className={styles.inputGroup}>
					<Input
						label={'Username'}
						value={username}
						onChange={setUsername}
						disabled={false}
						placeholder={'Enter username'}
						autoComplete={'username-to-edit'}
						error={!!errors.username}
						helperText={errors.username}
						classes={{
							wrapper: styles.input,
						}}
					/>
					<Input
						label={'E-mail'}
						value={email}
						onChange={setEmail}
						disabled={false}
						placeholder={'Enter e-mail'}
						autoComplete={'email-to-edit'}
						error={!!errors.email}
						helperText={errors.email}
						classes={{
							wrapper: styles.input,
						}}
					/>
				</div>
				<button className={styles.changePasswordButton} onClick={handlePasswordChangeClick}>
					<Typography variant="body1">Change password</Typography>
				</button>
			</Stack>
			<div className={styles.buttonWrapper}>
				{isLoading ? (
					<CircularProgress sx={{ margin: '0 auto' }} />
				) : (
					<Button classes={{ wrapper: styles.saveButton }} disabled={isSavingDisabled} onClick={saveChanges}>
						Save changes
					</Button>
				)}
				<button className={styles.buttonDeleteAccount} onClick={() => setModalOpened(true)}>
					<Typography variant="body1">Delete account</Typography>
				</button>
			</div>
			<DeleteAccountModal
				onCancel={() => setModalOpened(false)}
				isOpened={isModalOpened}
				deleteAccount={deleteAccount}
			/>
			<FeedbackModal isOpened={isFeedbackModalOpened} onClickOutside={() => setFeedbackModalOpened(false)} />
			<button className={styles.buttonFeedback} onClick={() => setFeedbackModalOpened(true)}>
				<Typography variant="body1">We would be happy if you could provide us with your feedback.</Typography>
				<Typography variant="body1" color="primary" sx={{ textDecoration: 'underline' }}>
					You can leave it here!
				</Typography>
			</button>
		</div>
	);
};

export default EditProfileForm;
