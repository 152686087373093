import React, { useState } from 'react';
import Modal from '../Components/Modal';
import PopUpCard from '../Components/PopUpCard';
import Button from '../Components/Button';
import { IconButton, Typography } from '@mui/material';
import styles from './styles.module.scss';
import { CloseIcon } from '../Icons';
import useBreakpoints from '../../hooks/useBreakpoints';
import PasswordInput from '../Components/PasswordInput';
import UserRepository from '../../../../infrastructure/repositories/User';
import { PASSWORD_IS_INCORRECT } from '../../../../utils/validation';
import Loader from '../Components/Loader';

interface DeleteAccountModalProps {
	isOpened: boolean;
	onCancel?: () => void;
	deleteAccount: () => Promise<void>;
}

const DeleteAccountModal: React.FC<DeleteAccountModalProps> = ({
	isOpened = false,
	onCancel,
	deleteAccount,
}) => {
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');
	const [isLoading, setLoading] = useState(false);
	const { isDesktop } = useBreakpoints();

	const handleDeleteAccount = async () => {
		setLoading(true);
		const isPasswordCorrect = await UserRepository.confirmPassword(password);

		if (isPasswordCorrect) {
			await deleteAccount();
			setLoading(false);
			return;
		}

		setLoading(false);
		setError(PASSWORD_IS_INCORRECT);
	};

	const handleSetPassword = (password: string) => {
		setError('');
		setPassword(password);
	};

	return (
		<Modal isOpened={isOpened} onClickOutside={onCancel}>
			<PopUpCard>
				<div className={styles.wrapper}>
					{isDesktop && (
						<IconButton classes={{ root: styles.closeButton }} onClick={onCancel}>
							<CloseIcon color="primary" />
						</IconButton>
					)}
					<Typography variant="h3">Are you sure you want to delete account?</Typography>
					<Typography variant="body2" className={styles.text}>
						If you delete your account, all wishes and wishlists will also be deleted. Are you sure you want
						to continue?
					</Typography>
					<PasswordInput
						label="Enter your password"
						placeholder="Password"
						value={password}
						onChange={handleSetPassword}
						autoComplete="one-time-code"
						error={!!error}
						helperText={error}
						disabled={isLoading}
						classes={{
							wrapper: styles.input,
						}}
					/>
					<div className={styles.buttonGroup}>
						{isLoading ? (
							<Loader size={25} />
						) : (
							<>
								<Button
									classes={{ wrapper: styles.button }}
									variant="outlined"
									onClick={handleDeleteAccount}
									disabled={!password || !!error}
								>
									Delete
								</Button>
								<Button classes={{ wrapper: styles.button }} onClick={onCancel}>
									Cancel
								</Button>
							</>
						)}
					</div>
				</div>
			</PopUpCard>
		</Modal>
	);
};

export default DeleteAccountModal;
