import { type WishListId } from './types';
import { type WishListData } from './interfaces/WishListData';
import { type UserId } from '../User/definitions';

export class WishList {
	public name: string;
	public description: string;
	public createdAt: Date;
	private readonly _id: WishListId;
	private readonly _userId: UserId;
	private readonly _isMain: boolean;

	constructor(params: WishListData) {
		this.name = params.name;
		this.description = params.description;
		this.createdAt = params.createdAt;
		this._id = params.id;
		this._userId = params.userId;
		this._isMain = params.isMain;
	}

	public get id(): WishListId {
		return this._id;
	}

	public get userId(): UserId {
		return this._userId;
	}

	public get isMain(): boolean {
		return this._isMain;
	}
}
