import type User from '../../../domain/entities/User';
import { useState } from 'react';
import useModal, { type ModalControls } from '../hooks/useModal';
import useUser from './useUser';
import WishItemRepository from '../../../infrastructure/repositories/WishItem';
import UserRepository from '../../../infrastructure/repositories/User';
import { type WishItemId } from '../../../domain/entities/WishItem/types';
import useWishItem, { type WishItemControls } from './useWishItem';
import type Booking from '../../../domain/entities/Booking';
import { emailValidation } from '../../../utils/validation';
import useVisitorEmail from './useVisitorEmail';

interface BookingControls {
	email: string;
	setEmail: (email: string) => void;
	wishItem: WishItemControls;
	user: User | null;
	modal: ModalControls;
	quantityToBook: number;
	setQuantityToBook: (quantity: number) => void;
	bookItem: (callback?: () => void) => Promise<void>;
	removeBooking: () => Promise<void>;
	errors: {
		email: string;
	};
}

const useBookingProcess = (wishItemId: WishItemId): BookingControls => {
	// Email needed only for visitors. Maybe makes sense to create a separate module for it
	const { visitorEmail: email, setVisitorEmail: setEmail } = useVisitorEmail();

	const { isOpened, setIsOpened } = useModal();
	const [quantityToBook, setQuantityToBook] = useState(1);
	const [user] = useUser();
	const wishItem = useWishItem(wishItemId);
	const [errors, setErrors] = useState({
		email: '',
	});

	const checkUserWishThisEmail = async (): Promise<string> => {
		if (!email) return '';
		const user = await UserRepository.getUserByEmail(email);
		if (!user) return '';

		return 'User with this E-mail already exists. Try to LogIn using this email';
	};

	const bookItem = async (callback?: () => void) => {
		if (!user?.id) {
			const validationResult = emailValidation(email, true);
			const checkUserWishThisEmailResult = await checkUserWishThisEmail();
			const error = validationResult || checkUserWishThisEmailResult;

			setErrors((prev) => ({
				...prev,
				email: error,
			}));

			if (error.length) return;
		}

		if (user?.id ?? email) {
			await WishItemRepository.bookWishItem({
				id: wishItemId,
				userId: user?.id,
				email,
				quantity: quantityToBook,
			});
			setIsOpened(false);
			if (callback) callback();
			return;
		}

		setIsOpened(true);
	};

	const removeBooking = async () => {
		let existingBooking: Booking | null = null;
		if (user?.id) {
			existingBooking = wishItem.getBookingByUserId(user.id);
		}

		if (email) {
			existingBooking = wishItem.getBookingByEmail(email);
		}

		if (!existingBooking) return;

		await WishItemRepository.removeBooking(wishItemId, existingBooking.id);
	};

	return {
		modal: {
			isOpened,
			setIsOpened,
		},
		quantityToBook,
		setQuantityToBook,
		user,
		bookItem,
		removeBooking,
		wishItem,
		email,
		setEmail,
		errors,
	};
};

export default useBookingProcess;
