import FirebaseFirestore from '../../http/firebase/FirebaseFirestore';
import { type BookingId } from '../../../domain/entities/Booking/definitions';
import type Booking from '../../../domain/entities/Booking';

const Firestore = new FirebaseFirestore();

class BookingRepository {
	private readonly _firestoreService: FirebaseFirestore;

	constructor(firebaseFirestore: FirebaseFirestore) {
		this._firestoreService = firebaseFirestore;
	}

	public async getBookingById(bookingId: BookingId): Promise<Booking | null> {
		return this._firestoreService.getBookingById(bookingId);
	}
}

export default new BookingRepository(Firestore);
