import { type Analytics as GoogleAnalytics, getAnalytics, logEvent } from 'firebase/analytics';
import FirebaseApp from '../../../services/firebase/FirebaseApp';
import { type Page } from './events';

const GAInstance = getAnalytics(FirebaseApp);

class Analytics {
	private readonly _ga: GoogleAnalytics;

	constructor(ga: GoogleAnalytics) {
		this._ga = ga;
	}

	public sendPageViewEvent(page: Page): void {
		logEvent(this._ga, `page_view__${page}`);
	}

	public sendCustomEvent(eventName: string): void {
		logEvent(this._ga, eventName);
	}
}

export default new Analytics(GAInstance);
