import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import Container from '../../ui/Components/Container';
import { BackIcon, LogOutIcon } from '../../ui/Icons';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../context/auth/useAuth';
import ChangePasswordForm from '../../ui/ChangePasswordForm';
import useAnalytics from '../../hooks/useAnalytics';

const ChangePasswordPage = () => {
	const { logout } = useAuth();
	const navigate = useNavigate();
	const analytics = useAnalytics();

	useEffect(() => {
		analytics.sendPageViewEvent('change_password_page');
	}, []);

	return (
		<Container className={styles.container} fullHeight>
			<div className={styles.headerWrapper}>
				<button
					onClick={() => {
						navigate('/profile');
					}}
					className={styles.backButton}
				>
					<BackIcon color="primary" />
					<Typography variant="body1">Edit profile</Typography>
				</button>
				<button onClick={logout}>
					<LogOutIcon color="primary" />
				</button>
			</div>
			<div className={styles.content}>
				<Typography color="primary" variant={'h2'}>
					Change password
				</Typography>
				<ChangePasswordForm />
			</div>
		</Container>
	);
};

export default ChangePasswordPage;
