import { CircularProgress, Stack, Typography } from '@mui/material';
import Input from '../Components/Input';
import Button from '../Components/Button';
import styles from './styles.module.scss';
import { default as AddImageIcon } from '../Icons/AddImage';
import NumericInput from '../Components/NumericInput';
import React, { useEffect, useState } from 'react';
import Select from '../Components/Select';
import useWishLists from '../../hooks/useWishLists';
import { useNavigate } from 'react-router-dom';
import { getErrorMessage } from '../../../../utils/errors';
import FileInput from '../Components/FileInput';
import ImagePreview from '../Components/ImagePreview';
import Label from '../Components/Label';
import useEditWishItemForm from './useEditWishItemForm';
import LightBox from '../Components/LightBox';
import { WishItem } from '../../../../domain/entities/WishItem/WishItem';

interface EditWishItemFormProps {
	wishItemId: string;
}

const EditWishItemForm: React.FC<EditWishItemFormProps> = ({ wishItemId }) => {
	const {
		title,
		setTitle,
		description,
		setDescription,
		link,
		setLink,
		images,
		addImages,
		removeImage,
		quantity,
		setQuantity,
		wishlist: selectedWishList,
		setWishlist,
		saveChanges,
		getWishItem,
	} = useEditWishItemForm(wishItemId);

	const { wishlists, fetchWishLists, getWishListsTotalCount } = useWishLists();
	const [isLoading, setIsLoading] = useState(false);
	const [lightBoxImage, setLightBoxImage] = useState<number | null>(null);
	const navigate = useNavigate();

	useEffect(() => {
		getWishItem();
		const updateWishLists = async () => {
			const totalCount = await getWishListsTotalCount();
			if (wishlists.length < totalCount) {
				await fetchWishLists();
			}
		};

		updateWishLists();
	}, []);

	const handleSaveWishItem = async () => {
		setIsLoading(true);
		try {
			await saveChanges();
			navigate(`/wish/${wishItemId}`);
		} catch (error) {
			setIsLoading(false);
			console.error('WishItem editing failed: ', getErrorMessage(error));
		}
	};

	const handleRemoveImage = (index: number) => {
		removeImage(index);
		setLightBoxImage(null);
	};

	return (
		<>
			<Stack spacing={2.5} className={styles.inputGroup}>
				<Input
					value={title}
					onChange={setTitle}
					label="Name"
					placeholder="Enter name"
					classes={{
						wrapper: styles.input,
					}}
				/>
				<Select
					label={'Wishlists'}
					options={wishlists.map((wishlist) => ({
						id: wishlist.id,
						value: wishlist.name,
					}))}
					selected={selectedWishList}
					onSelect={setWishlist}
					classes={{
						wrapper: styles.input,
					}}
				/>
				<Input
					value={description}
					onChange={setDescription}
					label={'Description'}
					placeholder={'Enter description'}
					rows={3}
					classes={{
						wrapper: styles.input,
					}}
				/>
				<div className={styles.imagesWrapper}>
					<Label>Images</Label>
					<div className={styles['images-section']}>
						{images.map((image, index) => (
							<ImagePreview key={index} source={image} onClick={() => setLightBoxImage(index)} />
						))}
						<FileInput onChange={addImages} isDisabled={images.length >= 5} className={styles.addImageButton}>
							<AddImageIcon />
							<Typography
								variant={'body2'}
								color="secondary"
								className={styles.numberLabel}
							>{`${images.length} / ${WishItem.MAX_IMAGES_COUNT}`}</Typography>
						</FileInput>
					</div>
				</div>
				<Input
					value={link}
					onChange={setLink}
					label={'Link'}
					placeholder={'Enter link'}
					classes={{
						wrapper: styles.input,
					}}
				/>
				<NumericInput
					label={'Quantity'}
					value={quantity}
					onChange={setQuantity}
					classes={{ inputWrapper: styles.quantityInput, wrapper: styles.input }}
					min={1}
				/>
			</Stack>
			<div className={styles.buttonGroup}>
				{isLoading ? (
					<CircularProgress sx={{ margin: '15px auto' }} />
				) : (
					<Button
						classes={{ wrapper: styles.addButton }}
						className={styles.addButton}
						onClick={handleSaveWishItem}
					>
						Save changes
					</Button>
				)}
			</div>
			<LightBox
				setImageIndex={setLightBoxImage}
				removeImage={handleRemoveImage}
				imageIndex={lightBoxImage}
				onClose={() => setLightBoxImage(null)}
				images={images}
			/>
		</>
	);
};

export default EditWishItemForm;
