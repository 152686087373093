import { useEffect, useState } from 'react';
import UserRepository from '../../../infrastructure/repositories/User';
import UserStore from '../../../infrastructure/store/user';

interface VisitorEmailControls {
	visitorEmail: string;
	setVisitorEmail: (email: string) => void;
}

const useVisitorEmail = (): VisitorEmailControls => {
	const [visitorEmail, setVisitorEmail] = useState(UserRepository.getVisitorEmail());

	useEffect(() => {
		const disposer = UserStore.subscribeOnVisitorEmailChange(setVisitorEmail);
		return () => {
			disposer();
		};
	}, []);

	return {
		visitorEmail,
		setVisitorEmail: UserRepository.setVisitorEmail,
	};
};

export default useVisitorEmail;
