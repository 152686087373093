import React from 'react';
import 'slick-carousel/slick/slick.css';
import Slider from 'react-slick';
import styles from './styles.module.scss';
import './activeDot.scss';
import Button from '../Button';
import ChevronLeft from '../../Icons/ChevronLeft';
import ChevronRight from '../../Icons/ChevronRight';

interface CarouselProps {
	infinite?: boolean;
	slidesToShow?: number;
	slidesToScroll?: number;
	adaptiveHeight?: boolean;
	initialSlide?: number;
	afterChange?: (index: number) => void;
	currentSlide?: number;
	arrows?: boolean;
	classes?: {
		root?: string;
		dots?: string;
	};
}

const DEFAULT_PROPS: CarouselProps = {
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	adaptiveHeight: true,
	initialSlide: 0,
	arrows: false,
};

const Dots = ({ dots, customClass }: { dots: React.ReactNode; customClass?: string }) => (
	<ul className={[styles.dots, customClass].join(' ')}>{dots}</ul>
);

const PrevArrow = ({ onClick }: { onClick?: () => void }) => (
	<Button
		classes={{ wrapper: [styles.arrow, styles.arrowPrev].join(' '), button: styles.arrowButton }}
		onClick={onClick}
	>
		<ChevronLeft color="white" />
	</Button>
);
const NextArrow = ({ onClick }: { onClick?: () => void }) => (
	<Button
		classes={{ wrapper: [styles.arrow, styles.arrowNext].join(' '), button: styles.arrowButton }}
		onClick={onClick}
	>
		<ChevronRight color="white" />
	</Button>
);

const Carousel: React.FC<CarouselProps> = ({ children, currentSlide, classes, ...props }) => {
	const sliderConfig = {
		...DEFAULT_PROPS,
		...props,
	};

	return (
		<Slider
			{...sliderConfig}
			dots={true}
			className={[styles.carousel, classes?.root].join(' ')}
			customPaging={() => <button></button>}
			appendDots={(dots) => <Dots dots={dots} customClass={classes?.dots} />}
			prevArrow={<PrevArrow />}
			nextArrow={<NextArrow />}
		>
			{/* @ts-expect-error I have no idea */}
			{children}
		</Slider>
	);
};

export default Carousel;
