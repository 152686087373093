import React, { useEffect, useState } from 'react';
import Header from '../../ui/Header';
import Container from '../../ui/Components/Container';
import { Typography } from '@mui/material';
import EditWishListForm from '../../ui/EditWishListForm';
import { Navigate, useParams } from 'react-router-dom';
import styles from './styles.module.scss';
import WishListsRepository from '../../../../infrastructure/repositories/WishLists';
import BackLink from '../../ui/Components/BackLink';
import useAnalytics from '../../hooks/useAnalytics';

const EditWishListPage = () => {
	const [wishListName, setWishListName] = useState('');
	const { wishlistId } = useParams();
	const analytics = useAnalytics();

	useEffect(() => {
		analytics.sendPageViewEvent('edit_wishlist_page');
	}, []);

	// TODO: Rework it with TODO #1
	// Too much effort to get the name
	useEffect(() => {
		if (!wishlistId) return;

		const wishlist = WishListsRepository.getWishListById(wishlistId);

		if (wishlist?.name) {
			setWishListName(wishlist.name);
		} else {
			const fetchWishListName = async () => {
				if (!wishlistId) return;
				const promise =
					WishListsRepository.isWishListLoading(wishlistId) ?? WishListsRepository.fetchWishList(wishlistId);
				const wishlist = await promise;
				if (!wishlist?.name) return;

				setWishListName(wishlist.name);
			};

			fetchWishListName();
		}
	}, []);

	if (!wishlistId) return <Navigate to="/" />;

	return (
		<Container sx={{ flexDirection: 'column' }} fullHeight>
			<Header />
			<BackLink label={wishListName} to={`/wishlist/${wishlistId}`} />
			<div className={styles.content}>
				<Typography variant={'h2'}>Edit wishlist</Typography>
				<EditWishListForm wishlistId={wishlistId} />
			</div>
		</Container>
	);
};

export default EditWishListPage;
