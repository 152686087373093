const EMAIL_EXISTS_ERROR_CODE = 'auth/email-already-in-use';
const EMAIL_EXISTS_ERROR_MESSAGE = 'This email already exists';

type Keys = 'email';
const mapErrorToMessage = (errorCode: string): string => {
	const messages: Record<string, string> = {
		[EMAIL_EXISTS_ERROR_CODE]: EMAIL_EXISTS_ERROR_MESSAGE,
		default: '',
	};

	return messages[errorCode] ?? messages.default;
};

export default class AuthError extends Error {
	private readonly _code: string;
	private readonly _key: Keys;
	constructor(code: string) {
		super();
		this._code = code;
		this.message = mapErrorToMessage(code);
		this._key = 'email';
	}

	public get code(): string {
		return this._code;
	}

	public get key(): string {
		return this._key;
	}
}
