import React from 'react';
import styles from './styles.module.scss';
import Portal from '../Portal';

interface ModalProps {
	isOpened: boolean;
	className?: string;
	onClickOutside?: () => void;
}

const Modal: React.FC<ModalProps> = ({ isOpened, className, children, onClickOutside }) => {
	return isOpened ? (
		<Portal wrapperId={'modal-container'}>
			<div className={[styles.modal, className].join(' ')}>
				<div className={styles.backdrop} onClick={onClickOutside}></div>
				{children}
			</div>
		</Portal>
	) : null;
};

export default Modal;
