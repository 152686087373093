import { type WishItemFields } from '../../../../domain/entities/WishItem/interfaces/WishItemFields';
import { useCallback, useState } from 'react';
import WishItemRepository from '../../../../infrastructure/repositories/WishItem';
import { type WishListId } from '../../../../domain/entities/WishList/types';
import { requiredValidation } from '../../../../utils/validation';
import { WishItem } from '../../../../domain/entities/WishItem/WishItem';
import { type WishItemId } from '../../../../domain/entities/WishItem/types';

interface CreateWishItemFormControls {
	title: WishItemFields['title'];
	setTitle: (title: WishItemFields['title']) => void;
	description: WishItemFields['description'];
	setDescription: (description: WishItemFields['description']) => void;
	rawImages: File[];
	addImages: (images: File[]) => void;
	removeImage: (imageIndex: number) => void;
	link: WishItemFields['link'];
	setLink: (link: WishItemFields['link']) => void;
	quantity: number;
	setQuantity: (count: number) => void;
	createWishItem: () => Promise<WishItemId | null>;
	wishlist: WishListId;
	setWishlist: (wishlists: WishListId) => void;
	errors: {
		title: string;
		wishlist: string;
	};
}

const useWishItemCreateForm = ({
	preselectedWishlist,
}: {
	preselectedWishlist: WishListId;
}): CreateWishItemFormControls => {
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [link, setLink] = useState('');
	const [rawImages, setRawImages] = useState<File[]>([]);
	const [wishlist, setWishlist] = useState<WishListId>(preselectedWishlist);
	const [quantity, setQuantity] = useState(1);
	const [errors, setErrors] = useState({
		title: '',
		wishlist: '',
	});

	const addImages = (image: File[]) => {
		setRawImages((prev) => prev.concat(image).slice(0, WishItem.MAX_IMAGES_COUNT));
	};

	const removeImage = (imageIndex: number) => {
		const image = rawImages[imageIndex];
		if (!image) return;
		setRawImages(rawImages.filter((file) => file !== image));
	};

	const createWishItem = useCallback(async () => {
		const titleValidation = requiredValidation(title);
		const wishlistValidation = requiredValidation(wishlist);
		if (titleValidation || wishlistValidation) {
			setErrors((prev) => ({
				...prev,
				title: titleValidation,
				wishlist: wishlistValidation,
			}));
			return null;
		}

		return WishItemRepository.createWishItem({
			title,
			description,
			link,
			images: rawImages,
			wishlist,
			quantity,
		});
	}, [title, description, link, rawImages, wishlist, quantity]);

	const handleSetWishlist = (value: string) => {
		const validationResult = requiredValidation(value);
		setErrors((prev) => ({
			...prev,
			wishlist: validationResult,
		}));

		setWishlist(value);
	};

	const handleSetTitle = (value: string) => {
		const validationResult = requiredValidation(value);
		setErrors((prev) => ({
			...prev,
			title: validationResult,
		}));

		setTitle(value);
	};

	return {
		title,
		setTitle: handleSetTitle,
		description,
		setDescription,
		link,
		setLink,
		rawImages,
		addImages,
		removeImage,
		createWishItem,
		quantity,
		setQuantity,
		wishlist,
		setWishlist: handleSetWishlist,
		errors,
	};
};

export default useWishItemCreateForm;
