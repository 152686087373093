import { observable, observe } from 'mobx';
import { type WishItem } from '../../../domain/entities/WishItem/WishItem';
import { type WishItemId } from '../../../domain/entities/WishItem/types';

export const wishItemsStore = observable<{
	wishItems: WishItem[];
	loading: Map<WishItemId, Promise<WishItem | null>>;
}>({
	wishItems: observable.array([], { deep: true }),
	loading: observable.map(),
});

export { observe };
