import React from 'react';
import Modal from '../Components/Modal';
import { IconButton } from '@mui/material';
import styles from './styles.module.scss';
import { CloseIcon } from '../Icons';

interface FeedbackModalProps {
	isOpened: boolean;
	onClickOutside?: () => void;
}

const FORM_ID = process.env.REACT_APP_FEEDBACK_FORM_ID ?? '';

const FeedbackModal: React.FC<FeedbackModalProps> = ({ isOpened = false, onClickOutside }) => (
	<Modal isOpened={isOpened} onClickOutside={onClickOutside}>
		<IconButton className={styles.closeButton} onClick={onClickOutside}>
			<CloseIcon color="primary" />
		</IconButton>
		<iframe
			className={styles.iframe}
			src={`https://docs.google.com/forms/d/e/${FORM_ID}/viewform?embedded=true`}
			width="100%"
			height="100%"
		>
			Loading...
		</iframe>
	</Modal>
);

export default FeedbackModal;
