import { type WishListId } from '../../../domain/entities/WishList/types';
import { type WishList } from '../../../domain/entities/WishList/WishList';
import { useState } from 'react';
import WishListsRepository from '../../../infrastructure/repositories/WishLists';

interface WishListControls {
	wishlist: WishList | null;
	fetchWishList: () => Promise<WishList | null>;
}

// TODO: #1 Get wishList instead of wishListId
// useWishList = (wishList: null) => null
// useWishList = (wishList: WishList) => WishListControls
const useWishList = (wishListId: WishListId): WishListControls => {
	const [wishlist, updateWishList] = useState<WishList | null>(
		WishListsRepository.getWishListById(wishListId),
	);

	const fetchWishList = async () => {
		const promise =
			WishListsRepository.isWishListLoading(wishListId) ?? WishListsRepository.fetchWishList(wishListId);
		const wishlist = await promise;
		updateWishList(wishlist);
		return wishlist;
	};

	return {
		wishlist,
		fetchWishList,
	};
};

export default useWishList;
