import React, { useEffect } from 'react';
import useBooking from '../../hooks/useBooking';
import { useParams } from 'react-router-dom';
import LoadingPage from '../LoadingPage';
import WishItemView from '../../ui/WishItemView';
import Container from '../../ui/Components/Container';
import useVisitorEmail from '../../hooks/useVisitorEmail';
import useAnalytics from '../../hooks/useAnalytics';

const BookingPage = () => {
	const { bookingId } = useParams();
	const { wishItemId, email } = useBooking(bookingId ?? '');
	const { visitorEmail, setVisitorEmail } = useVisitorEmail();
	const analytics = useAnalytics();

	useEffect(() => {
		if (visitorEmail === email) return;
		setVisitorEmail(email);
	}, [email]);

	if (!wishItemId || !visitorEmail) {
		return <LoadingPage />;
	}

	useEffect(() => {
		analytics.sendPageViewEvent('booking_page');
	}, []);

	return (
		<Container sx={{ flexDirection: 'column' }} fullHeight>
			<WishItemView wishItemId={wishItemId} />
		</Container>
	);
};

export default BookingPage;
