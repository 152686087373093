import React, { useState } from 'react';
import Input, { type InputProps } from '../Input';
import { IconButton, InputAdornment } from '@mui/material';
import { PasswordHiddenIcon, PasswordShownIcon } from '../../Icons';
import styles from './styles.module.scss';

const PasswordInput: React.FC<Omit<InputProps<string>, 'type'>> = (props) => {
	const [isPasswordVisible, setPasswordVisible] = useState(false);

	return (
		<Input
			{...props}
			label={props.label ?? 'Password'}
			placeholder={props.placeholder ?? 'Enter password'}
			type={isPasswordVisible ? 'text' : 'password'}
			className={styles.input}
			endAdornment={
				<InputAdornment position="end">
					<IconButton
						aria-label="toggle password visibility"
						onClick={() => setPasswordVisible((prev) => !prev)}
					>
						{isPasswordVisible ? <PasswordShownIcon /> : <PasswordHiddenIcon />}
					</IconButton>
				</InputAdornment>
			}
		/>
	);
};

export default PasswordInput;
