import React from 'react';
import Button from '../../Components/Button';
import styles from './styles.module.scss';
import NumericInput from '../../Components/NumericInput';
import BookModalVisitor from '../../BookModalVisitor';
import useBookingProcess from '../../../hooks/useBookingProcess';
import { type WishItemId } from '../../../../../domain/entities/WishItem/types';

interface BookingBarProps {
	wishItemId: WishItemId;
}

const BookingBar: React.FC<BookingBarProps> = ({ wishItemId }) => {
	const {
		user,
		quantityToBook,
		setQuantityToBook,
		bookItem,
		removeBooking,
		wishItem,
		modal: { isOpened: isBookModalOpened, setIsOpened: setIsBookModalOpened },
		email,
		setEmail,
		errors,
	} = useBookingProcess(wishItemId);

	const availableQuantity = wishItem.quantity - wishItem.bookedQuantity;
	const bookedByEmail = email ? !!wishItem.getBookingByEmail(email) : false;
	const bookedByCurrentUser = user?.id ? !!wishItem.getBookingByUserId(user.id) : false;
	const booked = bookedByEmail || bookedByCurrentUser;

	if (availableQuantity <= 0 && !booked) return null;

	const renderButton = () => {
		if (booked) {
			return (
				<Button variant="outlined" onClick={removeBooking}>
					Unbook
				</Button>
			);
		}

		const handleBookItem = async () => {
			if (user?.id) {
				await bookItem();
				return;
			}

			setIsBookModalOpened(true);
		};

		return (
			<Button onClick={handleBookItem} disabled={wishItem.isBooked}>
				Book item
			</Button>
		);
	};

	const renderInput = () => {
		if (wishItem.isBooked || availableQuantity === 1) return null;
		return (
			<NumericInput
				classes={{ inputWrapper: styles.inputWrapper, wrapper: styles.wrapper }}
				min={1}
				max={availableQuantity}
				value={quantityToBook}
				onChange={setQuantityToBook}
			/>
		);
	};

	return (
		<>
			<div className={styles.bar}>
				{renderInput()}
				{renderButton()}
			</div>
			{!user && (
				<BookModalVisitor
					onOutsideClick={() => setIsBookModalOpened(false)}
					isOpened={isBookModalOpened}
					continueUrl={`/wish/${wishItem.id}`}
					onBook={bookItem}
					email={email}
					setEmail={setEmail}
					error={errors.email}
				/>
			)}
		</>
	);
};

export default BookingBar;
