import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Typography } from '@mui/material';

import styles from './styles.module.scss';

interface LinkProps {
	to: string;
	className?: string;
}

const Link: React.FC<LinkProps> = ({ to, children, className }) => (
	<RouterLink to={to} className={[styles.link, className].join(' ')}>
		<Typography variant={'body1'}>{children}</Typography>
	</RouterLink>
);

export default Link;
