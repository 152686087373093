import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { Typography } from '@mui/material';
import { type WishListId } from '../../../../domain/entities/WishList/types';
import WishListsRepository from '../../../../infrastructure/repositories/WishLists';
import { Link } from 'react-router-dom';
import mockImage from '../../../../assets/images/mock-item.jpg';

interface WishListProps {
	id: WishListId;
	title: string;
	description?: string;
}

const WishListCard: React.FC<WishListProps> = ({ title, description, id }) => {
	const [preview, setPreview] = useState('');

	useEffect(() => {
		WishListsRepository.getPreviewImage(id).then((previewUrl) => {
			setPreview(previewUrl || mockImage);
		});
	}, []);

	return (
		<Link to={`/wishlist/${id}`} className={styles.wrapper}>
			<Typography variant={'h4'}>{title}</Typography>
			{!!description && <Typography variant="body2">{description}</Typography>}
			{!!preview && (
				<div className={styles.imageWrapper}>
					<img className={styles.image} src={preview} alt={''} loading="lazy" />
				</div>
			)}
		</Link>
	);
};

export default WishListCard;
