import type User from '../../../domain/entities/User';
import { AUTHORIZED_VALUE, USER_AUTHORIZED, userStore } from '../../../services/mobx/user';
import { autorun, type Lambda, runInAction } from 'mobx';

class UserStore {
	public subscribeOnUserChange(callback: (user: User | null) => void): Lambda {
		return autorun(() => {
			callback(userStore.user);
		});
	}

	public subscribeOnVisitorEmailChange(callback: (email: string) => void): Lambda {
		return autorun(() => {
			callback(userStore.visitorEmail);
		});
	}

	public subscribeOnUserLoggedInChange(callback: (isUserLoggedIn: boolean) => void): Lambda {
		return autorun(() => {
			callback(userStore.isUserLoggedIn);
		});
	}

	public getUser(): User | null {
		return userStore.user;
	}

	public setUser(user: User | null): void {
		runInAction(() => {
			userStore.user = user;
		});
	}

	public getVisitorEmail(): string {
		return userStore.visitorEmail;
	}

	public setVisitorEmail(email: string): void {
		runInAction(() => {
			userStore.visitorEmail = email;
		});
	}

	public getIsUserLoggedIn(): boolean {
		return userStore.isUserLoggedIn;
	}

	public setIsUserLoggedIn(isUserLoggedIn: boolean): void {
		runInAction(() => {
			if (isUserLoggedIn) {
				localStorage.setItem(USER_AUTHORIZED, AUTHORIZED_VALUE);
			} else {
				localStorage.removeItem(USER_AUTHORIZED);
			}

			userStore.isUserLoggedIn = isUserLoggedIn;
		});
	}
}

export default new UserStore();
