import React, { useEffect } from 'react';
import Container from '../../ui/Components/Container';
import { Typography } from '@mui/material';
import styles from './styles.module.scss';
import ForgotPasswordForm from '../../ui/ForgotPasswordForm';
import { BackIcon } from '../../ui/Icons';
import TextLogo from '../../ui/Icons/TextLogo';
import { useNavigate } from 'react-router-dom';
import DescriptionCard from '../../ui/DescriptionCard';
import useAnalytics from '../../hooks/useAnalytics';

const ForgotPasswordPage = () => {
	const navigate = useNavigate();
	const analytics = useAnalytics();

	useEffect(() => {
		analytics.sendPageViewEvent('forgot_password_page');
	}, []);

	return (
		<Container sx={{ flexDirection: 'column' }} fullHeight>
			<div className={styles.headerWrapper}>
				<button
					onClick={() => {
						navigate('/login');
					}}
					className={styles.backButton}
				>
					<BackIcon color="primary" />
					<Typography variant="body1">Log In</Typography>
				</button>
				<TextLogo />
			</div>
			<div className={styles.content}>
				<Typography color="primary" variant={'h2'} className={styles.title}>
					Forgot password
				</Typography>
				<DescriptionCard className={styles.description}>
					Enter your e-mail to confirm that it is your account
				</DescriptionCard>
				<ForgotPasswordForm />
			</div>
		</Container>
	);
};

export default ForgotPasswordPage;
