import React from 'react';
import { Typography } from '@mui/material';
import colors from '../Theme/colors.module.scss';
import useBreakpoints from '../../hooks/useBreakpoints';

const TextLogo = () => {
	const { isDesktop } = useBreakpoints();

	return (
		<Typography
			component="span"
			variant="h2"
			sx={{
				fontSize: isDesktop ? '24px' : '18px',
				lineHeight: '127%',
				letterSpacing: '-0.05em',
				color: colors.primary,
				fontWeight: 700,
			}}
		>
			Wishly
		</Typography>
	);
};

export default TextLogo;
