import React from 'react';
import styles from './styles.module.scss';

interface ImagePreviewProps {
	source: File | string;
	onClick?: () => void;
	alt?: string;
}

const ImagePreview: React.FC<ImagePreviewProps> = ({ source, alt, onClick }) => (
	<img
		className={styles.image}
		src={typeof source === 'string' ? source : URL.createObjectURL(source)}
		alt={alt}
		onClick={onClick}
	/>
);

export default ImagePreview;
