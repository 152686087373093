import { useState } from 'react';
import { type WishListId } from '../../../../domain/entities/WishList/types';
import { type WishItemFields } from '../../../../domain/entities/WishItem/interfaces/WishItemFields';
import { type WishItemId } from '../../../../domain/entities/WishItem/types';
import WishItemsRepository from '../../../../infrastructure/repositories/WishItem';

interface EditWishItemFormControls extends Omit<WishItemFields, 'images' | 'bookings'> {
	setTitle: (title: WishItemFields['title']) => void;
	setDescription: (description: WishItemFields['description']) => void;
	setLink: (link: WishItemFields['link']) => void;
	images: Array<File | string>;
	addImages: (image: File[]) => void;
	removeImage: (imageIndex: number) => void;
	setQuantity: (quantity: WishItemFields['quantity']) => void;
	setWishlist: (wishlists: WishItemFields['wishlist']) => void;
	saveChanges: () => Promise<void>;
	getWishItem: () => Promise<void>;
}

const useEditWishItemForm = (wishItemId: WishItemId): EditWishItemFormControls => {
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [link, setLink] = useState('');
	const [images, setImages] = useState<Array<File | string>>([]);
	const [wishlist, setWishlist] = useState<WishListId>('');
	const [quantity, setQuantity] = useState(1);

	const getWishItem = async () => {
		const wishItem =
			WishItemsRepository.getWishItemById(wishItemId) ??
			(await WishItemsRepository.fetchWishItemById(wishItemId));
		if (!wishItem) {
			return;
		}

		setTitle(wishItem.title);
		setLink(wishItem.link);
		setDescription(wishItem.description);
		setImages(wishItem.images);
		setWishlist(wishItem.wishlist);
		setQuantity(wishItem.quantity);
	};

	const addImages = (image: File[]) => {
		setImages((prevImages) => prevImages.concat(image));
	};

	const removeImage = (imageIndex: number) => {
		const imageToRemove = images[imageIndex];
		if (!imageToRemove) return;
		setImages((prevImages) => prevImages.filter((image) => image !== imageToRemove));
	};

	const saveChanges = async () => {
		await WishItemsRepository.updateWishItem(wishItemId, {
			title,
			description,
			link,
			wishlist,
			quantity,
			images,
		});
	};

	return {
		title,
		setTitle,
		description,
		setDescription,
		link,
		setLink,
		images,
		addImages,
		removeImage,
		wishlist,
		setWishlist,
		quantity,
		setQuantity,
		saveChanges,
		getWishItem,
	};
};

export default useEditWishItemForm;
