import { type AuthService } from '../../../domain/entities/Auth/interfaces/Service';
import { getFunctions, httpsCallable } from 'firebase/functions';
import {
	getAuth,
	type Auth as FirebaseAuth,
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	signOut,
	onAuthStateChanged,
	type User as FirebaseUser,
	updateEmail,
	reauthenticateWithCredential,
	updatePassword,
	EmailAuthProvider,
	verifyPasswordResetCode,
	confirmPasswordReset,
	deleteUser,
} from 'firebase/auth';
import { type Email, type Password } from '../../../domain/entities/Auth/definitions';
import FirebaseApp from '../../firebase/FirebaseApp';
import { type Username } from '../../../domain/entities/User/definitions';
import { getURLParamByName } from '../../../utils/getURLParamByName';

export default class FirebaseAuthService implements AuthService {
	private readonly _firebaseAuth: FirebaseAuth;
	private readonly _authStateObservers: Array<(email: string | null) => void>;

	constructor() {
		this._firebaseAuth = getAuth(FirebaseApp);
		this._authStateObservers = [];
		onAuthStateChanged(this._firebaseAuth, (user) => {
			this._authStateObservers.forEach((observer) => {
				observer(user?.email ?? null);
			});
		});
	}

	public async signup(email: Email, password: Password, username: Username): Promise<void> {
		await createUserWithEmailAndPassword(this._firebaseAuth, email, password);
	}

	public async login(email: Email, password: Password): Promise<void> {
		await signInWithEmailAndPassword(this._firebaseAuth, email, password);
	}

	public async logout(): Promise<void> {
		await signOut(this._firebaseAuth);
	}

	public getFirebaseUser(): FirebaseUser | null {
		return this._firebaseAuth.currentUser;
	}

	public subscribeOnAuthChange(observer: (email: string | null) => void) {
		this._authStateObservers.push(observer);
	}

	public async updateEmail(email: string): Promise<void> {
		const user = this.getFirebaseUser();
		if (!user) {
			return;
		}

		await updateEmail(user, email);
	}

	public async isPasswordCorrect(password: string): Promise<boolean> {
		const user = this.getFirebaseUser();
		if (!user?.email) {
			return false;
		}

		const credential = EmailAuthProvider.credential(user.email, password);

		try {
			await reauthenticateWithCredential(user, credential);
			return true;
		} catch {
			return false;
		}
	}

	public async updateUserPassword(password: string): Promise<void> {
		const user = this.getFirebaseUser();
		if (!user) {
			return;
		}

		await updatePassword(user, password);
	}

	public async sendPasswordResetEmail(email: string): Promise<void> {
		const functions = getFunctions(FirebaseApp);
		const sendPasswordResetEmail = httpsCallable(functions, 'sendPasswordResetEmail');
		await sendPasswordResetEmail({ email });
	}

	public async verifyPasswordResetCode(): Promise<string> {
		const actionCode = getURLParamByName('oobCode');
		if (!actionCode) return '';
		return verifyPasswordResetCode(this._firebaseAuth, actionCode);
	}

	public async confirmPasswordReset(newPassword: string): Promise<void> {
		const actionCode = getURLParamByName('oobCode');
		if (!actionCode) return;
		await confirmPasswordReset(this._firebaseAuth, actionCode, newPassword);
	}

	// TODO: Fix user deletion
	public async deleteUser(): Promise<void> {
		const user = this.getFirebaseUser();
		if (!user) return;

		await deleteUser(user);
	}
}
