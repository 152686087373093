import React, { type HTMLInputTypeAttribute } from 'react';
import styles from './styles.module.scss';
import { default as MuiInput } from '@mui/material/Input';
import Label from '../Label';
import { FormHelperText } from '@mui/material';

type Value = string | number;

interface InputStyles {
	wrapper: string;
}

export interface InputProps<T extends Value> {
	defaultValue?: T;
	value?: T;
	type?: HTMLInputTypeAttribute;
	onChange?: (value: T) => void;
	placeholder?: string;
	required?: boolean;
	name?: string;
	label?: string;
	rows?: number;
	disabled?: boolean;
	className?: string;
	error?: boolean;
	success?: boolean;
	autoComplete?: string;
	helperText?: string;
	endAdornment?: React.ReactNode;
	classes?: InputStyles;
}

const Input = <T extends Value>({
	type = 'text',
	value,
	defaultValue,
	onChange,
	placeholder,
	required = false,
	name,
	label,
	disabled = false,
	rows = 1,
	className,
	error,
	success,
	autoComplete,
	helperText,
	endAdornment,
	classes,
}: InputProps<T>) => {
	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		if (onChange) {
			onChange(event.target.value as T);
		}
	};

	return (
		<div className={[styles.wrapper, classes?.wrapper].join(' ')}>
			{label && (
				<Label htmlFor={name} className={styles.label}>
					{required ? `${label}*` : label}
				</Label>
			)}
			<MuiInput
				classes={{
					root: [
						styles.input,
						className,
						...(value ? [styles.filled] : []),
						...(success ? [styles.success] : []),
					].join(' '),
					focused: styles.focused,
					error: styles.error,
					input: styles.nativeInput,
				}}
				type={type}
				value={value}
				defaultValue={defaultValue}
				placeholder={placeholder}
				required={required}
				name={name}
				disableUnderline
				rows={rows}
				disabled={disabled}
				error={error}
				inputProps={{
					autoComplete: autoComplete ?? 'off',
				}}
				endAdornment={endAdornment}
				{...(rows > 1 && { multiline: true })}
				{...(onChange && { onChange: handleChange })}
			/>
			{helperText && (
				<FormHelperText className={success ? styles.successHelperText : ''} disabled={disabled} error={error}>
					{helperText}
				</FormHelperText>
			)}
		</div>
	);
};

export default Input;
