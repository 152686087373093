import React, { useEffect, useState } from 'react';
import Header from '../../ui/Header';
import Container from '../../ui/Components/Container';
import { Navigate, useParams } from 'react-router-dom';
import WishItemView from '../../ui/WishItemView';
import BackLink from '../../ui/Components/BackLink';
import useWishItem from '../../hooks/useWishItem';
import WishListsRepository from '../../../../infrastructure/repositories/WishLists';
import useAnalytics from '../../hooks/useAnalytics';

const WishItemPage = () => {
	const { wishItemId } = useParams();
	const { wishlist: wishlistId } = useWishItem(wishItemId ?? '');
	const [wishListName, setWishListName] = useState('');
	const analytics = useAnalytics();

	useEffect(() => {
		analytics.sendPageViewEvent('wishitem_page');
	}, []);

	// TODO: Rework it with TODO #1
	// Too much effort to get the name
	useEffect(() => {
		if (!wishlistId) return;
		const wishlist = WishListsRepository.getWishListById(wishlistId);

		if (wishlist?.name) {
			setWishListName(wishlist.name);
		} else {
			const fetchWishListName = async () => {
				if (!wishlistId) return;
				const promise =
					WishListsRepository.isWishListLoading(wishlistId) ?? WishListsRepository.fetchWishList(wishlistId);
				const wishlist = await promise;
				if (!wishlist?.name) return;
				setWishListName(wishlist.name);
			};

			fetchWishListName();
		}
	}, [wishlistId]);

	return (
		<Container sx={{ flexDirection: 'column' }} fullHeight>
			<Header />
			<BackLink label={wishListName} to={`/wishlist/${wishlistId}`} />
			{wishItemId ? <WishItemView wishItemId={wishItemId} /> : <Navigate to="/" />}
		</Container>
	);
};

export default WishItemPage;
