import React, { useEffect, useState } from 'react';
import { type WishListId } from '../../../../domain/entities/WishList/types';
import { Typography } from '@mui/material';
import useWishList from '../../hooks/useWishList';
import useWishItems from '../../hooks/useWishItems';
import WishItemCard from '../WishItemCard';
import styles from './styles.module.scss';
import useUser from '../../hooks/useUser';
import ButtonBar from './ButtonBar';
import useModal from '../../hooks/useModal';

import BookModal from './BookModal';
import Masonry from 'react-responsive-masonry';
import { type WishItem } from '../../../../domain/entities/WishItem/WishItem';
import UserRepository from '../../../../infrastructure/repositories/User';
import Loader from '../Components/Loader';
import NotFound from '../Components/NotFound';
import useBreakpoints from '../../hooks/useBreakpoints';
import { sortByCreatedAt } from '../../../../utils/sort';

interface WishListViewProps {
	wishlistId: WishListId;
}

const WishListView: React.FC<WishListViewProps> = ({ wishlistId }) => {
	const { isDesktop } = useBreakpoints();
	const { isOpened: isBookModalOpened, setIsOpened: setIsBookModalOpened } = useModal();
	const { wishlist, fetchWishList } = useWishList(wishlistId);
	const { wishItems, fetchWishItems, getWishItemsTotalCount } = useWishItems({ wishlistId });
	const [user] = useUser();
	const [itemToBook, setItemToBook] = useState<null | WishItem>(null);
	const [ownerName, setOwnerName] = useState('');
	const [isNotFound, setNotFound] = useState(false);

	useEffect(() => {
		if (!wishlist || user?.id === wishlist.userId) return;
		UserRepository.getUserById(wishlist.userId).then((owner) => {
			if (!owner) return;
			setOwnerName(owner.username);
		});
	}, [user, wishlist]);

	useEffect(() => {
		if (!wishlist) {
			fetchWishList().then((wishList) => {
				if (!wishList) setNotFound(true);
			});
		}

		const updateWishItems = async () => {
			const totalCount = await getWishItemsTotalCount();
			if (wishItems.length < totalCount) {
				await fetchWishItems();
			}
		};

		updateWishItems();
	}, []);

	const handleItemBooked = () => {
		setItemToBook(null);
	};

	const renderBookModal = () => {
		const bookedByCurrentUser = !!itemToBook?.bookings.find((booking) => booking.userId === user?.id);
		if (!itemToBook || bookedByCurrentUser) {
			return;
		}

		return (
			<BookModal
				wishListId={wishlistId}
				onClickOutside={handleItemBooked}
				isOpened={isBookModalOpened}
				wishItemId={itemToBook.id}
				onBook={handleItemBooked}
			/>
		);
	};

	useEffect(() => {
		setIsBookModalOpened(!!itemToBook);
	}, [itemToBook]);

	if (isNotFound)
		return <NotFound title="Wishlist couldn’t be found">It seems like this Wishlist was deleted</NotFound>;
	if (!wishlist) return <Loader />;

	return (
		<>
			<div className={styles.wrapper}>
				<Typography variant={'h2'} sx={{ marginTop: '35px' }}>
					{wishlist.name}
				</Typography>
				{ownerName && (
					<Typography className={styles.ownerName} variant="body1">
						{ownerName}
					</Typography>
				)}
				<Typography variant={'body1'} sx={{ marginTop: '10px' }}>
					{wishlist.description}
				</Typography>
				{!!wishItems.length && (
					<div className={styles.listWrapper}>
						<Masonry columnsCount={isDesktop ? 4 : 2} gutter={isDesktop ? '30px' : '20px'}>
							{sortByCreatedAt(wishItems, 'desc').map((item) => {
								return (
									<WishItemCard
										key={item.id}
										item={item}
										isBookable={user?.id !== item.userId}
										onBookButtonClick={setItemToBook}
									/>
								);
							})}
						</Masonry>
					</div>
				)}
			</div>
			{wishlist?.userId === user?.id ? <ButtonBar wishlistId={wishlistId} /> : renderBookModal()}
		</>
	);
};

export default WishListView;
