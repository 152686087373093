import React, { useEffect, useState } from 'react';
import { Link, Typography } from '@mui/material';
import useWishItem from '../../hooks/useWishItem';
import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import useUser from '../../hooks/useUser';
import ButtonBar from './ButtonBar';
import BookingBar from './BookingBar';
import Carousel from '../Components/Carousel';
import WishListTag from './WishListTag';
import useNotification from '../../context/notification/useNotification';
import mockImage from '../../../../assets/images/mock-item.jpg';
import UserRepository from '../../../../infrastructure/repositories/User';
import Loader from '../Components/Loader';
import ConfirmModal from '../ConfirmModal';
import useBreakpoints from '../../hooks/useBreakpoints';
import NotFound from '../Components/NotFound';

interface WishItemViewProps {
	wishItemId: string;
}

const WishItemView: React.FC<WishItemViewProps> = ({ wishItemId }) => {
	const { isNotFound, ...wishItem } = useWishItem(wishItemId);
	const navigate = useNavigate();
	const [user] = useUser();
	const { sendNotification } = useNotification();
	const [ownerName, setOwnerName] = useState('');
	const [isConfirmDeletionModalOpened, setConfirmDeletionModalOpened] = useState(false);
	const { isDesktop } = useBreakpoints();

	useEffect(() => {
		if (!wishItem?.userId || user?.id === wishItem.userId) return;
		UserRepository.getUserById(wishItem.userId).then((owner) => {
			if (!owner) return;
			setOwnerName(owner.username);
		});
	}, [user, wishItem]);

	const handleItemDelete = () => {
		wishItem.deleteItem().then(() => {
			navigate('/');
			sendNotification('Wish successfully deleted', 5000);
		});
	};

	if (isNotFound)
		return <NotFound title="Wishlist couldn’t be found">It seems like this Wishlist was deleted</NotFound>;

	if (!wishItem?.id) {
		return <Loader />;
	}

	const renderBottomControls = () => {
		if (user?.id === wishItem.userId) {
			return (
				<>
					<ButtonBar deleteItem={() => setConfirmDeletionModalOpened(true)} wishItemId={wishItemId} />
					<ConfirmModal
						isOpened={isConfirmDeletionModalOpened}
						onConfirm={handleItemDelete}
						onCancel={() => setConfirmDeletionModalOpened(false)}
						confirmText="Delete"
						title="Delete Wish?"
						text="If you delete this wish,  the action cannot be returned. Are you sure you want to delete it?"
					/>
				</>
			);
		}

		return <BookingBar wishItemId={wishItemId} />;
	};

	return (
		<div className={styles.wrapper}>
			<div className={styles.header}>
				<Typography variant={'h2'}>{wishItem.title}</Typography>
			</div>
			{ownerName && (
				<Typography className={styles.ownerName} variant="body1">
					{ownerName}
				</Typography>
			)}
			<Carousel slidesToShow={isDesktop ? 4 : 1} classes={{ root: styles.carousel }}>
				{wishItem.images.length ? (
					wishItem.images.map((image, index) => (
						<img key={index} alt="" src={image} className={styles.image} />
					))
				) : (
					<img alt={wishItem.title} src={mockImage} className={styles.image} />
				)}
			</Carousel>
			<div className={styles.tags}>
				<WishListTag key={wishItem.wishlist} wishListId={wishItem.wishlist} />
			</div>
			{wishItem.description && (
				<Typography variant="body1" classes={{ root: styles.description }}>
					{wishItem.description}
				</Typography>
			)}
			{wishItem.link && (
				<Link className={styles.link} href={wishItem.link} variant="body1" target="_blank" rel="noreferrer">
					{wishItem.link}
				</Link>
			)}
			{renderBottomControls()}
		</div>
	);
};

export default WishItemView;
