import React, { type ChangeEvent, useRef } from 'react';
import styles from './styles.module.scss';
import Button from '../Button';

interface FileInputProps {
	onChange?: (files: File[]) => void;
	className?: string;
	isDisabled?: boolean;
}

const FileInput: React.FC<FileInputProps> = ({ onChange, children, className, isDisabled = false }) => {
	const inputRef = useRef<HTMLInputElement | null>(null);

	const handleChange = (e: ChangeEvent<{ files: FileList | null }>): void => {
		if (!onChange || !e.target?.files) {
			return;
		}

		const files: File[] = [];
		for (let i = 0; i < e.target.files.length; i++) {
			const file = e.target.files.item(i);
			if (file) {
				files.push(file);
			}
		}

		onChange(files);

		// Clear input
		if (inputRef.current) {
			inputRef.current.value = '';
		}
	};

	return (
		<Button
			className={[styles.button, className].join(' ')}
			classes={{ wrapper: styles.wrapper }}
			disabled={isDisabled}
			size="medium"
		>
			<label className={styles.label}>
				<input
					ref={inputRef}
					className={styles.input}
					type={'file'}
					multiple
					onChange={handleChange}
					accept="image/png, image/jpeg"
				/>
				{children}
			</label>
		</Button>
	);
};

export default FileInput;
