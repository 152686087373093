import React from 'react';
import { Snackbar, Typography } from '@mui/material';
import styles from './styles.module.scss';

interface ToastNotificationProps {
	isOpened: boolean;
	message?: string;
	autoHideDuration?: number;
	onClose?: () => void;
	icon?: React.ReactNode;
}

const ToastNotification: React.FC<ToastNotificationProps> = ({
	icon,
	isOpened,
	message,
	autoHideDuration,
	onClose,
}) => (
	<Snackbar
		anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
		className={styles.snackbar}
		open={isOpened}
		autoHideDuration={autoHideDuration}
		onClose={onClose}
	>
		<Typography variant="body1" className={styles.textBlock}>
			{icon}
			{message}
		</Typography>
	</Snackbar>
);

export default ToastNotification;
