import Analytics from '../../../infrastructure/repositories/Analytics';

interface AnalyticsControls {
	sendPageViewEvent: (typeof Analytics)['sendPageViewEvent'];
	sendCustomEvent: (typeof Analytics)['sendCustomEvent'];
}

const useAnalytics = (): AnalyticsControls => ({
	sendPageViewEvent: (page) => Analytics.sendPageViewEvent(page),
	sendCustomEvent: (eventName) => Analytics.sendCustomEvent(eventName),
});

export default useAnalytics;
