import { useCallback, useEffect, useState } from 'react';
import WishListsRepository from '../../../../infrastructure/repositories/WishLists';
import { type WishListId } from '../../../../domain/entities/WishList/types';
import { useNavigate } from 'react-router-dom';

interface EditWishListFormControls {
	name: string;
	description: string;
	setName: (newName: string) => void;
	setDescription: (newDescription: string) => void;
	isConfirmDeletionModalOpened: boolean;
	setConfirmDeletionModalOpened: (isOpened: boolean) => void;
	saveChanges: () => Promise<void>;
	deleteWishList: () => Promise<void>;
}

// TODO: Add errors check so user can not totally remove the title
const useEditWishListForm = (wishlistId: WishListId): EditWishListFormControls => {
	const [wishlist, setWishList] = useState(WishListsRepository.getWishListById(wishlistId));
	const [name, setName] = useState(wishlist?.name ?? '');
	const [description, setDescription] = useState(wishlist?.description ?? '');
	const [isConfirmDeletionModalOpened, setConfirmDeletionModalOpened] = useState(false);

	const navigate = useNavigate();

	const fetchWishList = async () => {
		const fetchedWishList = await WishListsRepository.fetchWishList(wishlistId);
		setWishList(fetchedWishList);
		setName(fetchedWishList?.name ?? '');
		setDescription(fetchedWishList?.description ?? '');
	};

	const saveChanges = useCallback(async () => {
		if (!wishlist) {
			return;
		}

		await WishListsRepository.updateWishList(wishlist.id, name, description);
	}, [name, description, wishlist]);

	const deleteWishList = async () => {
		await WishListsRepository.deleteWishList(wishlistId);
		navigate('/');
	};

	useEffect(() => {
		if (!wishlist) {
			fetchWishList();
		}
	}, []);

	return {
		name,
		description,
		setDescription,
		setName,
		saveChanges,
		isConfirmDeletionModalOpened,
		setConfirmDeletionModalOpened,
		deleteWishList,
	};
};

export default useEditWishListForm;
