import { CircularProgress, Stack, Typography } from '@mui/material';
import Input from '../Components/Input';
import Button from '../Components/Button';
import styles from './styles.module.scss';
import { default as AddImageIcon } from '../Icons/AddImage';
import NumericInput from '../Components/NumericInput';
import useWishItemCreateForm from './useWishItemCreateForm';
import React, { useEffect, useState } from 'react';
import Select from '../Components/Select';
import useWishLists from '../../hooks/useWishLists';
import { useNavigate } from 'react-router-dom';
import { getErrorMessage } from '../../../../utils/errors';
import FileInput from '../Components/FileInput';
import ImagePreview from '../Components/ImagePreview';
import Label from '../Components/Label';
import { type WishListId } from '../../../../domain/entities/WishList/types';
import LightBox from '../Components/LightBox';
import { WishItem } from '../../../../domain/entities/WishItem/WishItem';
import useBreakpoints from '../../hooks/useBreakpoints';

interface CreateWishItemFormProps {
	preselectedWishlist: WishListId;
}

const CreateWishItemForm: React.FC<CreateWishItemFormProps> = ({ preselectedWishlist }) => {
	const {
		title,
		setTitle,
		description,
		setDescription,
		link,
		setLink,
		createWishItem,
		rawImages,
		addImages,
		removeImage,
		quantity,
		setQuantity,
		wishlist: selectedWishList,
		setWishlist,
		errors,
	} = useWishItemCreateForm({ preselectedWishlist });

	const { wishlists, fetchWishLists, getWishListsTotalCount } = useWishLists();
	const [isLoading, setIsLoading] = useState(false);
	const [lightBoxImage, setLightBoxImage] = useState<number | null>(null);
	const navigate = useNavigate();
	const { isDesktop } = useBreakpoints();

	useEffect(() => {
		const updateWishLists = async () => {
			const totalCount = await getWishListsTotalCount();
			if (wishlists.length < totalCount) {
				await fetchWishLists();
			}
		};

		updateWishLists();
	}, []);

	const handleCreateWishItem = async () => {
		setIsLoading(true);
		try {
			const wishItemId = await createWishItem();
			if (!wishItemId) {
				setIsLoading(false);
				return;
			}

			navigate(wishItemId ? `/wish/${wishItemId}` : '/');
		} catch (error) {
			setIsLoading(false);
			console.error('Wish creation failed: ', getErrorMessage(error));
		}
	};

	const handleRemoveImage = (index: number) => {
		removeImage(index);
		setLightBoxImage(null);
	};

	return (
		<>
			<Stack spacing={2.5} className={styles.inputGroup}>
				<Input
					value={title}
					onChange={setTitle}
					label="Name*"
					placeholder="Enter name"
					error={!!errors.title}
					helperText={errors.title}
					classes={{
						wrapper: styles.input,
					}}
				/>
				<Input
					value={description}
					onChange={setDescription}
					label={'Description'}
					placeholder={'Enter description'}
					rows={3}
					classes={{
						wrapper: styles.input,
					}}
				/>
				<div className={styles.imagesWrapper}>
					{!isDesktop && <Label>Images</Label>}
					<div className={styles['images-section']}>
						{rawImages.map((image, index) => (
							<ImagePreview key={index} source={image} onClick={() => setLightBoxImage(index)} />
						))}
						<FileInput
							onChange={addImages}
							isDisabled={rawImages.length >= 5}
							className={styles.addImageButton}
						>
							<AddImageIcon />
							<Typography
								variant={'body2'}
								color="secondary"
								className={styles.numberLabel}
							>{`${rawImages.length} / ${WishItem.MAX_IMAGES_COUNT}`}</Typography>
						</FileInput>
					</div>
				</div>
				<Input
					value={link}
					onChange={setLink}
					label={'Link'}
					placeholder={'Enter link'}
					classes={{
						wrapper: styles.input,
					}}
				/>
				<NumericInput
					label={'Quantity'}
					value={quantity}
					classes={{ inputWrapper: styles.quantityInput, wrapper: styles.input }}
					onChange={setQuantity}
					min={1}
				/>
				<Select
					label={'Wishlists'}
					options={wishlists.map((wishlist) => ({
						id: wishlist.id,
						value: wishlist.name,
					}))}
					selected={selectedWishList}
					onSelect={setWishlist}
					error={!!errors.wishlist}
					helperText={errors.wishlist}
					classes={{
						wrapper: styles.input,
					}}
				/>
			</Stack>
			<div className={styles.buttonGroup}>
				{isLoading ? (
					<CircularProgress sx={{ margin: '0 auto' }} />
				) : (
					<Button
						classes={{ wrapper: styles.addButton }}
						className={styles.addButton}
						onClick={handleCreateWishItem}
					>
						Add wish
					</Button>
				)}
			</div>
			<LightBox
				imageIndex={lightBoxImage}
				removeImage={handleRemoveImage}
				setImageIndex={setLightBoxImage}
				onClose={() => setLightBoxImage(null)}
				images={rawImages}
			/>
		</>
	);
};

export default CreateWishItemForm;
