import React from 'react';
import Modal from '../Components/Modal';
import PopUpCard from '../Components/PopUpCard';
import Button from '../Components/Button';
import { IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
import { CloseIcon } from '../Icons';

interface CreateModalProps {
	isOpened: boolean;
	onClickOutside?: () => void;
}

const CreateModal: React.FC<CreateModalProps> = ({ isOpened = false, onClickOutside }) => {
	const navigate = useNavigate();

	const createWish = () => {
		navigate('/wish/create');
	};

	const createWishlist = () => {
		navigate('/wishlist/create');
	};

	return (
		<Modal isOpened={isOpened} onClickOutside={onClickOutside}>
			<PopUpCard>
				<div className={styles.header}>
					<Typography variant={'h3'}>Create</Typography>
					<IconButton className={styles.closeButton} onClick={onClickOutside}>
						<CloseIcon color="primary" />
					</IconButton>
				</div>
				<div className={styles.buttonGroup}>
					<Button onClick={createWish} classes={{ wrapper: styles.button }}>
						Wish
					</Button>
					<Button onClick={createWishlist} classes={{ wrapper: styles.button }}>
						Wishlist
					</Button>
				</div>
			</PopUpCard>
		</Modal>
	);
};

export default CreateModal;
