import { type WishItemFields } from '../../../domain/entities/WishItem/interfaces/WishItemFields';
import { type WishItemId } from '../../../domain/entities/WishItem/types';
import WishItemsRepository from '../../../infrastructure/repositories/WishItem';
import { useEffect, useState } from 'react';
import { type UserId } from '../../../domain/entities/User/definitions';
import { type Lambda } from 'mobx/dist/utils/utils';
import type Booking from '../../../domain/entities/Booking';
import WishItemsStore from '../../../infrastructure/store/wishitems';
import { type WishItem } from '../../../domain/entities/WishItem/WishItem';

export interface WishItemControls extends WishItemFields {
	id: WishItemId;
	userId: UserId;
	bookedQuantity: number;
	getBookingByUserId: (userId: UserId) => Booking | null;
	getBookingByEmail: (email: string) => Booking | null;
	isBooked: boolean;
	deleteItem: () => Promise<void>;
	isNotFound: boolean;
}

const EMPTY_WISH_ITEM: WishItemControls = {
	id: '',
	userId: '',
	title: '',
	description: '',
	images: [],
	link: '',
	quantity: 0,
	wishlist: '',
	bookings: [],
	isBooked: false,
	bookedQuantity: 0,
	getBookingByUserId() {
		return null;
	},
	getBookingByEmail() {
		return null;
	},
	async deleteItem() {},
	isNotFound: false,
};

const mapWishItemInstanceToState = (item: WishItem) => ({
	id: item.id,
	userId: item.userId,
	title: item.title,
	description: item.description,
	images: item.images,
	link: item.link,
	quantity: item.quantity,
	bookings: item.bookings,
	wishlist: item.wishlist,
	bookedQuantity: item.bookedQuantity,
	getBookingByUserId: item.getBookingByUserId,
	getBookingByEmail: item.getBookingByEmail,
	isBooked: item.isBooked,
});

const useWishItem = (wishItemId: WishItemId): WishItemControls => {
	const [wishItem, setWishItem] = useState(() => {
		const item = WishItemsRepository.getWishItemById(wishItemId);
		if (!item) return EMPTY_WISH_ITEM;
		return mapWishItemInstanceToState(item);
	});
	const [isNotFound, setNotFound] = useState(false);

	const updateWishItem = (updatedItem: WishItem) => {
		setWishItem(mapWishItemInstanceToState(updatedItem));
	};

	const deleteItem = async () => {
		await WishItemsRepository.deleteWishItem(wishItemId);
	};

	useEffect(() => {
		let disposer: Lambda | null = null;
		if (wishItem.id) {
			disposer = WishItemsStore.subscribeOnWishItemChangeById(wishItemId, (item) => {
				updateWishItem(item);
			});
		} else {
			const promise =
				WishItemsRepository.isWishItemLoading(wishItemId) ??
				WishItemsRepository.fetchWishItemById(wishItemId);
			promise.then((item) => {
				if (!item) {
					setNotFound(true);
					return;
				}

				updateWishItem(item);
				disposer = WishItemsStore.subscribeOnWishItemChangeById(wishItemId, (item) => {
					updateWishItem(item);
				});
			});
		}

		return () => {
			if (!disposer) return;
			disposer();
		};
	}, []);

	return {
		...wishItem,
		deleteItem,
		isNotFound,
	};
};

export default useWishItem;
