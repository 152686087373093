export type ErrorMessage = string;

export const EMAIL_REQUIRED = 'E-mail is required';
export const INCORRECT_EMAIL_FORMAT = 'Incorrect e-mail format';
export const PASSWORD_REQUIRED = 'Password is required';
export const PASSWORD_FORMAT = 'Password must have a minimum 8 characters';
export const PASSWORD_CONFIRMATION_REQUIRED = 'Password confirmation is required';
export const PASSWORDS_DONT_MATCH = 'Passwords do not match';
export const PASSWORD_IS_INCORRECT = 'Password is incorrect';
export const REQUIRED = 'It is required field';

export enum ValidationTypes {
	EMAIL,
	PASSWORD,
	PASSWORD_CONFIRMATION,
	REQUIRED,
}

const EMAIL_REGEX_PATTERN = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const emailValidation = (value: string, required = false): ErrorMessage => {
	if (required && !value) {
		return EMAIL_REQUIRED;
	}

	if (!new RegExp(EMAIL_REGEX_PATTERN).test(value)) {
		return INCORRECT_EMAIL_FORMAT;
	}

	return '';
};

export const passwordValidation = (value: string, required = true): ErrorMessage => {
	if (required && !value) {
		return PASSWORD_REQUIRED;
	}

	if (value.length < 8) {
		return PASSWORD_FORMAT;
	}

	return '';
};

export const confirmPasswordValidation = (
	value: string,
	originPassword: string,
	required = true,
): ErrorMessage => {
	if (required && !value) {
		return PASSWORD_CONFIRMATION_REQUIRED;
	}

	if (value !== originPassword) {
		return PASSWORDS_DONT_MATCH;
	}

	return '';
};

export const requiredValidation = (value: unknown): ErrorMessage => {
	const isValueEmpty = (typeof value === 'string' && !value) || (Array.isArray(value) && !value.length);

	if (isValueEmpty) {
		return REQUIRED;
	}

	return '';
};

export interface mapValidationTypeToValueType {
	[ValidationTypes.EMAIL]: string;
	[ValidationTypes.PASSWORD]: string;
	[ValidationTypes.PASSWORD_CONFIRMATION]: string;
	[ValidationTypes.REQUIRED]: unknown;
}

export const mapTypeToValidation = {
	[ValidationTypes.EMAIL]: emailValidation,
	[ValidationTypes.PASSWORD]: passwordValidation,
	[ValidationTypes.PASSWORD_CONFIRMATION]: confirmPasswordValidation,
	[ValidationTypes.REQUIRED]: requiredValidation,
};
