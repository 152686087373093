import WishItemsStore from '../../store/wishitems';
import { type WishListId } from '../../../domain/entities/WishList/types';
import FirebaseFirestore from '../../http/firebase/FirebaseFirestore';
import { type WishItem } from '../../../domain/entities/WishItem/WishItem';
import { type WishItemId } from '../../../domain/entities/WishItem/types';
import { type UserId } from '../../../domain/entities/User/definitions';
import Booking from '../../../domain/entities/Booking';
import { type BookingId } from '../../../domain/entities/Booking/definitions';
import { type WishItemFields } from '../../../domain/entities/WishItem/interfaces/WishItemFields';
import { prependHttp } from '../../helpers/link';

const Firestore = new FirebaseFirestore();

interface UpdatedWishItem extends Omit<Partial<WishItemFields>, 'images'> {
	images?: Array<File | string>;
}

class WishItemsRepository {
	private readonly _firestore: FirebaseFirestore;

	constructor(firestore: FirebaseFirestore) {
		this._firestore = firestore;
	}

	public async getWishItemsTotalCount(wishListId: WishListId): Promise<number> {
		return this._firestore.getWishItemsTotalCount(wishListId);
	}

	public async fetchWishItems(wishListId: WishListId, limit?: number): Promise<void> {
		const wishItems = await this._firestore.getWishItems(wishListId, limit);
		const itemsToAdd = wishItems.filter((newItem) => !this.getWishItemById(newItem.id));
		WishItemsStore.addWishItems(itemsToAdd);
	}

	public getWishItemsByWishlistId(wishListId: WishListId): WishItem[] {
		return WishItemsStore.getWishItemsByWishlistId(wishListId);
	}

	public getWishItemById(wishItemId: WishItemId): WishItem | null {
		return WishItemsStore.getWishItem(wishItemId) ?? null;
	}

	public isWishItemLoading(wishItemId: WishItemId): Promise<WishItem | null> | null {
		return WishItemsStore.getWishItemLoadingPromise(wishItemId);
	}

	public async fetchWishItemById(wishItemId: WishItemId): Promise<WishItem | null> {
		const promise = this._firestore.getWishItem(wishItemId);
		WishItemsStore.setWishItemLoading(wishItemId, promise);
		const wishItem = await promise;
		WishItemsStore.setWishItemLoaded(wishItemId);
		if (!wishItem) {
			return null;
		}

		WishItemsStore.addWishItems([wishItem]);
		return wishItem;
	}

	public async createWishItem(wishItem: {
		title: string;
		description: string;
		link: string;
		images: File[];
		quantity: number;
		wishlist: WishListId;
	}): Promise<WishItemId | null> {
		const createdWishItem = await this._firestore.createWishItem(wishItem);
		if (!createdWishItem) {
			return null;
		}

		WishItemsStore.addWishItems([createdWishItem]);
		return createdWishItem.id;
	}

	public async deleteWishItem(id: WishItemId): Promise<void> {
		await this._firestore.deleteWishItem(id);
		WishItemsStore.deleteWishItem(id);
	}

	public async bookWishItem({
		id,
		userId,
		quantity,
		email,
	}: {
		id: WishItemId;
		userId?: UserId;
		quantity: number;
		email?: string;
	}): Promise<void> {
		const bookingId = await this._firestore.setWishItemBooked({
			id,
			userId,
			quantity,
			email,
		});
		const wishItem = WishItemsStore.getWishItem(id);
		if (!wishItem || !bookingId) return;

		let booking: Booking | null = null;
		if (userId) {
			booking = wishItem.getBookingByUserId(userId);
		}

		if (email) {
			booking = wishItem.getBookingByEmail(email);
		}

		if (booking) {
			wishItem.bookings = wishItem.bookings.map((booking) => {
				if (booking.id === bookingId) {
					booking.quantity += quantity;
				}

				return booking;
			});
			return;
		}

		const newBooking = new Booking({
			id: bookingId,
			wishItemId: id,
			quantity,
			userId: userId ?? '',
			email: email ?? '',
		});

		wishItem.bookings = wishItem.bookings.concat(newBooking);
	}

	public async removeBooking(wishItemId: WishItemId, bookingId: BookingId): Promise<void> {
		const wishItem = this.getWishItemById(wishItemId);
		if (!wishItem) return;

		await this._firestore.deleteBooking(bookingId);
		wishItem.bookings = wishItem.bookings.filter((booking) => booking.id !== bookingId);
	}

	public async updateWishItem(wishItemId: WishItemId, updatedFields: UpdatedWishItem): Promise<void> {
		const { images, ...rest } = updatedFields;
		const fieldsToUpdate: Partial<WishItemFields> = rest;

		// Prepend http to link
		if (fieldsToUpdate.link) {
			fieldsToUpdate.link = prependHttp(fieldsToUpdate.link);
		}

		// Upload not existing images
		if (images) {
			const imagesToUpload: File[] = images.filter((image): image is File => image instanceof File);
			if (imagesToUpload.length) {
				const uploadedImages = await this._firestore.uploadWishItemImages(imagesToUpload);
				fieldsToUpdate.images = images
					.filter((image): image is string => typeof image === 'string')
					.concat(uploadedImages);
			} else {
				fieldsToUpdate.images = images as string[];
			}
		}

		await this._firestore.updateWishItem(wishItemId, fieldsToUpdate);
		WishItemsStore.updateWishItem(wishItemId, fieldsToUpdate);
	}
}

export default new WishItemsRepository(Firestore);
