import { type WishItemId } from './types';
import { type WishItemData } from './interfaces/WishItemData';
import { type WishListId } from '../WishList/types';
import { type UserId } from '../User/definitions';
import type Booking from '../Booking';

export class WishItem {
	public static get MAX_IMAGES_COUNT() {
		return 5;
	}

	public description: string;
	public title: string;
	public bookings: Booking[];
	public link: string;
	public images: string[];
	public quantity: number;
	public createdAt: Date;

	private readonly _id: WishItemId;

	private _wishList: WishListId;
	private readonly _userId: UserId;

	constructor(itemData: WishItemData) {
		this.title = itemData.title;
		this.description = itemData.description;
		this.images = itemData.images;
		this.link = itemData.link;
		this.bookings = itemData.bookings;
		this.quantity = itemData.quantity;
		this.createdAt = itemData.createdAt;

		this._id = itemData.id;
		this._userId = itemData.userId;
		this._wishList = itemData.wishlist;
	}

	get id(): WishItemId {
		return this._id;
	}

	get userId(): UserId {
		return this._userId;
	}

	get wishlist(): WishListId {
		return this._wishList;
	}

	set wishlist(wishlist: WishListId) {
		this._wishList = wishlist;
	}

	get isBooked(): boolean {
		return this.bookedQuantity >= this.quantity;
	}

	get bookedQuantity(): number {
		return this.bookings.reduce((accumulator, current) => {
			return accumulator + current.quantity;
		}, 0);
	}

	public getBookingByUserId(userId: UserId): Booking | null {
		return this.bookings.find((booking) => booking.userId === userId) ?? null;
	}

	public getBookingByEmail(email: string): Booking | null {
		return this.bookings.find((booking) => booking.email === email) ?? null;
	}
}
