import { useEffect, useState } from 'react';
import UserRepository from '../../../infrastructure/repositories/User';
import UserStore from '../../../infrastructure/store/user';

interface IsUserLoggedInControls {
	isUserLoggedIn: boolean;
	setUserLoggedIn: (isUserLoggedIn: boolean) => void;
}

const useIsUserLoggedIn = (): IsUserLoggedInControls => {
	const [isUserLoggedIn, setUserLoggedIn] = useState(UserRepository.getIsUserLoggedIn());

	useEffect(() => {
		const disposer = UserStore.subscribeOnUserLoggedInChange(setUserLoggedIn);
		return () => {
			disposer();
		};
	}, []);

	return {
		isUserLoggedIn,
		setUserLoggedIn: UserRepository.setIsUserLoggedIn,
	};
};

export default useIsUserLoggedIn;
