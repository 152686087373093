import React from 'react';
import useNotification from '../useNotification';
import ToastNotification from '../../../ui/Components/ToastNotification';
import { CheckMarkIcon } from '../../../ui/Icons';

const Notification = () => {
	const { notification } = useNotification();

	if (!notification) return null;

	return (
		<ToastNotification
			icon={<CheckMarkIcon />}
			isOpened={!!notification}
			autoHideDuration={notification.time}
			onClose={() => notification.onClose()}
			message={notification.message}
		/>
	);
};

export default Notification;
