import React, { useEffect } from 'react';
import Container from '../../ui/Components/Container';
import { Typography } from '@mui/material';
import styles from './styles.module.scss';
import EditProfileForm from '../../ui/EditProfileForm';
import useUser from '../../hooks/useUser';
import LoadingPage from '../LoadingPage';
import DescriptionCard from '../../ui/DescriptionCard';
import { BackIcon, LogOutIcon } from '../../ui/Icons';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../context/auth/useAuth';
import useAnalytics from '../../hooks/useAnalytics';

const ProfilePage = () => {
	const [user] = useUser();
	const navigate = useNavigate();
	const { logout } = useAuth();
	const analytics = useAnalytics();

	if (!user) {
		return <LoadingPage />;
	}

	useEffect(() => {
		analytics.sendPageViewEvent('profile_page');
	}, []);

	return (
		<Container sx={{ flexDirection: 'column' }} fullHeight>
			<div className={styles.headerWrapper}>
				<button
					onClick={() => {
						navigate('/');
					}}
					className={styles.backButton}
				>
					<BackIcon color="primary" />
					<Typography variant="body1">Home</Typography>
				</button>
				<button onClick={logout}>
					<LogOutIcon color="primary" />
				</button>
			</div>
			<div className={styles.content}>
				<Typography color="primary" variant={'h2'} className={styles.title}>
					Edit your profile
				</Typography>
				<DescriptionCard className={styles.description}>
					You can change your name, password or e-mail
				</DescriptionCard>
				<EditProfileForm user={user} />
			</div>
		</Container>
	);
};

export default ProfilePage;
