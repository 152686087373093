import { type AuthService } from './interfaces/Service';
import { AUTH_LOGIN_ERROR, AUTH_LOGOUT_ERROR, AUTH_SIGNUP_ERROR } from './Errors';
import { getErrorMessage } from '../../../utils/errors';
import { type Email, type Password } from './definitions';
import { type Username } from '../User/definitions';
import WishListsStore from '../../../infrastructure/store/wishlists';
import WishItemsStore from '../../../infrastructure/store/wishitems';
import UserStore from '../../../infrastructure/store/user';
import AuthError from './AuthError';

export default class Auth {
	private readonly _service: AuthService;
	private _continueUrl: string | null;

	constructor(service: AuthService) {
		this._service = service;
		this._continueUrl = null;
	}

	public login = async (email: Email, password: Password): Promise<void> => {
		try {
			await this._service.login(email, password);
		} catch (error) {
			const message = getErrorMessage(error);
			throw new Error(message ? `${AUTH_LOGIN_ERROR}: ${getErrorMessage(error)}` : AUTH_LOGIN_ERROR);
		}
	};

	public logout = async (): Promise<void> => {
		try {
			await this._service.logout();
			WishListsStore.setWishLists([]);
			WishItemsStore.setWishItems([]);
			UserStore.setUser(null);
		} catch (error) {
			const message = getErrorMessage(error);
			throw new Error(message ? `${AUTH_LOGOUT_ERROR}: ${message}` : AUTH_LOGOUT_ERROR);
		}
	};

	public signup = async (email: Email, password: Password, username: Username): Promise<void> => {
		try {
			await this._service.signup(email, password, username);
		} catch (error) {
			const errorCode = (error as { code: string }).code;
			if (errorCode) {
				throw new AuthError(errorCode);
			} else {
				const message = getErrorMessage(error);
				throw new Error(message ? `${AUTH_SIGNUP_ERROR}: ${message}` : AUTH_SIGNUP_ERROR);
			}
		}
	};

	public subscribeOnAuthStateChange = (observer: (email: string | null) => void): void => {
		this._service.subscribeOnAuthChange(observer);
	};

	public sendPasswordResetEmail = async (email: string): Promise<void> => {
		return this._service.sendPasswordResetEmail(email);
	};

	public verifyPasswordResetCode = async (): Promise<string> => {
		return this._service.verifyPasswordResetCode();
	};

	public resetPassword = async (email: string): Promise<void> => {
		return this._service.confirmPasswordReset(email);
	};

	public deleteUser = async (): Promise<void> => {
		return this._service.deleteUser();
	};

	public get continueUrl(): string | null {
		return this._continueUrl;
	}

	public set continueUrl(url) {
		this._continueUrl = url;
	}
}
