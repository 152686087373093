import React, { useState } from 'react';
import styles from './styles.module.scss';
import { CircularProgress, Stack } from '@mui/material';
import Button from '../Components/Button';
import useAuth from '../../context/auth/useAuth';
import { useNavigate, useParams } from 'react-router-dom';
import PasswordInput from '../Components/PasswordInput';

const CreatePasswordForm = () => {
	const [isLoading] = useState(false);
	const [newPassword, setNewPassword] = useState('');
	const [newPasswordCopy, setNewPasswordCopy] = useState('');
	const auth = useAuth();
	const { continueUrl } = useParams();
	const navigate = useNavigate();

	const updatePassword = async () => {
		if (continueUrl) auth.continueUrl = continueUrl;
		await auth.resetPassword(newPassword);
		navigate('/login');
	};

	return (
		<div className={styles.wrapper}>
			<Stack spacing={2.5} className={styles.inputGroup}>
				<PasswordInput
					label={'New password'}
					value={newPassword}
					onChange={setNewPassword}
					disabled={isLoading}
					placeholder={'Enter new password'}
					classes={{
						wrapper: styles.input,
					}}
				/>
				<PasswordInput
					label={'Repeat new password'}
					value={newPasswordCopy}
					onChange={setNewPasswordCopy}
					disabled={isLoading}
					placeholder={'Repeat new password'}
					classes={{
						wrapper: styles.input,
					}}
				/>
			</Stack>
			<div className={styles.buttonGroup}>
				{isLoading ? (
					<CircularProgress sx={{ margin: '0 auto' }} />
				) : (
					<Button onClick={updatePassword}>Save password</Button>
				)}
			</div>
		</div>
	);
};

export default CreatePasswordForm;
