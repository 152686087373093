import React from 'react';
import BigLogo from '../../ui/Icons/BigLogo';
import styles from './style.module.scss';
import Container from '../../ui/Components/Container';
import Loader from '../../ui/Components/Loader';

const LoadingPage = () => (
	<Container
		fullHeight
		sx={{
			alignContent: 'space-around',
		}}
	>
		<div className={styles.logo_wrapper}>
			<BigLogo />
		</div>
		<Loader className={styles.loader} />
	</Container>
);

export default LoadingPage;
