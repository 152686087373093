import { useState } from 'react';

export interface ModalControls {
	isOpened: boolean;
	setIsOpened: (opened: boolean) => void;
}

const useModal = (isOpened = false): ModalControls => {
	const [isModalOpened, setIsOpenedModal] = useState(isOpened);

	return {
		isOpened: isModalOpened,
		setIsOpened: setIsOpenedModal,
	};
};

export default useModal;
