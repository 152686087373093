import { type WishItem } from '../../domain/entities/WishItem/WishItem';
import { type WishItemFields } from '../../domain/entities/WishItem/interfaces/WishItemFields';

export function mapFieldsToObject(wishItem: WishItem, updatedFields: Partial<WishItemFields>): WishItem {
	if (updatedFields.title) wishItem.title = updatedFields.title;
	if (updatedFields.description) wishItem.description = updatedFields.description;
	if (updatedFields.wishlist) wishItem.wishlist = updatedFields.wishlist;
	if (updatedFields.link) wishItem.link = updatedFields.link;
	if (updatedFields.images) wishItem.images = updatedFields.images;
	if (updatedFields.quantity) wishItem.quantity = updatedFields.quantity;
	return wishItem;
}
