import React from 'react';
import { Chip, Typography } from '@mui/material';
import styles from './styles.module.scss';

interface TagProps {
	label?: React.ReactNode;
	className?: string;
}

const Tag: React.FC<TagProps> = ({ label, className }) => (
	<Chip
		classes={{
			root: [styles.root, className].join(' '),
		}}
		color={'primary'}
		label={
			<Typography variant="body1" className={styles.text}>
				{label}
			</Typography>
		}
	/>
);

export default Tag;
