import React, { useEffect, useState } from 'react';
import Container from '../../ui/Components/Container';
import { useParams } from 'react-router-dom';
import WishItemView from '../../ui/WishItemView';
import styles from './styles.module.scss';
import TextLogo from '../../ui/Icons/TextLogo';
import useWishItem from '../../hooks/useWishItem';
import WishListsRepository from '../../../../infrastructure/repositories/WishLists';
import BackLink from '../../ui/Components/BackLink';
import useAnalytics from '../../hooks/useAnalytics';

const WishItemPageVisitor = () => {
	const { wishItemId } = useParams();
	const { wishlist: wishlistId } = useWishItem(wishItemId ?? '');
	const [wishListName, setWishListName] = useState('');
	const analytics = useAnalytics();

	useEffect(() => {
		analytics.sendPageViewEvent('visitor_wishitem_page');
	}, []);

	// TODO: Rework it with TODO #1
	// Too much effort to get the name
	useEffect(() => {
		if (!wishlistId) return;
		const wishlist = WishListsRepository.getWishListById(wishlistId);

		if (wishlist?.name) {
			setWishListName(wishlist.name);
		} else {
			const fetchWishListName = async () => {
				if (!wishlistId) return;
				const promise =
					WishListsRepository.isWishListLoading(wishlistId) ?? WishListsRepository.fetchWishList(wishlistId);
				const wishlist = await promise;
				if (!wishlist?.name) return;
				setWishListName(wishlist.name);
			};

			fetchWishListName();
		}
	}, [wishlistId]);

	return (
		<Container sx={{ flexDirection: 'column' }} fullHeight>
			<div className={styles.header}>
				{wishListName && <BackLink label={wishListName} to={`/wishlist/${wishlistId}`} />}
				<div className={styles.logo}>
					<TextLogo />
				</div>
			</div>
			{!!wishItemId && <WishItemView wishItemId={wishItemId} />}
		</Container>
	);
};

export default WishItemPageVisitor;
