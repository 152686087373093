import React, { useEffect } from 'react';
import Header from '../../ui/Header';
import Container from '../../ui/Components/Container';
import { Navigate, useParams } from 'react-router-dom';
import WishListView from '../../ui/WishListView';
import styles from './styles.module.scss';
import BackLink from '../../ui/Components/BackLink';
import useAnalytics from '../../hooks/useAnalytics';

const WishListPage = () => {
	const { wishlistId } = useParams();
	const analytics = useAnalytics();

	useEffect(() => {
		analytics.sendPageViewEvent('wishlist_page');
	}, []);

	if (!wishlistId) {
		return <Navigate to={'/'} replace />;
	}

	return (
		<Container className={styles.container} fullHeight>
			<Header />
			<BackLink label="Home" to="/" />
			<WishListView wishlistId={wishlistId} />
		</Container>
	);
};

export default WishListPage;
