import React from 'react';
import { Typography } from '@mui/material';

interface LabelProps {
	htmlFor?: string;
	className?: string;
}

const Label: React.FC<LabelProps> = ({ children, className, htmlFor }) => (
	<label htmlFor={htmlFor} className={className}>
		<Typography variant="body1">{children}</Typography>
	</label>
);

export default Label;
