import React from 'react';
import styles from './styles.module.scss';
import Button from '../../../ui/Components/Button';
import { PlusIcon, ShareIcon } from '../../../ui/Icons';
import { type WishListId } from '../../../../../domain/entities/WishList/types';
import { useNavigate } from 'react-router-dom';
import useNotification from '../../../context/notification/useNotification';

interface ButtonBarProps {
	wishlistId: WishListId;
}

const ButtonBar: React.FC<ButtonBarProps> = ({ wishlistId }) => {
	const navigate = useNavigate();
	const { sendNotification } = useNotification();

	const copyLinkToClipboard = async () => {
		const link = `${process.env.PUBLIC_URL}/wishlist/${wishlistId}`;
		await navigator.clipboard.writeText(link);
		sendNotification('Copied to clipboard', 2000);
	};

	return (
		<div className={styles.buttonBar}>
			<Button
				classes={{
					button: styles.button,
					wrapper: [styles.button_wrapper, styles.button_add].join(' '),
				}}
				onClick={() => {
					navigate(`/wish/create?wishlist=${wishlistId}`);
				}}
			>
				<PlusIcon color="white" />
			</Button>
			<Button
				classes={{
					button: styles.button,
					wrapper: [styles.button_wrapper, styles.button_edit].join(' '),
				}}
				onClick={() => {
					navigate('edit');
				}}
			>
				Edit wishlist
			</Button>
			<Button
				classes={{
					button: styles.button,
					wrapper: [styles.button_wrapper, styles.button_share].join(' '),
				}}
				onClick={copyLinkToClipboard}
			>
				<ShareIcon color="white" />
			</Button>
		</div>
	);
};

export default ButtonBar;
