import type User from '../../../domain/entities/User';
import { useEffect, useState } from 'react';
import UserStore from '../../../infrastructure/store/user';

const useUser = (): [User | null, (user: User | null) => void] => {
	const [user, updateUser] = useState<User | null>(UserStore.getUser());

	useEffect(() => {
		const disposer = UserStore.subscribeOnUserChange((updatedUser: User | null) => {
			updateUser(updatedUser);
		});

		return () => {
			disposer();
		};
	}, []);

	return [user, UserStore.setUser];
};

export default useUser;
