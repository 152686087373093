import { type WishListFields } from '../../../domain/entities/WishList/interfaces/WishListFIelds';
import { useCallback, useState } from 'react';
import WishListsRepository from '../../../infrastructure/repositories/WishLists';
import { useNavigate } from 'react-router-dom';
import { requiredValidation } from '../../../utils/validation';

interface WishListFormControls {
	isLoading: boolean;
	name: WishListFields['name'];
	description: WishListFields['description'];
	setName: (name: string) => void;
	setDescription: (description: string) => void;
	createWishList: () => void;
	errors: {
		name: string;
	};
	isCreationDisabled: boolean;
}

const useWishListCreateForm = (): WishListFormControls => {
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();
	const [errors, setErrors] = useState({
		name: '',
	});

	const validateName = (value: string): void => {
		const validationResult = requiredValidation(value);
		setErrors((prev) => ({
			...prev,
			name: validationResult,
		}));
	};

	const handleSetName = (value: string) => {
		validateName(value);
		setName(value);
	};

	const createWishList = useCallback(() => {
		setIsLoading(true);
		WishListsRepository.createWishList(name, description)
			.then(() => {
				navigate('/');
			})
			.catch((error) => {
				console.error(error);
				setIsLoading(false);
			});
	}, [name, description]);

	const isCreationDisabled = () => !!Object.values(errors).find((error) => error.length);

	return {
		isLoading,
		name,
		description,
		setName: handleSetName,
		setDescription,
		createWishList,
		errors,
		isCreationDisabled: isCreationDisabled(),
	};
};

export default useWishListCreateForm;
