import { observable, observe } from 'mobx';
import { type WishList } from '../../../domain/entities/WishList/WishList';
import { type WishListId } from '../../../domain/entities/WishList/types';

export const wishlistsStore = observable<{
	wishlists: WishList[];
	loading: Map<WishListId, Promise<WishList | null>>;
}>({
	wishlists: observable.array([]),
	loading: observable.map(),
});

export { observe };
