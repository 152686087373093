import React from 'react';

const BigLogo = () => (
	<svg width="285" height="242" viewBox="0 0 285 242" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g filter="url(#filter0_i_43_274)">
			<path
				d="M23.1301 137.361C17.8625 130.489 9.82985 114.497 19.8396 105.508C32.3517 94.2713 45.8912 103.811 53.9559 113.295C60.4077 120.882 81.896 145.918 91.8337 157.488C96.5461 161.393 107.64 165.625 114.315 151.312C114.573 150.76 114.836 150.158 115.115 149.519C117.475 144.112 120.983 136.076 132.293 133.086C143.543 129.879 150.664 134.995 155.455 138.437C156.021 138.844 156.555 139.227 157.059 139.571C170.109 148.465 177.484 139.16 179.541 133.395C182.173 118.372 187.856 85.8718 189.527 76.0536C191.615 63.7809 198.381 48.6633 214.878 51.9312C228.075 54.5455 229.339 72.3968 228.321 80.9957C225.264 103.935 217.826 155.792 212.54 179.706C205.933 209.599 179.489 201.864 172.199 194.687C164.982 187.582 156.776 178.475 145.604 181.543C134.433 184.612 132.03 196.634 129.456 206.429C126.855 216.322 108.073 236.48 87.1223 214.158C70.3617 196.3 37.4772 155.519 23.1301 137.361Z"
				fill="url(#paint0_linear_43_274)"
			/>
		</g>
		<g filter="url(#filter1_i_43_274)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M217.526 5.19129C221.909 3.89337 226.117 7.9521 230.277 10.517C234.659 13.2192 239.95 15.0848 241.902 20.221C243.981 25.6935 242.758 31.853 240.237 36.7292C237.78 41.4811 233.539 44.6146 228.68 45.7942C223.95 46.9424 218.896 45.9128 214.571 42.9592C210.315 40.0519 206.753 35.5249 205.771 30.1461C204.856 25.1371 207.712 20.9337 209.756 16.5941C211.837 12.1775 213.172 6.48069 217.526 5.19129Z"
				fill="url(#paint1_linear_43_274)"
			/>
		</g>
		<defs>
			<filter
				id="filter0_i_43_274"
				x="15.0675"
				y="51.4794"
				width="213.528"
				height="176.795"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="4" />
				<feGaussianBlur stdDeviation="2.5" />
				<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
				<feColorMatrix type="matrix" values="0 0 0 0 0.415686 0 0 0 0 0 0 0 0 0 0.956863 0 0 0 0.15 0" />
				<feBlend mode="normal" in2="shape" result="effect1_innerShadow_43_274" />
			</filter>
			<filter
				id="filter1_i_43_274"
				x="205.595"
				y="4.94153"
				width="37.3626"
				height="45.3115"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="4" />
				<feGaussianBlur stdDeviation="2.5" />
				<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
				<feColorMatrix type="matrix" values="0 0 0 0 0.415686 0 0 0 0 0 0 0 0 0 0.956863 0 0 0 0.15 0" />
				<feBlend mode="normal" in2="shape" result="effect1_innerShadow_43_274" />
			</filter>
			<linearGradient
				id="paint0_linear_43_274"
				x1="54.5062"
				y1="220.778"
				x2="244.339"
				y2="74.2584"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#7678ED" />
				<stop offset="1" stopColor="#E4C1F9" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_43_274"
				x1="216.308"
				y1="49.6277"
				x2="256.49"
				y2="14.968"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#E4C1F9" />
				<stop offset="1" stopColor="#A9DEF9" />
			</linearGradient>
		</defs>
	</svg>
);

export default BigLogo;
