import React from 'react';
import styles from './styles.module.scss';
import { Typography } from '@mui/material';
import Minus from '../../Icons/Minus';
import Plus from '../../Icons/Plus';
import Input from '../Input';

interface NumericInputProps {
	value?: number;
	max?: number;
	min?: number;
	onChange?: (value: number) => void;
	required?: boolean;
	label?: string;
	disabled?: boolean;
	classes?: {
		input?: string;
		inputWrapper?: string;
		wrapper?: string;
	};
}

const NumericInput: React.FC<NumericInputProps> = ({
	value = 0,
	max,
	min,
	onChange,
	required = false,
	label,
	disabled = false,
	classes,
}) => {
	const handleChange = (value: number): void => {
		if (!onChange) {
			return;
		}

		if (typeof min !== 'undefined' && value < min) {
			onChange(min);
			return;
		}

		if (typeof max !== 'undefined' && value > max) {
			onChange(max);
			return;
		}

		onChange(value);
	};

	return (
		<div className={[styles.wrapper, classes?.wrapper].join(' ')}>
			{label && <Typography variant="body1">{label}</Typography>}
			<div className={[styles['input-wrapper'], classes?.inputWrapper].join(' ')}>
				<div className={styles.controls}>
					<button
						disabled={disabled}
						onClick={() => {
							handleChange(value - 1);
						}}
						className={`${styles.control} ${styles.control__decrement}`}
					>
						<Minus />
					</button>
					<button
						disabled={disabled}
						onClick={() => {
							handleChange(Number(value) + 1);
						}}
						className={`${styles.control} ${styles.control__increment}`}
					>
						<Plus />
					</button>
				</div>
				<Input
					required={required}
					disabled={disabled}
					value={value}
					className={[styles.input, classes?.input].join(' ')}
					onChange={handleChange}
					type={'number'}
				/>
			</div>
		</div>
	);
};

export default NumericInput;
