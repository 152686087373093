import React, { useState } from 'react';
import NotificationContext, { type Notification } from './NotificationContext';

const NotificationProvider: React.FC = ({ children }) => {
	const [notification, setNotification] = useState<Notification | null>(null);

	const sendNotification = (message: string, time?: number) => {
		if (notification) {
			notification.onClose();
		}

		const newNotification = {
			message,
			onClose() {
				setNotification(null);
			},
			time,
		};

		setNotification(newNotification);
	};

	const controls = {
		notification,
		sendNotification,
	};

	return <NotificationContext.Provider value={controls}>{children}</NotificationContext.Provider>;
};

export default NotificationProvider;
