import { CircularProgress } from '@mui/material';
import React from 'react';
import styles from './styles.module.scss';

interface LoaderProps {
	size?: number;
	className?: string;
}

const Loader: React.FC<LoaderProps> = ({ className, size = 60 }) => (
	<div className={[styles.wrapper, className].join(' ')}>
		<CircularProgress sx={{ margin: '0 auto' }} size={size} />
	</div>
);

export default Loader;
