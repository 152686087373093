import { useState } from 'react';
import useAuth from '../../context/auth/useAuth';
import { emailValidation, PASSWORD_REQUIRED, requiredValidation } from '../../../../utils/validation';
import UserRepository from '../../../../infrastructure/repositories/User';
import useAnalytics from '../../hooks/useAnalytics';

const WRONG_USERNAME_ERROR = 'Wrong email or username';
const WRONG_USERNAME_OR_PASSWORD = 'Wrong login or password. Please try again';

interface LoginFormControls {
	username: string;
	password: string;
	setUsername: (username: string) => void;
	setPassword: (password: string) => void;
	login: () => Promise<void>;
	isButtonDisabled: boolean;
	errors: {
		common: string;
		username: string;
		password: string;
	};
	cleanCommonError: () => void;
}

const useLoginForm = (): LoginFormControls => {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');

	const [isLoading, setIsLoading] = useState(false);
	const [errors, setErrors] = useState({
		common: '',
		username: '',
		password: '',
	});

	const { login } = useAuth();
	const analytics = useAnalytics();

	const handleLogIn = async () => {
		setIsLoading(true);
		validatePassword(password);
		analytics.sendCustomEvent('login');

		let email = username;
		const isUsernameUsedToLogIn = !!emailValidation(username, false);

		if (isUsernameUsedToLogIn) {
			const user = await UserRepository.getUserByUsername(username);
			if (user) {
				email = user.email;
			} else {
				setErrors((prev) => ({
					...prev,
					username: WRONG_USERNAME_ERROR,
				}));
				setIsLoading(false);
				return;
			}
		}

		if (isButtonDisabled()) {
			setIsLoading(false);
			return;
		}

		try {
			await login(email, password);
		} catch {
			setErrors((prev) => ({
				...prev,
				common: WRONG_USERNAME_OR_PASSWORD,
			}));
		} finally {
			setIsLoading(false);
		}
	};

	const validatePassword = (value: string) => {
		const validationResult = requiredValidation(value);
		setErrors((prev) => ({
			...prev,
			password: validationResult ? PASSWORD_REQUIRED : '',
			common: '',
		}));
	};

	const handleSetPassword = (value: string) => {
		validatePassword(value);
		setPassword(value);
	};

	const handleSetUsername = (value: string) => {
		setErrors((prev) => ({
			...prev,
			username: '',
			common: '',
		}));
		setUsername(value);
	};

	const cleanCommonError = () => setErrors((prev) => ({ ...prev, common: '' }));

	const isButtonDisabled = () => {
		const isThereErrors = !!Object.values(errors).find((error) => error.length);
		const fieldsAreEmpty = username === '' || password === '';
		return isThereErrors || fieldsAreEmpty || isLoading;
	};

	return {
		username,
		setUsername: handleSetUsername,
		password,
		setPassword: handleSetPassword,
		login: handleLogIn,
		isButtonDisabled: isButtonDisabled(),
		errors,
		cleanCommonError,
	};
};

export default useLoginForm;
