import React, { useEffect } from 'react';
import AuthContext from './AuthContext';
import Auth from '../../../../domain/entities/Auth';
import FirebaseAuthService from '../../../../services/auth/firebase/FirebaseAuthService';
import useUser from '../../hooks/useUser';
import UserRepository from '../../../../infrastructure/repositories/User';

const auth = new Auth(new FirebaseAuthService());

const AuthProvider: React.FC = ({ children }) => {
	const [, setUser] = useUser();

	const handleUserStateChange = async (email: string | null) => {
		// Handle LogOut
		if (!email) {
			setUser(null);
			UserRepository.setIsUserLoggedIn(false);
			return;
		}

		// Handle LogIn
		const user = await UserRepository.getUserByEmail(email);
		if (user) {
			setUser(user);
			UserRepository.setIsUserLoggedIn(true);
			return;
		}

		// Default
		setUser(null);
		UserRepository.setIsUserLoggedIn(false);
	};

	useEffect(() => {
		auth.subscribeOnAuthStateChange(handleUserStateChange);
	}, []);

	return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
