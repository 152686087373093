import { useState } from 'react';
import { type Username } from '../../../../domain/entities/User/definitions';
import { type Email } from '../../../../domain/entities/Auth/definitions';
import type User from '../../../../domain/entities/User';
import { emailValidation, type ErrorMessage, requiredValidation } from '../../../../utils/validation';
import UserRepository from '../../../../infrastructure/repositories/User';
import useNotification from '../../context/notification/useNotification';
import useAuth from '../../context/auth/useAuth';

const USERNAME_EXISTS_ERROR = 'This username already exists';
const EMAIL_EXISTS_ERROR = 'This email already exists';

const SUCCESSFULLY_UPDATED = 'Your profile successfully updated';

interface EditProfileControls {
	username: Username;
	setUsername: (username: Username) => void;
	email: Email;
	setEmail: (email: Email) => void;
	errors: {
		username: ErrorMessage;
		email: ErrorMessage;
	};
	isSavingDisabled: boolean;
	saveChanges: () => Promise<void>;
	deleteAccount: () => Promise<void>;
}

const useEditProfileForm = (user: User): EditProfileControls => {
	const [username, setUsername] = useState(user.username);
	const [email, setEmail] = useState(user.email);
	const { logout } = useAuth();

	const [errors, setErrors] = useState({
		username: '',
		email: '',
	});

	const [isSaved, setIsSaved] = useState(false);
	const { sendNotification } = useNotification();

	const validateEmail = (value: string) => {
		const validationResult = emailValidation(value, true);
		setErrors((prev) => ({
			...prev,
			email: validationResult,
		}));
	};

	const validateUsername = (value: string) => {
		const validationResult = requiredValidation(value);
		setErrors((prev) => ({
			...prev,
			username: validationResult,
		}));
	};

	const handleSetUsername = (value: string) => {
		validateUsername(value);
		setUsername(value);
		setIsSaved(false);
	};

	const handleSetEmail = (value: string) => {
		validateEmail(value);
		setEmail(value);
		setIsSaved(false);
	};

	const isSavingDisabled = () => {
		const isThereAnyError = !!Object.values(errors).find((value) => value.length);
		const noChanges = username === user.username && email === user.email;
		return isThereAnyError || noChanges || isSaved;
	};

	const saveChanges = async () => {
		const currentUser = UserRepository.getCurrentUser();
		const error = !!Object.values(errors).find((value) => value.length);

		if (!currentUser || error) {
			return;
		}

		const promises = [];
		const errorsToSet: Partial<EditProfileControls['errors']> = {};

		if (currentUser.username !== username) {
			const isUsernameAvailable = !(await UserRepository.checkIsUsernameTaken(username));
			const validationResult = requiredValidation(username);

			if (isUsernameAvailable && !validationResult) {
				promises.push(UserRepository.setUsername(username));
			} else {
				errorsToSet.username = validationResult ? validationResult : USERNAME_EXISTS_ERROR;
			}
		}

		if (currentUser.email !== email) {
			const validationResult = emailValidation(email, true);
			if (validationResult) {
				errorsToSet.email = validationResult;
			} else {
				const updateUserEmail = async () => {
					try {
						await UserRepository.setUserEmail(email);
					} catch {
						errorsToSet.email = EMAIL_EXISTS_ERROR;
					}
				};

				promises.push(updateUserEmail());
			}
		}

		try {
			await Promise.all(promises);
			if (Object.keys(errorsToSet).length) {
				setErrors((prev) => ({
					...prev,
					...errorsToSet,
				}));
			} else {
				setIsSaved(true);
				sendNotification(SUCCESSFULLY_UPDATED);
			}
		} catch (e) {
			console.error(e);
		}
	};

	const deleteAccount = async () => {
		await UserRepository.deleteUser();
		await logout();
	};

	return {
		username,
		setUsername: handleSetUsername,
		email,
		setEmail: handleSetEmail,
		errors,
		isSavingDisabled: isSavingDisabled(),
		saveChanges,
		deleteAccount,
	};
};

export default useEditProfileForm;
