import React from 'react';
import { colors, type IconColor } from './definitions';

interface CloseProps {
	color?: IconColor;
}

const Close: React.FC<CloseProps> = ({ color = 'white' }) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M18 6L6 18"
			stroke={colors[color]}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M6 6L18 18"
			stroke={colors[color]}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default Close;
