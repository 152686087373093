import React from 'react';
import { Routes as ReactRoutes, Route, Navigate } from 'react-router-dom';
import useUser from '../hooks/useUser';
import WelcomePage from '../pages/WelcomePage';
import LoginPage from '../pages/LoginPage';
import SignUpPage from '../pages/SignUpPage';
import HomePage from '../pages/HomePage';
import CreateWishListPage from '../pages/CreateWishListPage';
import ProfilePage from '../pages/ProfilePage';
import LoadingPage from '../pages/LoadingPage';
import CreateWishItemPage from '../pages/CreateWishItemPage';
import ForgotPasswordPage from '../pages/ForgotPasswordPage';
import CreatePasswordPage from '../pages/CreatePasswordPage';
import WishListPage from '../pages/WishListPage';
import EditWishListPage from '../pages/EditWishListPage';
import WishItemPage from '../pages/WishItemPage';
import EditWishItemPage from '../pages/EditWishItemPage';
import WishListPageVisitor from '../pages/WishListPageVisitor';
import WishItemPageVisitor from '../pages/WishItemPageVisitor';
import BookingPage from '../pages/BookingPage';
import useIsUserLoggedIn from '../hooks/useIsUserLoggedIn';
import ChangePasswordPage from '../pages/ChangePasswordPage';

const Routes = () => {
	const [user] = useUser();
	const { isUserLoggedIn } = useIsUserLoggedIn();

	if (user) {
		return (
			<ReactRoutes>
				<Route path={'/'} key={'/'} element={<HomePage />} />

				<Route path={'/wishlist/create'} key={'/wishlist/create'} element={<CreateWishListPage />} />
				<Route path={'/wishlist/:wishlistId'} key={'/wishlist/:wishlistId'} element={<WishListPage />} />
				<Route
					path={'/wishlist/:wishlistId/edit'}
					key={'/wishlist/:wishlistId/edit'}
					element={<EditWishListPage />}
				/>

				<Route path={'/wish/create'} key={'/wish/create'} element={<CreateWishItemPage />} />
				<Route path={'/wish/:wishItemId'} key={'/wish/:wishItemId'} element={<WishItemPage />} />
				<Route
					path={'/wish/:wishItemId/edit'}
					key={'/wish/:wishItemId/edit'}
					element={<EditWishItemPage />}
				/>

				<Route path={'/profile'} key={'/profile'} element={<ProfilePage />} />
				<Route path={'/password-change'} key={'/password-change'} element={<ChangePasswordPage />} />
				<Route path={'*'} key={'*'} element={<Navigate to={'/'} replace />} />
			</ReactRoutes>
		);
	}

	if (isUserLoggedIn) {
		return <LoadingPage />;
	}

	return (
		<ReactRoutes>
			<Route path={'/'} key={'/'} element={<WelcomePage />} />
			<Route path={'/login'} key={'/login'} element={<LoginPage />} />
			<Route path={'/signup'} key={'/signup'} element={<SignUpPage />} />
			<Route path={'/password-recovery'} key={'/password-recovery'} element={<ForgotPasswordPage />} />
			<Route path={'/password-creation'} key={'/password-creation'} element={<CreatePasswordPage />} />

			<Route path={'/wishlist/:wishlistId'} key={'/wishlist/:wishlistId'} element={<WishListPageVisitor />} />

			<Route path={'/wish/:wishItemId'} key={'/wish/:wishItemId'} element={<WishItemPageVisitor />} />

			<Route path={'/booking/:bookingId'} key={'/booking/:bookingId'} element={<BookingPage />} />

			<Route path={'*'} key={'*'} element={<Navigate to={'/'} replace />} />
		</ReactRoutes>
	);
};

export default Routes;
