import { CircularProgress, Stack } from '@mui/material';
import Input from '../Components/Input';
import Button from '../Components/Button';
import useEditWishListForm from './useEditWishListForm';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getErrorMessage } from '../../../../utils/errors';
import { type WishListId } from '../../../../domain/entities/WishList/types';
import useNotification from '../../context/notification/useNotification';
import ConfirmModal from '../ConfirmModal';
import styles from './styles.module.scss';

interface EditWishListFormProps {
	wishlistId: WishListId;
}

const EditWishListForm: React.FC<EditWishListFormProps> = ({ wishlistId }) => {
	const {
		name,
		description,
		setName,
		setDescription,
		saveChanges,
		deleteWishList,
		isConfirmDeletionModalOpened,
		setConfirmDeletionModalOpened,
	} = useEditWishListForm(wishlistId);

	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();
	const { sendNotification } = useNotification();

	const handleUpdateWishList = async () => {
		setIsLoading(true);
		try {
			await saveChanges();
			navigate(-1);
		} catch (error) {
			setIsLoading(false);
			console.error('WishList update failed: ', getErrorMessage(error));
		}
	};

	const handleCancelWishListDeletion = () => {
		setConfirmDeletionModalOpened(false);
	};

	const handleDeleteWishList = async () => {
		setIsLoading(true);
		setConfirmDeletionModalOpened(false);
		try {
			await deleteWishList();
			sendNotification('WishList successfully deleted', 5000);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<div className={styles.wrapper}>
				<Stack spacing={2.5} sx={{ width: '100%', flexGrow: 1 }}>
					<Input value={name} onChange={setName} label="Name" placeholder="Enter name" />
					<Input
						value={description}
						onChange={setDescription}
						label={'Description'}
						placeholder={'Enter description'}
						rows={3}
					/>
				</Stack>
			</div>
			<div className={styles.buttonGroup}>
				<Stack spacing={2.5}>
					<Button onClick={handleUpdateWishList} disabled={isLoading}>
						{isLoading ? (
							<CircularProgress color="secondary" size={25} sx={{ margin: '0 auto' }} />
						) : (
							'Save Changes'
						)}
					</Button>
					<Button variant="outlined" onClick={() => setConfirmDeletionModalOpened(true)} disabled={isLoading}>
						{isLoading ? <CircularProgress size={25} sx={{ margin: '0 auto' }} /> : 'Delete Wishlist'}
					</Button>
				</Stack>
			</div>
			<ConfirmModal
				isOpened={isConfirmDeletionModalOpened}
				onConfirm={handleDeleteWishList}
				onCancel={handleCancelWishListDeletion}
				confirmText="Delete"
				title="Delete wishlist?"
				text="If you delete this wishlist, all wishes included in this wishlist will also be deleted. Are you sure you want to delete it?"
			/>
		</>
	);
};

export default EditWishListForm;
