import React from 'react';
import { Stack, Typography } from '@mui/material';
import styles from './styles.module.scss';

interface NotFoundProps {
	title?: string;
}

const NotFound: React.FC<NotFoundProps> = ({ children, title }) => {
	return (
		<Stack spacing={2} className={styles.wrapper}>
			{title && (
				<Typography variant="h2" color="primary">
					{title}
				</Typography>
			)}
			<Typography variant="body1">{children}</Typography>
		</Stack>
	);
};

export default NotFound;
