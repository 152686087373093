import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';

interface BreakpointsControls {
	isDesktop: boolean;
}

const useBreakpoints = (): BreakpointsControls => {
	const isDesktop = useMediaQuery('(min-width: 1024px)');

	return {
		isDesktop,
	};
};

export default useBreakpoints;
