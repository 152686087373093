import React from 'react';
import styles from './styles.module.scss';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Button from '../Components/Button';
import { type WishItem } from '../../../../domain/entities/WishItem/WishItem';
import useUser from '../../hooks/useUser';
import useBookingProcess from '../../hooks/useBookingProcess';
import useVisitorEmail from '../../hooks/useVisitorEmail';
import mockImage from '../../../../assets/images/mock-item.jpg';
import useBreakpoints from '../../hooks/useBreakpoints';

interface WishItemCardProps {
	item: WishItem;
	isBookable?: boolean;
	onBookButtonClick?: (item: WishItem) => void;
	onUnbookButtonClick?: (item: WishItem) => void;
}

const WishItemCard: React.FC<WishItemCardProps> = ({
	item,
	isBookable,
	onBookButtonClick,
	onUnbookButtonClick,
}) => {
	const [user] = useUser();
	const { visitorEmail } = useVisitorEmail();
	const { removeBooking, bookItem } = useBookingProcess(item.id);
	const { isDesktop } = useBreakpoints();

	const bookedByEmail = visitorEmail ? !!item.getBookingByEmail(visitorEmail) : false;
	const bookedByCurrentUser = !!item.bookings.find((booking) => booking.userId === user?.id);
	const availableQuantity = item.quantity - item.bookedQuantity;
	const booked = bookedByEmail || bookedByCurrentUser;

	const handleBook = async () => {
		// Workaround to book item if only 1 is available (no need in opening modal)
		// TODO: Refactor it
		if (user && availableQuantity === 1) {
			await bookItem();
			return;
		}

		if (onBookButtonClick) onBookButtonClick(item);
	};

	const handleUnbook = async () => {
		await removeBooking();
		if (onUnbookButtonClick) onUnbookButtonClick(item);
	};

	const renderBookButton = () => {
		if (!isBookable) return null;

		const buttonSize = isDesktop ? 'large' : 'small';

		if (booked) {
			return (
				<Button className={styles.bookButton} variant="outlined" size={buttonSize} onClick={handleUnbook}>
					<Typography variant="body1">Unbook</Typography>
				</Button>
			);
		}

		return (
			<Button className={styles.bookButton} size={buttonSize} disabled={item.isBooked} onClick={handleBook}>
				<Typography variant="body1">{item.isBooked ? 'Booked' : 'Book'}</Typography>
			</Button>
		);
	};

	const trimmedDescription =
		item.description.length > 110 ? item.description.slice(0, 110) + '...' : item.description;

	return (
		<div>
			<Link to={`/wish/${item.id}`} className={styles.link}>
				<img className={styles.image} src={item.images[0] || mockImage} alt={item.title} />
				<Typography variant="h4" className={styles.title}>
					{item.title}
				</Typography>
				{trimmedDescription && (
					<Typography variant="body2" className={styles.description}>
						{trimmedDescription}
					</Typography>
				)}
			</Link>
			{renderBookButton()}
		</div>
	);
};

export default WishItemCard;
